import { Currency, Pair } from '@dolomite-exchange/v2-sdk'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components/macro'
import { darken } from 'polished'
import { RowBetween } from '../Row'
import { TYPE } from '../../theme'
import { Input as NumericalInput } from '../NumericalInput'
import { StyledTooltipWithIcon } from '../common/StyledTooltip'

import { useActiveWeb3React } from '../../hooks'
import useTheme from '../../hooks/useTheme'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { useDolomiteBalancesWithLoadingIndicator } from '../../state/wallet/hooks'

const InputRow = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  font-size: 15px;
  padding: 0.5rem 0.75rem; /*${({ selected }) =>
    selected ? '0.25rem 0.15rem 0.25rem 0.5rem' : '0.25rem 0.25rem 0.25rem 0.5rem'}*/;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.bg6};
`

const LabelRow = styled.div<{ hide?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.text1};
  line-height: 1rem;
  padding: 0.75rem 0.5rem 0 0.5rem;
  ${({ hide }) => hide && 'display: none;'}

  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
`

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  z-index: 1;
`

const Container = styled.div<{ hideInput: boolean }>`
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '5px')};
`

// const StyledBalanceMax = styled.button`
//   height: 28px;
//   background-color: ${({ theme }) => theme.primary5};
//   border: 1px solid ${({ theme }) => theme.primary5};
//   border-radius: 0.5rem;
//   font-size: 0.875rem;
//
//   font-weight: 500;
//   cursor: pointer;
//   margin-right: 0.5rem;
//   color: ${({ theme }) => theme.primaryText1};
//   :hover {
//     border: 1px solid ${({ theme }) => theme.primary1};
//   }
//   :focus {
//     border: 1px solid ${({ theme }) => theme.primary1};
//     outline: none;
//   }
//
//   ${({ theme }) => theme.mediaWidth.upToExtraSmall`
//     margin-right: 0.5rem;
//   `};
// `

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  disableMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency
  id: string
  showCommonBases?: boolean
  customBalanceText?: string
  disabled?: boolean
  tooltipText?: string
  isForTrading: boolean
  hideLabelRow?: boolean
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  disableMaxButton,
  label = 'Input',
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases,
  disabled,
  tooltipText,
  isForTrading,
  hideLabelRow,
}: CurrencyInputPanelProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const { account } = useActiveWeb3React()
  const theme = useTheme()

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  return (
    <InputPanel id={id}>
      <Container hideInput={hideInput}>
        {!hideInput && (
          <LabelRow hide={hideLabelRow}>
            <RowBetween>
              <TYPE.body color={theme.text2} fontWeight={600} fontSize={12}>
                {label} {tooltipText && <StyledTooltipWithIcon tooltipText={tooltipText} verticalAlign={'top'} />}
              </TYPE.body>
              {showMaxButton && account && (
                <TYPE.body
                  onClick={() => (disabled || disableMaxButton ? undefined : onMax?.())}
                  color={theme.text2} // TODO change color if disabled
                  fontWeight={400}
                  fontSize={12}
                  style={{
                    display: 'inline',
                    cursor: disabled || disableMaxButton ? 'not-allowed' : 'pointer',
                    opacity: disableMaxButton ? '0.3' : '1',
                  }}
                >
                  MAX
                </TYPE.body>
              )}
            </RowBetween>
          </LabelRow>
        )}
        <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={disableCurrencySelect}>
          {!hideInput && (
            <>
              <NumericalInput
                className={'token-amount-input'}
                value={value}
                onUserInput={val => {
                  onUserInput(val)
                }}
                fontSize={'inherit'}
                unit={cleanCurrencySymbol(currency) ?? ''}
                maxDecimals={currency?.decimals ?? 18}
                disabled={disabled}
                style={{ cursor: disabled ? 'not-allowed' : 'text' }}
              />
            </>
          )}
        </InputRow>
      </Container>
    </InputPanel>
  )
}
