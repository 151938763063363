import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Fraction, Percent, Token } from '@dolomite-exchange/sdk-core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import getLogoOrDefault from '../../components/common/TokenLogos'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import Modal from '../../components/Modal'
import { StrategyAmounts, StrategyInfo, Tags, TagsColor } from './StrategiesList'
import {
  Asset,
  AssetList,
  AssetTitle,
  Expiration,
  FilterSelect,
  FilterSelector,
  StyledAutoAwesomeIcon,
  StyledNoneIcon,
  SubmitButton,
  SubmitButtonWrapper,
  Tag,
  TagsWrapper,
  THREE_DAYS_MILLIS,
  TokenLogo,
  Yield,
} from './index'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { useDolomiteBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { formatAmount } from '../../utils/formatAmount'
import { useActiveWeb3React } from '../../hooks'
import BorrowTradeDetails from '../Borrow/BorrowPosition/BorrowTradeDetails'
import { useGetZapExactTokensForTokensParams } from '../../hooks/useGetZapParams'
import { tryParseAmount } from '../../state/trade/hooks'
import { useDefaultMarginAccount } from '../../types/marginAccount'
import { useDefaultFiatValuesWithLoadingIndicator, useFiatPricesWithLoadingIndicator } from '../../hooks/useFiatValue'
import { useIsTokenGeoRestricted, useSpecialAsset } from '../../constants/isolation/special-assets'
import { CurrencyAmount } from '@dolomite-exchange/v2-sdk'
import { NewValue, NewValueWrapper } from '../Borrow/BorrowPosition/BorrowExpandedContent'
import { ChainId, ONE_FRACTION, ZERO_ADDRESS, ZERO_FRACTION, ZERO_PERCENT } from '../../constants'
import ArrowRight from '@material-ui/icons/ArrowForward'
import { useZapExactTokensForTokens, ZapEither, ZapEventType, ZapTransferType } from '../../hooks/useExecuteZap'
import ReactGA from 'react-ga'
import JSBI from 'jsbi'
import { ethers } from 'ethers'
import { useUserLifetimeBorrowPositionCount } from '../../types/userLifetimeData'
import useIsolationModeUserVaultAddress from '../../hooks/useIsolationModeUserVaultAddressIfCreated'
import { useToggleModal } from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/actions'
import { NETWORK_LABELS, NETWORK_LOGOS } from '../../constants/chainId'
import {
  InterestRateRecord,
  useHistoricalInterestRateSeries,
  useStrategyInterestRate,
} from '../../hooks/useNetInterestRate'
import { useInterestRateData } from '../../types/interestRateData'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt'
import SignalCellularAlt1BarIcon from '@mui/icons-material/SignalCellularAlt1Bar'
import SignalCellularAlt2BarIcon from '@mui/icons-material/SignalCellularAlt2Bar'
import DepositWithdrawModal from '../../components/DepositWithdrawModal'
import ZapModal from '../Borrow/ZapModal'
import { useShowYieldAsApr } from '../../state/user/hooks'
import { YieldTooltip } from '../../components/LendingPanel'
import calculateAprToApy from '../../utils/calculateAprToApy'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useIsTransactionPending } from '../../state/transactions/hooks'
import cleanCurrencyName from '../../utils/cleanCurrencyName'
import { StyledTooltip } from '../../components/common/StyledTooltip'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { InterestRatePart } from '../../types/InterestRatePart'
import { Line, LineChart, ResponsiveContainer, Tooltip, YAxis } from 'recharts'
import dayjs from 'dayjs'

enum Risk {
  low = 'low',
  medium = 'medium',
  high = 'high',
}

export enum StrategyStage {
  OPEN = 0,
  BORROW = 1,
  ZAP = 2,
  COMPLETE = 3,
}

export const ModalInner = styled.div`
  height: auto;
  padding: 30px 40px;
  width: 100%;
  overflow: hidden;
`

export const Column = styled.div<{ right?: boolean }>`
    width: ${({ right }) =>
      right ? 'calc(54% - 15px)' : 'calc(46% - 15px)'}; /*'calc(61.8% - 15px)' : 'calc(38.2% - 15px)'*/
    display: inline-block;
    vertical-align: top;
    ${({ right }) => (right ? 'margin-top: 4px;' : '')}
    margin-left: ${({ right }) => (right ? 30 : 0)}px;

    @media screen and (max-width: 919px) {
        margin-top: 10px;
        width: 100%;
        margin-left: 0;
    }
`

export const Description = styled.div<{ visible: boolean }>`
  font-size: 11px;
  height: auto;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  margin-top: ${({ visible }) => (visible ? '10px' : '0px')};

  :hover {
    opacity: 1 !important;
    cursor: pointer;
  }

  @media screen and (max-width: 919px) {
    height: ${({ visible }) => (visible ? 'auto' : '0px')};
  }
`

const ExpandChevronIcon = styled.div<{ expanded?: boolean }>`
  opacity: 0.5;
  ${({ expanded }) => (expanded ? 'transform: rotateX(180deg);' : '')}
  transform-origin: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  display: inline-block;

  :hover {
    opacity: 1 !important;
    cursor: pointer;
  }

  svg {
    font-size: 24px;
    margin-bottom: ${({ expanded }) => (expanded ? '-8px' : '-6px')};
  }
`

const DescriptionSelector = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.text3};
  display: none;
  cursor: pointer;
  float: right;
  height: 20px;
  line-height: 66px;
  @media screen and (max-width: 919px) {
    display: inline-block;
  }
`

const HeaderWrapper = styled.div`
  font-size: 32px;
  font-weight: 100;
  width: 100%;
  height: fit-content;
  margin-bottom: 2px;
`

const StrategySubtitle = styled.div`
  margin-top: -7px;
  height: 18px;
`

const Title = styled.div`
  font-size: 20px;
  font-weight: 100;
  margin-bottom: 5px;
`

const LeverageTag = styled.div`
  display: inline-block;
  background-color: ${({ theme, color }) => (color ? color : theme.bg6)};
  color: ${({ theme }) => theme.text1};
  padding: 2px 7px 4px;
  border-radius: 4px;
  font-weight: 600;
  user-select: none;
  vertical-align: top;
  font-size: 12px;
  margin: 11px 0 4px 7px;
`

const YieldSelector = styled.div`
  margin-top: 2px;
  display: inline-block;
  float: right;
`

export const Subtitle = styled.div`
  font-size: 16px;
  margin: 15px 0 3px;
  font-weight: 600;
`

const SubText = styled.div`
  font-size: 12px;
  font-weight: 100;
  margin-top: 5px;
  display: inline-block;
  width: calc(100% - 136px);
  vertical-align: middle;
`

const ChainLogo = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: 16px;

  img {
    width: 12px;
  }
`

const ChainLabel = styled.div`
  display: inline-block;
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
  font-weight: 400;
  margin-top: 3px;
  margin-left: 4px;
  vertical-align: top;
`

const DepositButton = styled.div`
  width: 60px;
  height: 26px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg4};
  text-align: center;
  line-height: 26px;
  cursor: pointer;
  pointer-events: all;
  display: inline-block;
  margin-left: 8px;

  :hover {
    background-color: ${({ theme }) => theme.bg5};
  }

  > div {
    height: 19px !important;
    width: 19px !important;
    margin-top: 7px;
  }
`

const DepositButtons = styled.div`
  width: 136px;
  height: 26px;
  display: inline-block;
  vertical-align: middle;
`

const BalanceRow = styled.div`
  display: flex;
  width: 100%;
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 2px;
  font-weight: 500;
  justify-content: space-between;
`

const BalanceWrapper = styled.div`
  display: block;
  cursor: auto;
  text-align: left;
`

const MaxButton = styled.div`
  align-self: flex-end;
  font-size: 13px;
  color: ${({ theme }) => theme.text3};
  cursor: pointer;
  vertical-align: bottom;

  :hover {
    color: ${({ theme }) => theme.text2};
  }
`

const NewAmountWrapper = styled.div`
  display: inline-block;
`

const Balance = styled.span`
  font-weight: 200;
`

const Ticker = styled.span`
  font-weight: 200;
  margin-left: 3px;
`

const BalanceAmount = styled.div`
  cursor: pointer !important;
`

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
  font-size: 14px;
`

const NewPosition = styled.div`
  border-radius: 5px;
  background-color: ${({ theme }) => theme.bg6};
  padding: 5px 15px 10px;
  margin-bottom: 8px;

  > div {
    height: auto;
    margin-bottom: 10px;

    :nth-of-type(1) {
      margin-bottom: 0;
    }
  }
`

const NewPositionInfo = styled.div`
  margin-top: 10px;
`

export const TokenValue = styled.div<{ right?: boolean; loading?: boolean }>`
  float: ${({ right }) => (right ? 'left' : 'right')};
  ${({ loading }) => (loading ? 'opacity: 0.7;' : '')};
`

const YieldBreakdownWrapper = styled.div`
  width: calc(100% + 30px);
  display: inline-block;
  background-color: ${({ theme }) => theme.bg2};
  padding: 10px 15px 10px;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 8px;
  border-radius: 5px;
`

const EarningSection = styled.div<{ right?: boolean; rightModal?: boolean }>`
  display: inline-block;
  width: 50%;
  vertical-align: top;
  margin-top: 0;
  margin-left: ${({ right, rightModal }) => (rightModal || right ? 10 : 0)}px;
  text-align: ${({ right, rightModal }) => (rightModal || right ? 'right' : 'left')};
`

const EarningTitle = styled.div`
  font-size: 11px;
  font-weight: 700;
  color: ${({ theme }) => theme.text3};
  margin-bottom: 2px;
  margin-top: 8px;
  width: 100%;
`

const OweSection = styled.div`
  width: calc(50% - 10px);
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
`

const OweTitle = styled(EarningTitle)``

const OweContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 11px;
`

const OweUnit = styled.div``

const OweRate = styled.div``

const ScaledDisclaimer = styled.div`
  width: 100%;
  text-align: right;
  font-size: 10px;
  font-weight: 500;
  color: ${({ theme }) => theme.text3};
  margin-top: 8px;
  margin-bottom: -3px;
`

const EarningContent = styled.div`
  width: 100%;

  > div {
    padding: 0;
    font-size: 11px;
  }
`

const Large = styled.span`
  font-size: 1.3em;
  line-height: 1em;
`

const Steps = styled.div`
  width: 100%;
  margin: 10px 0 5px;
`

const StrategyStep = styled.div<{ width: number }>`
  display: inline-block;
  width: ${({ width }) => width}%;
  height: 20px;
  vertical-align: top;
`

const StrategyProgress = styled.div`
  display: inline-block;
  width: 100%;
  height: 16px;
`

const Circle = styled.div<{ complete: boolean }>`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid ${({ complete, theme }) => (complete ? theme.green2 : theme.white)};
  background-color: ${({ complete, theme }) => (complete ? theme.green2 : 'none')};
  font-size: 10px;
  font-weight: 100;
  padding-left: ${({ complete }) => (complete ? -1 : 4.5)}px;
  padding-top: ${({ complete }) => (complete ? 1.4 : -2)}px;
  vertical-align: top;

  svg {
    height: 12px !important;
    margin-left: -5px !important;
  }
`

/*const Line = styled.div<{ complete: boolean }>`
  height: 1px;
  vertical-align: top;
  background-color: ${({ complete, theme }) => (complete ? theme.green2 : theme.white)};
  transition: background-color 0.3s ease-in-out;
  display: inline-block;
  margin-top: 7px;
  width: calc(100% - 16px);
`*/

const StrategyText = styled.div`
  font-size: 10px;
`

const AvgYieldTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  margin-bottom: -4px;
  margin-top: 8px;
`

const AdvancedDescriptionWrapper = styled.div`
  font-size: 12px;
  width: 100%;
  color: ${({ theme }) => theme.text2};
  margin-top: 5px;
`

const RiskWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  font-size: 12px;
  margin-top: 3px;
`

const RiskTitle = styled.div`
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
`

const RiskIconWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 16px;
  width: 16px;
  color: ${({ theme }) => theme.text3};

  svg {
    position: absolute;
    height: 16px;
    width: 16px;
    top: 0;
    left: 0;
  }
`

const YieldChartWrapper = styled.div`
  width: 100%;
  height: 50px;
  position: relative;
  margin: 30px 0 10px;

  :nth-child(2) {
    position: absolute;
    z-index: 2;
  }
`

const YieldChartTitle = styled.div`
  font-size: 11px;
  font-weight: 700;
  color: ${({ theme }) => theme.text3};
  position: absolute;
  top: -28px;
`

const ChartMinMaxNum = styled.div<{ hide: boolean; position: number }>`
  font-size: 9px;
  color: ${({ theme }) => theme.text1};
  font-weight: 400;
  position: absolute;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 0.2s ease-in-out;
  left: calc(${({ position }) => position * 0.7}% - 9px);
`

const LoadingText = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.text2};
  font-size: 12px;
  line-height: 50px;
`

const ChartMax = styled(ChartMinMaxNum)`
  top: -10px;
`
const ChartMin = styled(ChartMinMaxNum)`
  bottom: -10px;
`

const AverageYield = styled.div<{ hide: boolean; position: number }>`
  font-size: 13px;
  color: ${({ theme }) => theme.green1};
  font-weight: 400;
  position: absolute;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 0.2s ease-in-out;
  top: calc(${({ position }) => position}% - 14px);
  left: calc(70% + 4px);

  span {
    font-size: 11px;
    color: ${({ theme }) => theme.text2};
  }
`

const TooltipDate = styled.div<{ hide: boolean; position: number }>`
  position: absolute;
  font-size: 10px;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 0.2s ease-in-out;
  width: 40px;
  text-align: center;
  left: calc(${({ position }) => Math.max(position * 0.7, 5.5)}% - 18px);
  top: -12px;
`

const TooltipYield = styled.div<{ hide: boolean; position: number }>`
  position: absolute;
  font-size: 13px;
  font-weight: 500;
  color: ${({ theme }) => theme.green1};
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 0.2s ease-in-out;
  width: 80px;
  text-align: center;
  left: calc(${({ position }) => Math.max(position * 0.7, 10)}% - 35px);
  bottom: -13px;

  span {
    font-size: 11px;
    color: ${({ theme }) => theme.text2};
  }
`

const HoveredYield = styled.div<{ hide: boolean; position: number }>`
  font-size: 13px;
  color: ${({ theme }) => theme.green1};
  font-weight: 400;
  position: absolute;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 0.2s ease-in-out;
  top: calc(${({ position }) => position}% - 10px);
  left: 70%;

  span {
    font-size: 11px;
    color: ${({ theme }) => theme.text2};
  }
`

const AverageYieldTitle = styled.div`
  font-size: 9px;
  font-weight: 600;
  color: ${({ theme }) => theme.text3};
  margin-bottom: -3px;
`

const NegativeRecolor = styled.div`
  position: absolute;
  top: 30px;
  height: 60px;
  width: 100%;
  left: 0;
  backdrop-filter: brightness(0.5);
  z-index: 4;
`

const NegativeUndoRecolor = styled.div`
  position: absolute;
  top: 30px;
  height: 60px;
  width: 100%;
  left: 0;
  backdrop-filter: brightness(2);
  z-index: 0;
`

const ProjectedProfitWrapper = styled.div`
  width: 100%;
  margin: 10px 0 -2px;
`

const ProjectedProfitDescription = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.text2};
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: -5px;
`

const BasedOnWrapper = styled.div`
  width: 60%;
  color: ${({ theme }) => theme.text3};
  font-size: 11px;
  font-weight: 500;
  margin-left: auto;
  margin-bottom: -5px;
  margin-top: 8px;
`

const BasedOnText = styled.div`
  background: ${({ theme }) => theme.bg1};
  width: fit-content;
  padding: 2px 6px;
  margin: 0 auto;
`

const BasedOnLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.text3};
  margin-bottom: -11px;
`

const ProjectedProfitHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.text3};
  font-size: 11px;
  font-weight: 600;
`

const ProjectProfitTable = styled.div`
  width: 100%;
  margin-top: 5px;
  color: ${({ theme }) => theme.text1};
  font-size: 13px;

  span {
    color: ${({ theme }) => theme.text2};
    font-size: 11px;
  }
`

const ProjectedProfitRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const ProjectedProfitPeriod = styled.div`
  width: 20%;
  text-align: left;
`

const ProjectedProfitCurrent = styled.div`
  width: 40%;
  text-align: right;
`

const ProjectedProfitAverage = styled.div`
  width: 40%;
  text-align: right;
`

export function Header({
  strategy,
  expirationDate,
  showExpirationWarning,
  partsFormatted,
  totalYield,
}: {
  strategy: StrategyInfo
  expirationDate: string | undefined
  showExpirationWarning: boolean
  partsFormatted: string[]
  totalYield: boolean
}) {
  return (
    <HeaderWrapper>
      {strategy.title}
      <LeverageTag color={TagsColor.leverage2x}>{strategy.leverage ?? 1}x</LeverageTag>
      <StrategySubtitle>
        <ChainLogo>
          <img alt={`${NETWORK_LABELS[strategy.chain]} logo`} src={NETWORK_LOGOS[strategy.chain]} />
        </ChainLogo>
        <ChainLabel>{NETWORK_LABELS[strategy.chain]}</ChainLabel>
        <StyledTooltip title={strategy.riskAssessment} placement={'top'}>
          <RiskWrapper>
            <RiskTitle>Risk: </RiskTitle>
            <RiskIconWrapper>
              <SignalCellularAltIcon />
              <StrategyRiskIcon risk={strategy.risk}>
                {strategy.risk === Risk.low ? (
                  <SignalCellularAlt1BarIcon />
                ) : strategy.risk === Risk.medium ? (
                  <SignalCellularAlt2BarIcon />
                ) : (
                  <SignalCellularAltIcon />
                )}
              </StrategyRiskIcon>
            </RiskIconWrapper>
          </RiskWrapper>
        </StyledTooltip>
        {expirationDate && (
          <Expiration closeToExpiry={showExpirationWarning}>
            Expires: {expirationDate}
            {showExpirationWarning && <WarningRoundedIcon />}
          </Expiration>
        )}
      </StrategySubtitle>
      <TagsWrapper>
        {strategy.tags.map((tag: string, i: number) => {
          const tagText = Tags[tag as keyof typeof Tags]
          const matchingTag = partsFormatted.find(part => part.includes(tagText))
          const color = TagsColor[tag as keyof typeof Tags]
          const inactive = !totalYield && color === '#2b6dc2' && !strategy.includeRates?.includes(tagText)
          if (tag.startsWith('leverage')) return
          return (
            <Tag key={i} color={inactive ? '#565A69' : color}>
              {matchingTag ?? tagText}
            </Tag>
          )
        })}
      </TagsWrapper>
    </HeaderWrapper>
  )
}

interface InterestRateChartRecord {
  supplyInterestRate: Fraction
  borrowInterestRate: Fraction
  netYield: Fraction
  timestamp: Date
  dateString: string
  averageYield: Fraction | undefined
}

export function YieldBreakdown({
  collateralInterestRateParts,
  collateralLendingYield,
  showYieldAsApr,
  collateralTotalYield,
  leverage,
  totalYield,
  strategy,
  debtToken,
  debtInterestRate,
}: {
  collateralInterestRateParts: InterestRatePart[] | undefined
  collateralLendingYield: Percent | undefined
  showYieldAsApr: boolean
  collateralTotalYield: Percent | undefined
  leverage: number
  totalYield: boolean
  strategy: StrategyInfo
  debtToken: Token
  debtInterestRate: Percent | undefined
}) {
  const [hoveredDate, setHoveredDate] = useState<string | undefined>(undefined)
  const [hoveredYield, setHoveredYield] = useState<number | undefined>(undefined)
  const [hoveredIndex, setHoveredIndex] = useState<number | undefined>(undefined)
  const collateralInterestRateHistory = useHistoricalInterestRateSeries(strategy.collateralAssets[0], strategy.chain)
  const debtInterestRateHistory = useHistoricalInterestRateSeries(strategy.debtAssets[0], strategy.chain)

  const combinedRateData = useMemo(() => {
    const combinedHistory: InterestRateChartRecord[] = []
    if (!collateralInterestRateHistory || !debtInterestRateHistory) return undefined

    // Calculate the shorter length
    const minLength = Math.min(collateralInterestRateHistory.length, debtInterestRateHistory.length)

    // Loop backwards, since the most recent timestamps will always match
    for (let i = 1; i <= minLength; i++) {
      const collateralEntry = collateralInterestRateHistory[collateralInterestRateHistory.length - i]
      const debtEntry = debtInterestRateHistory[debtInterestRateHistory.length - i]

      // Assuming the timestamps match since we're starting from the end
      if (collateralEntry.timestamp.getTime() === debtEntry.timestamp.getTime()) {
        combinedHistory.unshift({
          supplyInterestRate: collateralEntry.supplyInterestRate,
          borrowInterestRate: debtEntry.borrowInterestRate,
          netYield: collateralEntry.supplyInterestRate
            .multiply(leverage)
            .subtract(debtEntry.borrowInterestRate.multiply(leverage - 1)),
          timestamp: collateralEntry.timestamp,
          dateString: dayjs(collateralEntry.timestamp).format('MMM D'),
          averageYield: undefined,
        })
      }
    }

    return combinedHistory
  }, [collateralInterestRateHistory, debtInterestRateHistory, leverage])

  const chartMinMax = useMemo(() => {
    return combinedRateData?.reduce(
      (acc, item, index) => {
        if (!acc.highest.value || item.netYield.greaterThan(acc.highest.value)) {
          acc.highest.value = item.netYield
          acc.highest.index = index
          acc.highest.name = item.dateString
        }
        if (!acc.lowest.value || item.netYield.lessThan(acc.lowest.value)) {
          acc.lowest.value = item.netYield
          acc.lowest.index = index
          acc.lowest.name = item.dateString
        }
        acc.totalCy = acc.totalCy.add(item.netYield)

        return acc
      },
      {
        highest: { value: undefined as Fraction | undefined, index: -1, name: '' },
        lowest: { value: undefined as Fraction | undefined, index: -1, name: '' },
        totalCy: ZERO_FRACTION,
      },
    )
  }, [combinedRateData])
  const averageYield = useMemo(
    () => (combinedRateData ? chartMinMax?.totalCy.divide(combinedRateData.length) : undefined),
    [chartMinMax?.totalCy, combinedRateData],
  )
  const populatedChartData = useMemo(
    () =>
      combinedRateData?.map(item => ({
        name: item.dateString,
        netYield: parseFloat(item.netYield.toFixed(2)),
        averageYield: averageYield ? parseFloat(averageYield.toFixed(2)) : undefined,
      })),
    [averageYield, combinedRateData],
  )

  const chartLoaded =
    populatedChartData !== undefined &&
    populatedChartData.length > 0 &&
    combinedRateData !== undefined &&
    combinedRateData.length > 0 &&
    chartMinMax !== undefined &&
    chartMinMax.highest &&
    chartMinMax.highest.value !== undefined &&
    chartMinMax.lowest &&
    chartMinMax.lowest.value !== undefined &&
    averageYield

  return (
    <YieldBreakdownWrapper>
      <YieldChartWrapper>
        <YieldChartTitle>Historical Yield {chartLoaded ? `(${populatedChartData.length} Days)` : ''}</YieldChartTitle>
        {chartLoaded ? (
          <ResponsiveContainer width='70%' height='100%'>
            <LineChart
              width={250}
              height={60}
              data={populatedChartData}
              onMouseMove={(chart: any) => {
                if (!chart.activePayload || chart.activePayload.length === 0) {
                  return
                }

                const payload = chart.activePayload[0].payload
                if (populatedChartData && payload) {
                  setHoveredIndex(
                    populatedChartData.findIndex(
                      item =>
                        item.name === payload.name &&
                        item.netYield === payload.netYield &&
                        item.averageYield === payload.averageYield,
                    ),
                  )
                }
                if (setHoveredYield && populatedChartData.length) {
                  setHoveredYield(payload.netYield)
                }
                if (setHoveredDate) {
                  setHoveredDate(payload.name)
                }
                /*const formattedTime = dayjs(payload.time).format('MMM D, YYYY')
              if (setLabel && label !== formattedTime && chartData.length && !customAxis) {
                setLabel(formattedTime)
              }*/
              }}
              onMouseLeave={() => {
                setHoveredYield && populatedChartData.length && setHoveredYield(undefined)
                setHoveredDate && populatedChartData.length && setHoveredDate(undefined)
              }}
            >
              <YAxis
                hide
                type='number'
                domain={['dataMin - (0.2 * (dataMax - dataMin))', 'dataMax + (0.2 * (dataMax - dataMin))']}
              />
              <Tooltip wrapperStyle={{ display: 'none' }} />
              <Line
                type={'monotone'}
                dataKey={'netYield'}
                stroke={'#d5d6e1'}
                strokeWidth={1.5}
                activeDot={{ stroke: '#f9f9f9', strokeWidth: 2, r: 2 }}
                dot={false}
              />
              <Line
                type={'monotone'}
                dataKey={'averageYield'}
                stroke={'#8fc942'}
                strokeWidth={1}
                strokeDasharray={'5, 6'}
                activeDot={false}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <LoadingText>Loading...</LoadingText>
        )}
        {/*hoveredDate && <TooltipDate >{hoveredDate}</TooltipDate>*/}
        {chartLoaded && (
          <ChartMax
            hide={hoveredYield !== undefined}
            position={(chartMinMax.highest.index / populatedChartData.length) * 100}
          >
            {chartMinMax.highest.value?.toFixed(2)}%
          </ChartMax>
        )}
        {chartLoaded && (
          <ChartMin
            hide={hoveredYield !== undefined}
            position={(chartMinMax.lowest.index / populatedChartData.length) * 100}
          >
            {chartMinMax.lowest.value?.toFixed(2)}%
          </ChartMin>
        )}
        {chartLoaded && chartMinMax.highest.value !== undefined && chartMinMax.lowest.value !== undefined && (
          <AverageYield
            hide={false /*hoveredYield !== undefined*/}
            position={parseFloat(
              chartMinMax.highest.value
                .subtract(averageYield)
                .divide(chartMinMax.highest.value.subtract(chartMinMax.lowest.value))
                .multiply(100)
                .toFixed(2),
            )}
          >
            <AverageYieldTitle>{populatedChartData.length} Day Avg.</AverageYieldTitle>
            {averageYield.toFixed(2)}% <span>{showYieldAsApr ? `APR` : `APY`}</span>
          </AverageYield>
        )}
        {chartLoaded && hoveredYield !== undefined && hoveredIndex !== undefined && hoveredDate !== undefined && (
          <TooltipDate hide={hoveredDate === undefined} position={(hoveredIndex / populatedChartData.length) * 100}>
            {hoveredDate}
          </TooltipDate>
        )}
        {chartLoaded && hoveredYield !== undefined && hoveredIndex !== undefined && hoveredDate !== undefined && (
          <TooltipYield hide={hoveredYield === undefined} position={(hoveredIndex / populatedChartData.length) * 100}>
            {hoveredYield.toFixed(2)}% <span>{showYieldAsApr ? `APR` : `APY`}</span>
          </TooltipYield>
        )}
        {/*<NegativeRecolor />
            <NegativeUndoRecolor />*/}
        {/*<AverageYield hide={!hoveredYield || !hoveredDate} position={40}>
          <AverageYieldTitle>{hoveredDate}</AverageYieldTitle>
          {hoveredYield}% <span>{showYieldAsApr ? `APR` : `APY`}</span>
        </AverageYield>*/}
        {/*<NegativeRecolor />
            <NegativeUndoRecolor />*/}
      </YieldChartWrapper>
      {collateralInterestRateParts && (
        <EarningSection>
          <EarningTitle>You Earn:</EarningTitle>
          <EarningContent>
            <YieldTooltip
              interestRateParts={collateralInterestRateParts}
              isBorrowRate={false}
              lendingYield={collateralLendingYield}
              showYieldAsApr={showYieldAsApr}
              totalSupplyYield={collateralTotalYield}
              leverage={leverage}
              includeRates={!totalYield}
            />
          </EarningContent>
        </EarningSection>
      )}
      <OweSection>
        <OweTitle>You Owe:</OweTitle>
        <OweContent>
          <OweUnit>
            {cleanCurrencySymbol(debtToken)} Borrow {showYieldAsApr ? 'APR' : 'APY'}:
          </OweUnit>
          <OweRate>{debtInterestRate?.multiply(leverage - 1).toFixed(2) ?? '-'}%</OweRate>
        </OweContent>
      </OweSection>
      <ScaledDisclaimer>Rates scaled to deposit amount</ScaledDisclaimer>
    </YieldBreakdownWrapper>
  )
}

export function StrategyDescription({
  showDescription,
  collateral,
  debt,
}: {
  showDescription: boolean
  collateral: string | undefined
  debt: string | undefined
}) {
  return (
    <Description visible={showDescription}>
      {`This strategy uses high yield ${collateral} collateral to borrow ${debt} and trade into more ${collateral},
            increasing the amount of ${collateral} held and overall yield.`}
      <Subtitle>Why the Strategy Works</Subtitle>
      {`Generally ${collateral} earns more yield than it takes to borrow ${debt}. Additionally ${collateral} and
            ${debt} are correlated in price.`}
      <Subtitle>Risks</Subtitle>
      {`If the ${debt} borrow rate climbs above the yield from
            ${collateral}, the strategy will be negative yielding. If the rate to borrow climbs far above the yield earned for an extended period, there's a chance of liquidation. There is also technical risk - if ${collateral} faces an issue that causes it to drop significantly in price, it could result in liquidation.`}
    </Description>
  )
}

const StrategyRiskIcon = styled.div<{ risk: Risk }>`
  color: ${({ theme, risk }) => (risk === Risk.low ? theme.green1 : risk === Risk.medium ? theme.yellow1 : theme.red1)};
`

const RANDOM_NUMBER = Math.floor(Math.random() * 10000000000) // used on a "per session" basis

interface CreatePositionModalProps {
  strategy: StrategyInfo
  isOpen: boolean
  closeModal: () => void
  interestRatePair: (Percent | undefined)[]
  totalYieldSelected: boolean
}

function CreatePositionModal({
  strategy,
  isOpen,
  closeModal,
  interestRatePair,
  totalYieldSelected,
}: CreatePositionModalProps) {
  const { account, chainId, setChain } = useActiveWeb3React()
  const { t } = useTranslation()
  const [showYieldAsApr, setShowYieldAsApr] = useShowYieldAsApr()
  const [loadingChain, setLoadingChain] = useState<ChainId | undefined>(undefined)
  const [totalYield, setTotalYield] = useState(totalYieldSelected)
  const [loadingZap, setLoadingZap] = useState<boolean>(false)
  const collateralToken = strategy.collateralAssets[0]
  const collateral = cleanCurrencySymbol(collateralToken)
  const debtToken = strategy.debtAssets[0]
  const debt = cleanCurrencySymbol(strategy.debtAssets[0])
  const [inputValue, setInputValue] = useState<string | undefined>()
  const [zapOutputValue, setZapOutputValue] = useState<CurrencyAmount<Token> | undefined>(undefined)
  const [zapRefreshIncrementor, setZapRefreshIncrementor] = useState(0)
  const [dolomiteBalanceData, isLoading] = useDolomiteBalancesWithLoadingIndicator(account, strategy.collateralAssets)
  const [strategyStage, setStrategyStage] = useState<StrategyStage>(StrategyStage.OPEN)
  const [newPositionId, setPositionId] = useState<string | undefined>(undefined)
  const [isDepositModalOpen, setDepositModalOpen] = useState<boolean>(false)
  const [isSwapModalOpen, setSwapModalOpen] = useState<boolean>(false)
  const [showDescription, setShowDescription] = useState<boolean>(true)
  const [isMaxSelected, setIsMaxSelected] = useState<boolean>(false)
  const tokens = useMemo(() => [...strategy.collateralAssets, ...strategy.debtAssets], [strategy])
  const [fiatValueMap] = useDefaultFiatValuesWithLoadingIndicator(tokens)
  const isGeoRestrictedCollateral = useIsTokenGeoRestricted(collateralToken.wrapped.address)
  const isGeoRestrictedDebt = useIsTokenGeoRestricted(debtToken.wrapped.address)
  const isGeoRestricted = isGeoRestrictedCollateral || isGeoRestrictedDebt
  const selectedSpecialAsset = useSpecialAsset(collateralToken)
  const isolationModeSpecialAsset = selectedSpecialAsset?.isIsolationMode ? selectedSpecialAsset : undefined
  const refreshZapData = useCallback(() => {
    setZapRefreshIncrementor(zapRefreshIncrementor + 1)
  }, [zapRefreshIncrementor])
  const isolationModeVaultAddress = useIsolationModeUserVaultAddress(collateralToken)

  const leverage = strategy.leverage ?? 1
  const [fiatPrices, loading] = useFiatPricesWithLoadingIndicator(tokens, strategy.chain)
  const [supplyAmounts, borrowAmounts] = StrategyAmounts(strategy)
  const interestRate = interestRatePair[totalYield ? 0 : 1]
  const parsedRate =
    parseFloat(interestRate?.denominator.toString() ?? '0') > 0 ? formatAmount(interestRate, 2) : '0.00%'
  const historicalInterestRate = useStrategyInterestRate(supplyAmounts, borrowAmounts, strategy.chain, true)
  const parsedHistoricalRate =
    parseFloat(historicalInterestRate?.denominator.toString() ?? '0') > 0
      ? formatAmount(historicalInterestRate, 2)
      : '0.00%'
  const [isAttemptingTx, setIsAttemptingTx] = useState(false)
  const [transactionPendingText, setTransactionPendingText] = useState('')
  const [txHash, setTxHash] = useState<string | undefined>(undefined)
  const [pendingHash, setPendingHash] = useState<string | undefined>(undefined)
  const [selectedToken, setSelectedToken] = useState<Token | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [openingIdMap, setOpeningIdMap] = useState<Record<string, boolean>>({})
  // const interestRate = useStrategyInterestRate(
  //   strategy.borrowAmounts,
  //   strategy.supplyAmounts,
  //   strategy.chain,
  // )

  const parsedInputValue = useMemo(() => tryParseAmount(inputValue, collateralToken), [inputValue, collateralToken])
  const defaultAccountNumber = useDefaultMarginAccount().accountNumber
  const { data: borrowCountFraction } = useUserLifetimeBorrowPositionCount(account)
  const borrowId = useMemo(
    () => (borrowCountFraction ? JSBI.BigInt(parseInt(borrowCountFraction.toFixed(0)) + 100_000) : undefined),
    [borrowCountFraction],
  )
  const collateralPrice = fiatPrices[collateralToken.address ?? ''] ?? ZERO_FRACTION
  const debtPrice = fiatPrices[debtToken.address ?? ''] ?? ONE_FRACTION
  /*const borrowAmount = parsedInputValue
   ? tryParseAmount(
   collateralPrice
   .divide(debtPrice)
   .multiply(parsedInputValue.asFraction)
   .toFixed(debtToken.decimals),
   debtToken,
   )
   : undefined*/
  const isTxPending = useIsTransactionPending(txHash)

  //TODO - update to account for multiple assets, right now it's just built for one collateral and one debt
  const borrowAmount = useMemo(() => {
    return tryParseAmount(
      (parsedInputValue?.asFraction ?? ZERO_FRACTION)
        .multiply(leverage)
        .subtract(parsedInputValue?.asFraction ?? ZERO_FRACTION)
        .multiply(
          (fiatValueMap[parsedInputValue?.currency.address ?? ''] ?? ZERO_FRACTION).divide(
            fiatValueMap[debtToken.address ?? ''] ?? ONE_FRACTION,
          ),
        )
        .toFixed(debtToken.decimals ?? 0),
      debtToken,
    )
  }, [debtToken, fiatValueMap, leverage, parsedInputValue?.asFraction, parsedInputValue?.currency.address])

  const { outputs: zaps, error: zapError } = useGetZapExactTokensForTokensParams(
    borrowAmount,
    collateralToken,
    true,
    zapRefreshIncrementor,
    borrowId?.toString(),
    fiatValueMap,
  )

  const zapEither = useMemo<ZapEither | undefined>(() => {
    return parsedInputValue
      ? {
          zapType: undefined,
          transfers: [
            {
              isToTradeAccount: true,
              eventType: ZapEventType.OpenBorrowPosition,
              amount: parsedInputValue,
              transferOpts: isMaxSelected ? ZapTransferType.ALL_BALANCE : undefined,
            },
          ],
        }
      : undefined
  }, [parsedInputValue, isMaxSelected])

  const { callback: submitZap, error: submitZapError } = useZapExactTokensForTokens(
    borrowId,
    defaultAccountNumber,
    zaps,
    zapEither,
    isolationModeSpecialAsset,
    isMaxSelected,
    true,
  )

  const bestZap = useMemo(() => zaps?.[0], [zaps])
  useEffect(() => {
    if (bestZap) {
      const expectedAmountOut = CurrencyAmount.fromRawAmount(collateralToken, bestZap.expectedAmountOut.toFixed(0))
      setZapOutputValue(expectedAmountOut)
      setLoadingZap(false)
    } else {
      setLoadingZap(true)
    }
  }, [bestZap, collateralToken])

  const handleTypeInput = useCallback((value: string) => {
    setZapOutputValue(undefined)
    setInputValue(value)
    setIsMaxSelected(false)
  }, [])
  const collateralBalance = dolomiteBalanceData[collateralToken.address]
  const changedBalanceValue = useMemo(
    () => (parsedInputValue ? (collateralBalance ?? ZERO_FRACTION).subtract(parsedInputValue) : undefined),
    [collateralBalance, parsedInputValue],
  )

  const setMax = useCallback(() => {
    setInputValue(formatAmount(collateralBalance))
    setIsMaxSelected(true)
  }, [collateralBalance])

  // const { callback: executeZapCallback, error: openMarginPositionError } = useZapExactTokensForTokens(
  //   tradeAccountNumber,
  //   subAccountNumber,
  //   zaps,
  //   specialAsset,
  //   ExtraZapInfo.AddCollateralAndZapInput,
  //   true,
  //   ZapEventType.MarginPosition,
  //   false,
  //   marginDeposit,
  // )

  const { data: totalBorrowPositionCount } = useUserLifetimeBorrowPositionCount(account)
  const nonce = totalBorrowPositionCount?.toFixed(0) ?? '0'

  const positionId = useMemo(() => {
    return JSBI.BigInt(
      ethers.utils.solidityKeccak256(
        ['address', 'uint256', 'uint256'],
        [account ?? ZERO_ADDRESS, nonce, RANDOM_NUMBER],
      ),
    )
  }, [account, nonce])

  const handleZap = useCallback(async () => {
    if (!submitZap || !parsedInputValue || !zapOutputValue) {
      console.error('Callback is not defined for submitting borrow action:', submitZapError)
      return
    }

    const amount = formatAmount(parsedInputValue)
    const unwrappedSymbol = cleanCurrencySymbol(parsedInputValue.currency)
    const unwrappedZapSymbol = cleanCurrencySymbol(zapOutputValue.currency)
    const zapAmount = formatAmount(zapOutputValue)
    const actionText = `Zap ${amount} ${unwrappedSymbol} to ~${zapAmount} ${unwrappedZapSymbol} and deposit into borrow position` // TODO - completely incorrect
    setSelectedToken(parsedInputValue.currency)
    setIsAttemptingTx(true)
    setTransactionPendingText(actionText)

    submitZap()
      .then(txHash => {
        setTxHash(txHash)
        setPendingHash(txHash)
        setIsAttemptingTx(false)
        ReactGA.event({
          category: 'Borrow Position',
          action: actionText,
        })
        setStrategyStage(StrategyStage.COMPLETE)
        setInputValue('')
      })
      .catch(e => {
        if (e.message === 'insufficient-gas' || e.message.includes('insufficient funds for gas')) {
          setErrorMessage('Insufficient ETH balance for gas')
          // TODO display error in the interface's dialogue box and don't close it
        } else if (
          e.message.includes('Cannot execute when paused') ||
          e.message.includes('Cannot lever up when paused')
        ) {
          setErrorMessage('Pause sentinel active')
        } else if (e.message !== 'transaction-rejected') {
          console.error('Caught error in web3 callback:', e)
          setErrorMessage('Zap failed')
          // TODO display error in the interface's dialogue box and don't close it
        }
        setIsAttemptingTx(false)
      })
  }, [submitZap, parsedInputValue, zapOutputValue, submitZapError])
  const wrongChain = strategy.chain !== chainId
  const toggle = useToggleModal(ApplicationModal.NETWORK)
  const selectNetwork = useCallback(
    async (selectedChainId: ChainId) => {
      toggle()
      setLoadingChain(selectedChainId)
      await setChain(selectedChainId).then(() => {
        setLoadingChain(undefined)
      })
    },
    [setChain, toggle],
  )

  const onConfirm = useCallback(() => {
    if (strategy.chain && wrongChain) {
      selectNetwork(strategy.chain)
    }
    handleZap()
  }, [handleZap, selectNetwork, strategy, wrongChain])

  const isLoadingZapTradeData = !!inputValue && !bestZap && !!parsedInputValue && parsedInputValue.greaterThan(0)
  const submitDisabled = useMemo(() => !wrongChain && (isLoadingZapTradeData || !parsedInputValue || isGeoRestricted), [
    wrongChain,
    isLoadingZapTradeData,
    parsedInputValue,
    isGeoRestricted,
  ])

  const expirationDate = useMemo(() => {
    let expiration: string | undefined = undefined
    const expiryMap = [...strategy.collateralAssets, ...strategy.debtAssets].map(asset => {
      const name = cleanCurrencyName(asset) ?? ''
      const date = name.substring(name.length, name?.length - 10)
      if (Array.from(date)[0] !== '2') return
      if (expiration === undefined) expiration = date
      else if (date && date < expiration) expiration = date
    })
    return expiration
  }, [strategy.collateralAssets, strategy.debtAssets])

  const { data: interestRateMap } = useInterestRateData(strategy.chain)
  const mineralBonus = strategy.chain === ChainId.MANTLE ? 2 : 1
  const collateralInterestRateParts =
    interestRateMap[strategy.collateralAssets[0].address]?.outsideSupplyInterestRateParts
  const collateralLendingYield = showYieldAsApr
    ? interestRateMap[strategy.collateralAssets[0].address]?.supplyInterestRate
    : calculateAprToApy(interestRateMap[strategy.collateralAssets[0].address]?.supplyInterestRate ?? ZERO_PERCENT)
  const collateralTotalYield = showYieldAsApr
    ? interestRateMap[strategy.collateralAssets[0].address]?.totalSupplyInterestRate
    : calculateAprToApy(interestRateMap[strategy.collateralAssets[0].address]?.totalSupplyInterestRate ?? ZERO_PERCENT)
  const debtInterestRate = showYieldAsApr
    ? interestRateMap[strategy.debtAssets[0].address]?.totalBorrowInterestRate
    : calculateAprToApy(interestRateMap[strategy.debtAssets[0].address]?.totalBorrowInterestRate ?? ZERO_PERCENT)
  const partsFormatted = useMemo(() => {
    return collateralInterestRateParts
      ? collateralInterestRateParts.map((part, index) => {
          return part.metadata
            ? `${(part.metadata?.units ?? 0) *
                leverage *
                (part.metadata?.unitsLabel === 'Minerals' ? mineralBonus : 1)} ${part.metadata?.unitsLabel}`
            : part.label
        })
      : []
  }, [collateralInterestRateParts, leverage, mineralBonus])

  const projectedProfit = useMemo(() => {
    return parsedInputValue && interestRate
      ? parsedInputValue?.asFraction.multiply(interestRate?.asFraction ?? ZERO_FRACTION)
      : ZERO_FRACTION
  }, [interestRate, parsedInputValue])
  const projectedProfit30DayAvg = useMemo(() => {
    return parsedInputValue && historicalInterestRate
      ? parsedInputValue?.asFraction.multiply(historicalInterestRate?.asFraction ?? ZERO_FRACTION)
      : ZERO_FRACTION
  }, [historicalInterestRate, parsedInputValue])

  const insufficientBalance = (parsedInputValue ?? ZERO_FRACTION).greaterThan(collateralBalance ?? ZERO_FRACTION)
  const expirationDateParsed = expirationDate ? new Date(expirationDate) : undefined
  const showExpirationWarning = expirationDateParsed
    ? expirationDateParsed.getTime() - Date.now() < THREE_DAYS_MILLIS
    : false

  return (
    <ModalInner>
      <Column>
        <Header
          strategy={strategy}
          totalYield={totalYield}
          showExpirationWarning={showExpirationWarning}
          expirationDate={expirationDate}
          partsFormatted={partsFormatted}
        />
        <YieldBreakdown
          collateralInterestRateParts={collateralInterestRateParts}
          collateralLendingYield={collateralLendingYield}
          collateralTotalYield={collateralTotalYield}
          debtInterestRate={debtInterestRate}
          totalYield={totalYield}
          debtToken={debtToken}
          showYieldAsApr={showYieldAsApr}
          strategy={strategy}
          leverage={leverage}
        />
        <StrategyDescription showDescription={showDescription} collateral={collateral} debt={debt} />
      </Column>
      <Column right>
        <Title>Deposit Amount</Title>
        <BalanceRow>
          <BalanceWrapper>
            {t('dolomiteBalanceText')}
            {` `}
            <Balance>{formatAmount(collateralBalance)}</Balance>
            {changedBalanceValue && collateralBalance && (
              <NewValueWrapper
                isNegative={changedBalanceValue.asFraction.lessThan(collateralBalance.asFraction ?? ZERO_FRACTION)}
              >
                <ArrowRight />
              </NewValueWrapper>
            )}
            <NewAmountWrapper>
              {changedBalanceValue && collateralBalance && (
                <NewValueWrapper
                  isNegative={changedBalanceValue.asFraction.lessThan(collateralBalance.asFraction ?? ZERO_FRACTION)}
                >
                  <NewValue error={changedBalanceValue.lessThan(ZERO_FRACTION)}>
                    {formatAmount(changedBalanceValue)}
                  </NewValue>
                </NewValueWrapper>
              )}
              <Ticker>{collateral}</Ticker>
            </NewAmountWrapper>
          </BalanceWrapper>
          <MaxButton onClick={setMax}>Max</MaxButton>
        </BalanceRow>
        <CurrencyInputPanel
          currency={collateralToken}
          label={''}
          value={inputValue ?? ''}
          disabled={wrongChain}
          onUserInput={handleTypeInput}
          showMaxButton={false}
          disableMaxButton={true}
          isForTrading={false}
          id={'create-position-modal-currency-input'}
          hideLabelRow
        />
        {insufficientBalance && (
          <>
            <SubText>You have insufficient {collateral} on Dolomite. Deposit or trade to get more.</SubText>
            <DepositButtons>
              <DepositButton onClick={() => setDepositModalOpen(true)}>Deposit</DepositButton>
              <DepositButton onClick={() => setSwapModalOpen(true)}>Swap</DepositButton>
            </DepositButtons>
          </>
        )}
        <NewPositionInfo>
          <Title>
            New Position
            <YieldSelector>
              <StyledTooltip title={'Toggle yields including external rewards'}>
                <FilterSelect onClick={() => setTotalYield(!totalYield)} middle>
                  <FilterSelector active={totalYield}>
                    <StyledAutoAwesomeIcon />
                  </FilterSelector>
                  <FilterSelector active={!totalYield}>
                    <StyledNoneIcon />
                  </FilterSelector>
                </FilterSelect>
              </StyledTooltip>
              <FilterSelect onClick={() => setShowYieldAsApr(!showYieldAsApr)} right>
                <FilterSelector active={showYieldAsApr}>APR</FilterSelector>
                <FilterSelector active={!showYieldAsApr}>APY</FilterSelector>
              </FilterSelect>
            </YieldSelector>
          </Title>
          <NewPosition>
            <Yield>
              {parsedRate}
              <span>{showYieldAsApr ? t('apr') : t('apy')}</span>
            </Yield>
            <AssetList>
              <AssetTitle small>Collateral</AssetTitle>
              {strategy.collateralAssets.map((asset: Token, i: number) => (
                <Asset key={i} small>
                  <TokenLogo small>
                    <img
                      src={getLogoOrDefault(cleanCurrencySymbol(asset) ?? '')}
                      alt={`${cleanCurrencySymbol(asset)} logo`}
                    />
                  </TokenLogo>
                  {cleanCurrencySymbol(asset)}
                  <TokenValue loading={loadingZap}>
                    {/* TODO - only designed for a single collateral and debt asset */}
                    <Large>{parsedInputValue && zapOutputValue && '~'}</Large>
                    {zapOutputValue && parsedInputValue
                      ? formatAmount(parsedInputValue?.add(zapOutputValue))
                      : '0.000000'}
                  </TokenValue>
                </Asset>
              ))}
            </AssetList>
            <AssetList rightModal>
              <AssetTitle right small>
                Debt
              </AssetTitle>
              {strategy.debtAssets.map((asset: Token, i: number) => {
                return (
                  <Asset key={i} small>
                    <TokenValue right loading={loadingZap}>
                      {borrowAmount ? formatAmount(borrowAmount) : '0.00'}
                    </TokenValue>
                    <TokenLogo small>
                      <img
                        src={getLogoOrDefault(cleanCurrencySymbol(asset) ?? '')}
                        alt={`${cleanCurrencySymbol(asset)} logo`}
                      />
                    </TokenLogo>
                    {cleanCurrencySymbol(asset)}
                  </Asset>
                )
              })}
            </AssetList>
          </NewPosition>
          {/*<InfoRow>*/}
          {/*  <div>Expiration</div>*/}
          {/*  <div>{cleanCurrencyName(collateralToken)?.split(' ')[2]}</div>*/}
          {/*</InfoRow>*/}
          <ProjectedProfitWrapper>
            <Title>Projected Profit</Title>
            <ProjectedProfitDescription>
              Profit projections based on your deposit and currently available historical yield information.
            </ProjectedProfitDescription>
            <BasedOnWrapper>
              <BasedOnLine />
              <BasedOnText>Based On</BasedOnText>
            </BasedOnWrapper>
            <ProjectedProfitHeader>
              <ProjectedProfitPeriod>Period</ProjectedProfitPeriod>
              <ProjectedProfitCurrent>Current Yield</ProjectedProfitCurrent>
              <ProjectedProfitAverage>30 Day Average</ProjectedProfitAverage>
            </ProjectedProfitHeader>
            <ProjectProfitTable>
              <ProjectedProfitRow>
                <ProjectedProfitPeriod>1 Day</ProjectedProfitPeriod>
                <ProjectedProfitCurrent>
                  {projectedProfit ? formatAmount(projectedProfit.divide(365)) : '-'} <span>{collateral}</span>
                </ProjectedProfitCurrent>
                <ProjectedProfitAverage>
                  {projectedProfit30DayAvg ? formatAmount(projectedProfit30DayAvg.divide(365)) : '-'}{' '}
                  <span>{collateral}</span>
                </ProjectedProfitAverage>
              </ProjectedProfitRow>
              <ProjectedProfitRow>
                <ProjectedProfitPeriod>1 Week</ProjectedProfitPeriod>
                <ProjectedProfitCurrent>
                  {projectedProfit ? formatAmount(projectedProfit.divide(52)) : '-'} <span>{collateral}</span>
                </ProjectedProfitCurrent>
                <ProjectedProfitAverage>
                  {projectedProfit30DayAvg ? formatAmount(projectedProfit30DayAvg.divide(52)) : '-'}{' '}
                  <span>{collateral}</span>
                </ProjectedProfitAverage>
              </ProjectedProfitRow>
              <ProjectedProfitRow>
                <ProjectedProfitPeriod>30 Days</ProjectedProfitPeriod>
                <ProjectedProfitCurrent>
                  {projectedProfit ? formatAmount(projectedProfit.divide(365).multiply(30)) : '-'}{' '}
                  <span>{collateral}</span>
                </ProjectedProfitCurrent>
                <ProjectedProfitAverage>
                  {projectedProfit30DayAvg ? formatAmount(projectedProfit30DayAvg.divide(365).multiply(30)) : '-'}{' '}
                  <span>{collateral}</span>
                </ProjectedProfitAverage>
              </ProjectedProfitRow>
            </ProjectProfitTable>
          </ProjectedProfitWrapper>
        </NewPositionInfo>
        {/*<InfoRow>*/}
        {/*  <div>Liquidated</div>*/}
        {/*  <div></div>*/}
        {/*</InfoRow>*/}
        {/*<Steps>*/}
        {/*  <StrategyStep width={30}>*/}
        {/*    <StrategyProgress>*/}
        {/*      <Circle complete={strategyStage > StrategyStage.OPEN}>*/}
        {/*        {strategyStage === StrategyStage.OPEN ? '1' : <Checkmark />}*/}
        {/*      </Circle>*/}
        {/*      <Line complete={strategyStage > StrategyStage.OPEN} />*/}
        {/*    </StrategyProgress>*/}
        {/*    <StrategyText>Open Position</StrategyText>*/}
        {/*  </StrategyStep>*/}
        {/*  <StrategyStep width={30}>*/}
        {/*    <StrategyProgress>*/}
        {/*      <Circle complete={strategyStage > StrategyStage.BORROW}>*/}
        {/*        {strategyStage < StrategyStage.ZAP ? '2' : <Checkmark />}*/}
        {/*      </Circle>*/}
        {/*      <Line complete={strategyStage > StrategyStage.BORROW} />*/}
        {/*    </StrategyProgress>*/}
        {/*    <StrategyText>Borrow</StrategyText>*/}
        {/*  </StrategyStep>*/}
        {/*  <StrategyStep width={30}>*/}
        {/*    <StrategyProgress>*/}
        {/*      <Circle complete={strategyStage > StrategyStage.ZAP}>*/}
        {/*        {strategyStage < StrategyStage.COMPLETE ? '3' : <Checkmark />}*/}
        {/*      </Circle>*/}
        {/*      <Line complete={strategyStage === StrategyStage.COMPLETE} />*/}
        {/*    </StrategyProgress>*/}
        {/*    <StrategyText>Zap</StrategyText>*/}
        {/*  </StrategyStep>*/}
        {/*  <StrategyStep width={10}>*/}
        {/*    <StrategyProgress>*/}
        {/*      <Line complete={strategyStage === StrategyStage.COMPLETE} />*/}
        {/*      <Circle complete={strategyStage > StrategyStage.ZAP}>*/}
        {/*        {strategyStage < StrategyStage.COMPLETE ? '4' : <Checkmark />}*/}
        {/*      </Circle>*/}
        {/*    </StrategyProgress>*/}
        {/*    <StrategyText style={{ textAlign: 'right' }}>Done</StrategyText>*/}
        {/*  </StrategyStep>*/}
        {/*</Steps>*/}
        <SubmitButtonWrapper>
          <SubmitButton onClick={onConfirm} wrongChain={wrongChain} disabled={submitDisabled}>
            {wrongChain && strategy.chain ? (
              `Change to ${NETWORK_LABELS[strategy.chain]}`
            ) : isAttemptingTx || isTxPending ? (
              <CircularProgress />
            ) : (
              'Create Position'
            )}
          </SubmitButton>
        </SubmitButtonWrapper>
        <BorrowTradeDetails
          bestZap={bestZap}
          isLoading={!bestZap && isLoadingZapTradeData}
          refreshZapData={refreshZapData}
          showError={!!zapError}
          open={true}
          small={true}
          strategy={true}
          loadingDisabled={!bestZap && !isLoadingZapTradeData}
          priceImpact={bestZap?.priceImpact}
        />
        {!!borrowAmount && zapOutputValue && (
          <AdvancedDescriptionWrapper>
            {`Will borrow ${formatAmount(borrowAmount)} ${debt}, trade that ${debt} into ${formatAmount(
              parsedInputValue?.add(zapOutputValue),
            )} ${collateral}, and then deposit that ${collateral} into the position as collateral.`}
          </AdvancedDescriptionWrapper>
        )}
      </Column>
      <DepositWithdrawModal
        open={isDepositModalOpen}
        close={() => setDepositModalOpen(false)}
        presetCurrency={collateralToken}
      />
      <ZapModal open={isSwapModalOpen} closeZap={() => setSwapModalOpen(false)} presetToken={collateralToken} />
    </ModalInner>
  )
}

function CreatePositionModalWrapper({
  strategy,
  isOpen,
  closeModal,
  interestRatePair,
  totalYieldSelected,
}: CreatePositionModalProps) {
  return (
    <Modal isOpen={isOpen} onDismiss={closeModal} maxHeight={700} maxWidthPx={800}>
      {!isOpen || !strategy ? (
        <></>
      ) : (
        <CreatePositionModal
          strategy={strategy}
          isOpen={isOpen}
          closeModal={closeModal}
          interestRatePair={interestRatePair}
          totalYieldSelected={totalYieldSelected}
        />
      )}
    </Modal>
  )
}

export default CreatePositionModalWrapper
