import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { Transaction } from './transaction'
import {
  createCurrencyAmount,
  createFractionUSD,
  createTransaction,
  EntityType,
  Transfer as TransferGql,
} from './gqlTypeHelpers'
import JSBI from 'jsbi'
import { Token } from '@dolomite-exchange/v2-sdk'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import { useAllTokens } from '../hooks/Tokens'
import { CurrencyAmount, Fraction } from '@dolomite-exchange/sdk-core'
import { transferGql } from './queryObjects'
import { createMarginAccount, MarginAccount } from './marginAccount'
import { GraphQueryState, useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency } from '../state/chain/hooks'

const TRANSFERS_BY_MARGIN_ACCOUNT_GQL = gql`
    query transfersByWallet($blockNumber: Int!, $marginAccount: String!) {
        transfersIn:transfers(
            block: { number_gte: $blockNumber },
            where: { toMarginAccount: $marginAccount },
            orderBy: serialId,
            orderDirection: desc,
            first: 100,
        ) {
            ${transferGql()}
        }
        transfersOut:transfers(
            block: { number_gte: $blockNumber },
            where: { fromMarginAccount: $marginAccount },
            orderBy: serialId,
            orderDirection: desc,
            first: 100,
        ) {
            ${transferGql()}
        }
    }
`

const TRANSFERS_BY_MARGIN_ACCOUNT_ARRAY_GQL = gql`
    query transfersByWallet($blockNumber: Int!, $marginAccounts: [String!]!) {
        transfersIn:transfers(
            block: { number_gte: $blockNumber },
            where: { toMarginAccount: $marginAccount },
            orderBy: serialId,
            orderDirection: desc,
            first: 100,
        ) {
            ${transferGql()}
        }
        transfersOut:transfers(
            block: { number_gte: $blockNumber },
            where: { fromMarginAccount: $marginAccount },
            orderBy: serialId,
            orderDirection: desc,
            first: 100,
        ) {
            ${transferGql()}
        }
    }
`

const TRANSFERS_BY_WALLET_ADDRESS_GQL = gql`
    query transfersByWallet($blockNumber: Int!, $account: String!) {
        transfers(
            block: { number_gte: $blockNumber },
            where: { effectiveUsers_contains: [$account] },
            orderBy: serialId,
            orderDirection: desc,
            first: 100,
        ) {
            ${transferGql()}
        }
    }
`

export interface Transfer {
  id: string
  type: EntityType
  serialId: JSBI
  transaction: Transaction
  logIndex: JSBI
  fromMarginAccount: MarginAccount
  fromAccountAddress: string
  toMarginAccount: MarginAccount
  toAccountAddress: string
  token: Token
  amount: CurrencyAmount<Token>
  amountUSD: Fraction
  toEffectiveUser: string
  fromEffectiveUser: string
}

interface TransferResponse {
  transfers: TransferGql[]
}

interface TransferInAndOutResponse {
  transfersIn: TransferGql[]
  transfersOut: TransferGql[]
}

/**
 * Returns a list of transfers for a given margin account, sorted by timestamp, descending.
 * @param marginAccount The margin account (or position) whose transfers should be retrieved
 */
export function useCombinedTransfersByMarginAccount(
  marginAccount?: MarginAccount,
): {
  loading: boolean
  error: boolean
  data: Transfer[]
} {
  const result = useTransfersByMarginAccount(marginAccount)
  const data = useMemo(() => {
    return result.transfersIn.concat(result.transfersOut).sort((a, b) => {
      return JSBI.greaterThan(b.serialId, a.serialId) ? 1 : -1
    })
  }, [result.transfersIn, result.transfersOut])
  return useMemo(() => {
    return {
      data,
      loading: result.loading,
      error: result.error,
    }
  }, [result.loading, result.error, data])
}

/*export function useCombinedTransfersByMarginAccountList(
  marginAccounts?: MarginAccount[],
): {
  loading: boolean
  error: boolean
  data: Record<string, Transfer[]>
} {
  const result = useTransfersByMarginAccountArray(marginAccounts)
  const data = useMemo(() => {
    return result.transfersIn.concat(result.transfersOut).sort((a, b) => {
      return JSBI.greaterThan(b.serialId, a.serialId) ? 1 : -1
    })
  }, [result.transfersIn, result.transfersOut])
  return useMemo(() => {
    return {
      data: { test: data },
      loading: result.loading,
      error: result.error,
    }
  }, [result.loading, result.error, data])
}*/

/**
 * Returns a list of transfers for a given margin account, sorted by timestamp, descending.
 * @param marginAccount The margin account (or position) whose transfers should be retrieved
 */
export function useTransfersByMarginAccount(
  marginAccount?: MarginAccount,
): {
  loading: boolean
  error: boolean
  transfersIn: Transfer[]
  transfersOut: Transfer[]
} {
  const variables = useMemo(() => {
    if (!marginAccount) {
      return undefined
    }
    return {
      marginAccount: marginAccount.toString(),
    }
  }, [marginAccount])
  const queryState = useGraphqlResult<TransferInAndOutResponse>(
    GraphqlClientType.Dolomite,
    TRANSFERS_BY_MARGIN_ACCOUNT_GQL.loc!.source.body,
    variables,
    RefreshFrequency.Fast,
  )

  const tokenMap = useAllTokens()

  return useMemo(() => {
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)
    const transfersIn = mapTransfersGqlToTransfers(result?.transfersIn ?? [], tokenMap)
    const transfersOut = mapTransfersGqlToTransfers(result?.transfersOut ?? [], tokenMap)
    return {
      loading: anyLoading,
      error: anyError,
      transfersIn,
      transfersOut,
    }
  }, [queryState, tokenMap])
}

/**
 * Returns a list of transfers for a given array of margin accounts, sorted by timestamp, descending.
 * @param marginAccounts The margin accounts (or positions) whose transfers should be retrieved
 */
/*export function useTransfersByMarginAccountArray(
  marginAccounts?: MarginAccount[],
): {
  loading: boolean
  error: boolean
  transfersIn: Transfer[]
  transfersOut: Transfer[]
} {
  const variables = useMemo(() => {
    if (!marginAccounts || marginAccounts.length === 0) {
      return undefined
    }
    return {
      marginAccounts: marginAccounts.map(marginAccount => marginAccount.toString()),
    }
  }, [marginAccounts])
  console.log(variables)
  const queryState = useGraphqlResult<TransferInAndOutResponse>(
    GraphqlClientType.Dolomite,
    TRANSFERS_BY_MARGIN_ACCOUNT_ARRAY_GQL.loc!.source.body,
    variables,
    RefreshFrequency.Fast,
  )

  const tokenMap = useAllTokens()

  return useMemo(() => {
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)
    console.log(result)
    const transfersIn = mapTransfersGqlToTransfers(result?.transfersIn ?? [], tokenMap)
    const transfersOut = mapTransfersGqlToTransfers(result?.transfersOut ?? [], tokenMap)
    return {
      loading: anyLoading,
      error: anyError,
      transfersIn,
      transfersOut,
    }
  }, [queryState, tokenMap])
}*/

export function useTransfersByWalletAddress(
  account?: string,
): {
  loading: boolean
  error: boolean
  data: Transfer[]
} {
  const variables = useMemo(() => {
    if (!account) {
      return undefined
    }
    return {
      account: account.toLowerCase(),
    }
  }, [account])
  const queryState = useGraphqlResult<TransferResponse>(
    GraphqlClientType.Dolomite,
    TRANSFERS_BY_WALLET_ADDRESS_GQL.loc!.source.body,
    variables,
    RefreshFrequency.Fast,
  )

  return useTransfers(queryState)
}

function useTransfers(
  queryState: GraphQueryState<TransferResponse>,
): {
  loading: boolean
  error: boolean
  data: Transfer[]
} {
  const tokenMap = useAllTokens()

  return useMemo(() => {
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)
    const transfers = mapTransfersGqlToTransfers(result?.transfers ?? [], tokenMap)
    return {
      loading: anyLoading,
      error: anyError,
      data: transfers,
    }
  }, [queryState, tokenMap])
}

function mapTransfersGqlToTransfers(transfers: TransferGql[], tokenMap: Record<string, Token | undefined>): Transfer[] {
  return transfers
    .map<Transfer | undefined>(transfer => {
      const token: Token | undefined = tokenMap[toChecksumAddress(transfer.token.id)]
      if (!token) {
        return undefined
      }
      return {
        id: transfer.id,
        type: EntityType.Transfer,
        serialId: JSBI.BigInt(transfer.serialId),
        transaction: createTransaction(transfer.transaction),
        logIndex: JSBI.BigInt(transfer.logIndex),
        fromMarginAccount: createMarginAccount(transfer.fromMarginAccount),
        fromAccountAddress: transfer.fromAccountAddress,
        toMarginAccount: createMarginAccount(transfer.toMarginAccount),
        toAccountAddress: transfer.toAccountAddress,
        token: token,
        amountUSD: createFractionUSD(transfer.amountUSDDeltaWei),
        amount: createCurrencyAmount(token, transfer.amountDeltaWei),
        toEffectiveUser: transfer.toEffectiveUser.effectiveUser.id,
        fromEffectiveUser: transfer.fromEffectiveUser.effectiveUser.id,
      }
    })
    .filter(transfer => !!transfer)
    .map<Transfer>(transfer => transfer!)
}
