import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { Overlay, PanelSubtitle, PanelTitle } from './index'
import Input from '@material-ui/core/Input'
import ARBLogo from '../../assets/markets/ARB.svg'
import { tryParseAmount, tryParseAmountWithNoCurrency } from '../../state/trade/hooks'
import { formatAmount } from '../../utils/formatAmount'
import { useAllActiveTokensArray, useArbToken, useEther, useOToken } from '../../hooks/Tokens'
import Modal from '../../components/Modal'
import ETHLogo from '../../assets/markets/ETH.svg'
import { StyledTooltipWithIcon } from '../../components/common/StyledTooltip'
import { CurrencyAmount, Percent, Token, ZERO } from '@dolomite-exchange/v2-sdk'
import CircularProgress from '@material-ui/core/CircularProgress'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { useDolomiteBalancesWithLoadingIndicator, useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import {
  LIQUIDITY_MINING_LEVEL_THRESHOLD,
  REWARDS_DEPOSIT_THRESHOLD,
  useAvailableRewardTokensForLiquidityMining,
  useEffectiveLevelForUser,
  useTokensWithOTokenRewards,
  useVestOToken,
} from '../../hooks/useLiquidityMining'
import { useIsTransactionPending } from '../../state/transactions/hooks'
import {
  useFiatPricesWithLoadingIndicator,
  useFiatValuesWithLoadingIndicator,
  useFiatValueWithLoadingIndicator,
} from '../../hooks/useFiatValue'
import { ARB, ONE_FRACTION } from '../../constants'
import { useLiquidityMiningVesterContract } from '../../hooks/useContract'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import Slider from '@mui/material/Slider'
import { useGalxeAddressData } from '../../types/galxeAddressData'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'
import { Ether, Fraction } from '@dolomite-exchange/sdk-core'
import { useAggregateBalanceData } from '../../types/aggregateBalanceData'

const VestOARB = styled.div`
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  width: 500px;
  height: auto;
  border-radius: 8px;
  padding: 25px 35px 30px;
  background-color: ${({ theme }) => theme.bg1};
  display: inline-block;
  vertical-align: top;
  margin: 20px;
  position: relative;
  overflow: hidden;
  text-align: left;

  @media screen and (max-width: 650px) {
    width: 100%;
    margin: 20px 0 0;
    padding: 20px 25px 25px;
  }
`

const DocsLink = styled.div`
  width: 100%;
  text-align: left;
  margin-top: -10px;
  margin-bottom: 15px;
  font-size: 12px;

  a {
    color: ${({ theme }) => theme.text1} !important;
    text-decoration: none !important;
    font-weight: 700;

    :hover {
      color: ${({ theme }) => theme.text2} !important;
    }
  }
`

const ARBPoolWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: -6px 0 8px;
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};

  @media screen and (max-width: 650px) {
    font-size: 13px;
    margin: 0 0 15px;
  }
`

const ARBPoolText = styled.div`
  svg {
    margin-bottom: -2px !important;
    margin-left: 0 !important;
  }

  @media screen and (max-width: 650px) {
    max-width: 45%;
  }
`

const ARBPoolRemaining = styled.div<{ showError: boolean }>`
  display: inline-block;
  color: ${({ theme, showError }) => (showError ? theme.red1 : theme.text1)};

  @media screen and (max-width: 650px) {
    line-height: 36px;
  }
`

const VestLeft = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: top;
  margin-top: 15px;
`

const VestRight = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: top;
  text-align: right;
  margin-top: 15px;
`

const InputSection = styled.div`
  width: 100%;
  margin-top: -8px;
`

const InputBalance = styled.div<{ showError: boolean }>`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme, showError }) => (showError ? theme.red1 : theme.text2)};
  display: flex;
  justify-content: space-between;

  span:first-child {
    color: ${({ theme }) => theme.text1} !important;
  }
`

const InputDollarValue = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
  float: right;
  margin-right: 4px;
  margin-top: 1px;
`

const StyledInput = styled(({ ...props }) => <Input {...props} />)<{ multiline: boolean }>`
  overflow: hidden;
  margin-bottom: 0 !important;
  height: 33px !important;
  background-color: ${({ theme }) => theme.bg6} !important;
  color: ${({ theme }) => theme.text1} !important;
  border-radius: 5px !important;
  padding: 0 16px !important;
  font-weight: 400 !important;
  font-family: 'Open Sans', sans-serif !important;

  input {
    margin-bottom: 0 !important;
  }

  ${({ multiline }) =>
    multiline &&
    `
    margin-top: 2px;
    width: 100% !important;

    textarea {
      overflow: hidden !important;
      padding: 0 8px !important;
      width: calc(100% - 8px) !important;
    }
  `};
  @media (max-width: 1400px) {
    input {
      font-size: 0.9rem;
    }

    p {
      font-size: 0.8rem;
    }
  }
`

const PlusSign = styled.div`
  display: inline-block;
  vertical-align: top;
  width: fit-content;
  font-size: 32px;
  color: ${({ theme }) => theme.text2};
  font-weight: 200;
  margin: 0 0 0 10px;

  @media screen and (max-width: 650px) {
    margin: 0 0 0 5px;
    float: left;
  }
`

const ARBAmountWrapper = styled.div`
  width: calc(100% - 60px);
  display: inline-block;
  vertical-align: top;
  padding-top: 5px;

  @media screen and (max-width: 650px) {
    width: calc(100% - 25px);
    margin-top: 7px;
    padding-top: 0;
  }
`

const ARBIcon = styled.div`
  display: inline-block;
  vertical-align: top;
  width: fit-content;
  margin-right: 3px;

  img {
    width: 20px;
    height: auto;
  }

  @media screen and (max-width: 650px) {
    img {
      width: 15px;
    }
  }
`

const ARBAmount = styled.div`
  display: inline-block;
  vertical-align: top;
  width: fit-content;
  font-size: 15px;
  color: ${({ theme }) => theme.text1};
  font-weight: 400;

  @media screen and (max-width: 650px) {
    font-size: 13px;
  }
`

const ARBWalletBalance = styled.div<{ showError: boolean }>`
  display: inline-block;
  vertical-align: top;
  width: fit-content;
  min-width: 100px;
  font-size: 11px;
  color: ${({ theme, showError }) => (showError ? theme.red1 : theme.text2)};
  font-weight: 400;
  cursor: pointer;

  svg {
    height: 11px;
  }

  @media screen and (max-width: 650px) {
    font-size: 10px;
    margin-top: -2px;
  }
`

const ARBTicker = styled.span`
  color: ${({ theme }) => theme.text2};
  margin-left: 3px;
`

const PeriodSelector = styled.div`
  width: 100%;
`

const SliderTitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 3px;
  font-size: 13px;

  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
`

const SliderTitle = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.text1};
`

const PeriodTitle = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.text2};
  /*margin-top: 15px;*/
`

const PeriodsWrapper = styled.div`
  width: 100%;
  height: 34px;
  display: flex;
  justify-content: space-between;
`

const VestingDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
  margin-top: 15px;

  @media screen and (max-width: 650px) {
    font-size: 13px;
  }
`

const VestButton = styled.div<{ isActive: boolean }>`
  width: 100%;
  height: 38px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.green2};
  color: ${({ theme }) => theme.text1};
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  padding: 8px 20px;
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'default')};
  margin-top: 15px;
  text-align: center;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.6)};
  pointer-events: ${({ isActive }) => (isActive ? 'all' : 'none')};

  :hover {
    background-color: ${({ theme, isActive }) => (isActive ? theme.green1 : theme.green2)};
  }
`

const ModalInner = styled.div`
  padding: 24px 32px;
  width: 100%;
`

const ModalTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.text1};
  margin-bottom: 15px;
`

const ModalSubtitle = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.text3};
  margin-bottom: 12px;
`

const ModalRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`

const ModalRowSection = styled.div`
  text-align: center;
  width: calc(50% - 50px);
`

const ExecutionDate = styled.span`
  color: ${({ theme }) => theme.text3};
`

const Left = styled(ModalRowSection)``

const LeftBottom = styled(Left)`
  width: calc(50% - 50px);
  margin: 20px 5% 0;
`

const Right = styled(ModalRowSection)`
  ${PlusSign} {
    width: 100%;
    font-size: 20px;
    margin: 6px 0;
  }
`

const BottomRow = styled(ModalRow)`
  ${Right} {
    width: calc(60% - 50px);
  }

  margin-bottom: 30px;
`

const Logo = styled.div`
  height: 30px;
  margin: 0 auto 4px;

  img,
  svg {
    width: auto;
    height: 100%;
  }
`

const Amount = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
`

const SubAmount = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
`

const ModalRowSubsection = styled.div`
  width: 100%;
  display: inline-block;

  ${Logo} {
    height: 20px;
  }
`

const Top = styled(ModalRowSubsection)``

const Bottom = styled(ModalRowSubsection)``

const ExecuteButton = styled.div<{ hasApproved: boolean }>`
  width: 100%;
  height: 38px;
  border-radius: 5px;
  background-color: ${({ theme, hasApproved }) => (hasApproved ? theme.green2 : theme.blue1)};
  color: ${({ theme }) => theme.text1};
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  padding: 8px 20px;
  cursor: pointer;
  margin-top: 15px;
  text-align: center;

  :hover {
    background-color: ${({ theme, hasApproved }) => (hasApproved ? theme.green1 : theme.blue2)};
  }

  > svg,
  > div {
    color: ${({ theme }) => theme.text1} !important;
    font-size: 20px;
    height: 20px !important;
    width: 20px !important;
  }
`

const DescriptionText = styled.div`
  font-size: 12px;
  font-weight: 100;
  line-height: 14px;
  color: ${({ theme }) => theme.text2};
  width: 100%;
  position: relative;
  margin: -10px auto 10px;
  padding: 12px 16px 12px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.bg2};

  svg {
    margin-right: 5px;
    margin-bottom: -3px;
    font-size: 24px;
    color: ${({ theme }) => theme.text3};
    display: inline-block;
    vertical-align: top;
  }

  a {
    color: ${({ theme }) => theme.text2};
    font-weight: 600;
  }

  @media screen and (max-width: 650px) {
  }
`

const YourLevelWrapper = styled.div`
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  margin-top: -10px;
`

const DiscountWrapper = styled.div<{ disabled: boolean }>`
  border: 1px solid ${({ theme, disabled }) => (disabled ? theme.bg3 : theme.blue1)};
  background: ${({ disabled }) => (disabled ? 'none' : '#22344e')};
  box-shadow: ${({ disabled }) => (disabled ? 'none' : '#2172E5 0px 0px 9px -4px')};
  border-radius: 5px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  padding: 10px 15px;
  margin: 5px 0;
  width: calc(50% - 5px);
  display: inline-block;
  vertical-align: top;

  @media screen and (max-width: 450px) {
    width: 100%;
    margin-left: 0 !important;
  }
`

const LevelWrapper = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.text1};
  font-weight: 600;
`

const DiscountAmount = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.text1};
  font-weight: 500;

  @media screen and (max-width: 500px) {
    font-size: 11px;
  }
`

const EarnMoreLevels = styled.div`
  margin-top: 4px;
  font-size: 13px;
  font-weight: 300;
  text-align: center;
  color: ${({ theme }) => theme.text1};

  a {
    color: ${({ theme }) => theme.text1};
    text-decoration: none !important;
    font-weight: 700;
  }

  @media screen and (max-width: 450px) {
    font-size: 12px;
  }
`

const OrWrapper = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.text1};
  margin: 5px 0;
`

const TotalDepositSizeWrapper = styled.div<{ percent: number }>`
  padding: 15px;
  border: 1px solid ${({ theme, percent }) => (percent === 100 ? theme.blue1 : theme.bg3)};
  background: ${({ percent }) => (percent === 100 ? '#22344e' : 'none')};
  box-shadow: ${({ percent }) => (percent === 100 ? '#2172E5 0px 0px 9px -4px' : 'none')};
  border-radius: 5px;
  width: 100%;
  display: inline-block;
  ${({ percent }) => percent === 100 && `box-shadow: #2172E5 0px 0px 9px -4px;`}
  vertical-align: top;
  
  @media screen and (max-width: 420px) {
    padding-bottom: 12px;
  }
}
`

const DescriptionContent = styled.div`
  width: calc(100% - 30px);
  display: inline-block;
  vertical-align: top;
`

const DepositSizeLeft = styled.div`
  width: 35%;
  display: inline-block;
  vertical-align: top;
  font-size: 11px;

  @media screen and (max-width: 460px) {
    width: 40%;
    font-size: 10px;
  }

  @media screen and (max-width: 400px) {
    width: 45%;
  }
`

const DepositSizeTitle = styled.div`
  width: 100%;
  font-size: 13px;
  color: ${({ theme }) => theme.text1};
  font-weight: 600;

  @media screen and (max-width: 460px) {
    font-size: 12px;
  }

  @media screen and (max-width: 420px) {
    font-size: 11px;
  }
`

const DepositSizeDiscount = styled.span`
  width: 100%;
  font-size: 11px;
  color: ${({ theme }) => theme.text2};
  font-weight: 500;
`

const DepositSizeRight = styled.div`
  width: 65%;
  font-size: 11px;
  font-weight: 700;
  display: inline-block;
  vertical-align: top;
  text-align: right;
  padding-top: 4px;
  padding-right: 2.5%;

  @media screen and (max-width: 460px) {
    width: 60%;
  }

  @media screen and (max-width: 400px) {
    width: 55%;
  }
`

const DepositSizeBarWrapper = styled.div`
  width: 100%;
  display: inline-block;
  vertical-align: top;
`

const DepositAmount = styled.div`
  width: 85%;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  margin: -5px 0 -5px auto;
`

const DepositSizeBar = styled.div<{ percent: number }>`
  width: 60%;
  height: 5px;
  border-radius: 2.5px;
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.blue1} ${({ percent }) => percent}%,
    ${({ theme }) => theme.bg6} ${({ percent }) => percent}%
  );
  display: inline-block;
  vertical-align: top;
  margin-top: 6px;
`

const DepositSizeFraction = styled.div`
  width: 85%;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  display: inline-block;
  vertical-align: top;
  margin-top: -3px;

  @media screen and (max-width: 460px) {
    width: 100%;
    text-align: right;
    font-size: 13px;
    margin-top: -5px;
  }

  @media screen and (max-width: 420px) {
    font-size: 12px;
    margin-top: -4px;
  }
`

const DepositSizeDiscountWrapper = styled.div`
  width: 85%;
  display: inline-block;
  vertical-align: top;
  /*text-align: right;
  padding-right: 22px;*/
  font-weight: 400;
  text-align: center;

  @media screen and (max-width: 460px) {
    width: 100%;
    text-align: right;
    font-size: 10px;
    margin-top: -1px;
  }

  @media screen and (max-width: 420px) {
    font-size: 9px;
    margin-top: -4px;
  }
`

const DiscountText = styled.span`
  @media screen and (max-width: 420px) {
    display: none;
  }
`

const DiscountTextSmall = styled.span`
  display: none;

  @media screen and (max-width: 420px) {
    display: inline;
  }
`

const Spacer = styled.div`
  height: 8px;
  width: 100%;

  @media screen and (max-width: 650px) {
    height: 20px;
  }
`

const SliderWrapper = styled.div`
  display: inline-block;
  width: calc(100% - 100px);
  margin-left: 10px;
`

const SliderNumber = styled.div`
  display: inline-block;
  margin-top: 7px;
  font-size: 13px;
  font-weight: 700;
  vertical-align: top;
  color: ${({ theme }) => theme.text2};
`

const SliderStart = styled(SliderNumber)`
  margin-right: 4px;
`

const SliderEnd = styled(SliderNumber)`
  margin-left: 5px;
  float: right;
`

const ConfirmationModal = ({
  showVestModal,
  dismissModal,
  vestAmount,
  vestDurationSeconds,
  effectiveVestDurationSeconds,
  vestDiscount,
  executionDate,
}: {
  showVestModal: boolean
  dismissModal: (success: boolean) => void
  vestAmount: CurrencyAmount<Token> | undefined
  vestDurationSeconds: number
  effectiveVestDurationSeconds: Fraction
  vestDiscount: Percent
  executionDate: Date
  level: number
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [awaitingSignature, setAwaitingSignature] = useState(false)
  const [pendingHash, setPendingHash] = useState<string | undefined>(undefined)
  const { callback: vestCallback } = useVestOToken(vestDurationSeconds, effectiveVestDurationSeconds, vestAmount)
  const isHashPending = useIsTransactionPending(pendingHash)
  useEffect(() => {
    if (!isHashPending) {
      setPendingHash(undefined)
    }
    setIsSubmitting(isHashPending)
  }, [isHashPending])

  const oArbToken = useOToken()
  const vesterProxyContract = useLiquidityMiningVesterContract()

  const oArbAmount = useMemo(
    () => (oArbToken ? CurrencyAmount.fromRawAmount(oArbToken, vestAmount?.numerator ?? ZERO) : undefined),
    [oArbToken, vestAmount],
  )
  const [approvalState, approvalCallback] = useApproveCallback(oArbAmount, vesterProxyContract?.address)

  const onVest = useCallback(() => {
    if (!vestCallback || awaitingSignature) {
      return
    }

    setIsSubmitting(true)
    setAwaitingSignature(true)
    if (approvalState === ApprovalState.APPROVED) {
      vestCallback()
        .then(hash => {
          setPendingHash(hash)
          setAwaitingSignature(false)
          const vestEvent = new Event('onCreateVest')
          document.dispatchEvent(vestEvent)
          dismissModal(true)
        })
        .catch(() => {
          setAwaitingSignature(false)
          setIsSubmitting(false)
        })
    } else {
      approvalCallback()
        .then((hash: any) => {
          if (typeof hash === 'string') {
            setPendingHash(hash)
          }
          setAwaitingSignature(false)
        })
        .catch(() => {
          setIsSubmitting(false)
          setAwaitingSignature(false)
        })
    }
  }, [approvalCallback, approvalState, awaitingSignature, vestCallback, dismissModal])

  const onDismiss = useCallback(() => {
    dismissModal(false)
  }, [dismissModal])

  return (
    <Modal isOpen={showVestModal} onDismiss={onDismiss}>
      <ModalInner>
        <ModalTitle>Vest Summary</ModalTitle>
        <ModalSubtitle>Will vest</ModalSubtitle>
        <ModalRow>
          <Left>
            <Logo>
              <img src={ARBLogo} alt={'ARB logo'} />
            </Logo>
            <Amount>{formatAmount(vestAmount)} oARB</Amount>
            <SubAmount>From your claimed balance</SubAmount>
          </Left>
          <PlusSign>+</PlusSign>
          <Right>
            <Logo>
              <img src={ARBLogo} alt={'ARB logo'} />
            </Logo>
            <Amount>{formatAmount(vestAmount)} ARB</Amount>
            <SubAmount>From your Dolomite Balance</SubAmount>
          </Right>
        </ModalRow>
        <ModalSubtitle>
          On execution <ExecutionDate>({executionDate.toDateString() || '-'})</ExecutionDate>
        </ModalSubtitle>
        {vestDiscount.equalTo(1) ? (
          <ModalRow>
            <Left>
              <Logo>
                <img src={ARBLogo} alt={'ARB logo'} />
              </Logo>
              <Amount>{formatAmount(vestAmount)} ARB</Amount>
              <SubAmount>Vested from oARB</SubAmount>
            </Left>
            <PlusSign>+</PlusSign>
            <Right>
              <Logo>
                <img src={ARBLogo} alt={'ARB logo'} />
              </Logo>
              <Amount>{formatAmount(vestAmount)} ARB</Amount>
              <SubAmount>Released from your lock</SubAmount>
            </Right>
          </ModalRow>
        ) : (
          <BottomRow>
            <LeftBottom>
              <Logo>
                <img src={ETHLogo} alt={'ETH logo'} />
              </Logo>
              <Amount>ETH</Amount>
              <SubAmount>
                Paid from Dolomite Balance, {vestDiscount.toFixed(2)}% below the market rate for{' '}
                {formatAmount(vestAmount)} ARB
              </SubAmount>
              {/*<SubAmount>Discounted {vestDiscount.toFixed(2)}% from market rate</SubAmount>*/}
            </LeftBottom>
            <PlusSign style={{ paddingTop: '60px' }}>→</PlusSign>
            <Right>
              <Top>
                <Logo>
                  <img src={ARBLogo} alt={'ARB logo'} />
                </Logo>
                <Amount>{formatAmount(vestAmount)} ARB</Amount>
                <SubAmount>Vested from oARB</SubAmount>
              </Top>
              <PlusSign>+</PlusSign>
              <Bottom>
                <Logo>
                  <img src={ARBLogo} alt={'ARB logo'} />
                </Logo>
                <Amount>{formatAmount(vestAmount)} ARB</Amount>
                <SubAmount>Released from your lock</SubAmount>
              </Bottom>
            </Right>
          </BottomRow>
        )}
        <DescriptionText>
          <WarningRoundedIcon />
          {approvalState === ApprovalState.APPROVED ? (
            <DescriptionContent>
              {vestDiscount.equalTo(ONE_FRACTION)
                ? `Until vesting completes, your ARB and oARB will be locked. After vesting completes, you will be able to to execute your vest.`
                : `Until vesting completes, your ARB and oARB will be locked. After vesting completes, you will be able to purchase the discounted ARB using ETH.`}
              <a href={'https://docs.dolomite.io/oarb-incentives-program'} target={'_blank'} rel={'noreferrer'}>
                Learn more
              </a>
            </DescriptionContent>
          ) : (
            <DescriptionContent>
              You must first unlock your oARB balance for spending by the Vester contract. After unlocking your oARB for
              spending, a second transaction will allow you to vest your oARB.
            </DescriptionContent>
          )}
        </DescriptionText>
        <ExecuteButton
          onClick={() => !isSubmitting && !awaitingSignature && onVest()}
          hasApproved={approvalState === ApprovalState.APPROVED}
        >
          {isSubmitting || awaitingSignature ? (
            <CircularProgress />
          ) : (
            <>{approvalState === ApprovalState.APPROVED ? 'Vest' : 'Unlock'}</>
          )}
        </ExecuteButton>
        <Spacer />
      </ModalInner>
    </Modal>
  )
}

const ONE_WEEK = 86_400 * 7
const SLIDER_START = 2.5
const SLIDER_END = 100

function VestPanel() {
  const { account, chainId } = useActiveWeb3React()
  const [inputValue, setInputValue] = useState('')
  const [discountPercentInput, setDiscountPercentInput] = useState<number>(SLIDER_START)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const arbToken = useArbToken()
  const allTokens = useAllActiveTokensArray()
  const [dolomiteBalanceMap, isLoadingDolomiteBalanceMap] = useDolomiteBalancesWithLoadingIndicator(account, allTokens)
  const { levelData } = useGalxeAddressData(account)
  const { level } = levelData
  const userArbBalance = dolomiteBalanceMap[arbToken?.address ?? '']
  const oArbToken = useOToken()
  const userOArbBalance = useTokenBalance(account, oArbToken)
  const poolSize = useAvailableRewardTokensForLiquidityMining()
  const userLevelOnChain = useEffectiveLevelForUser()
  const rewardTokens = useTokensWithOTokenRewards()
  const { data: tokensToBalances } = useAggregateBalanceData(account, rewardTokens)
  const [tokenFiatBalanceMap] = useFiatPricesWithLoadingIndicator(rewardTokens)
  const totalDepositedFiatValue = useMemo(
    () =>
      rewardTokens.reduce((total, token) => {
        return total.add(
          tokenFiatBalanceMap[token.address]?.asFraction.multiply(
            (tokensToBalances && tokensToBalances[token.address]?.asFraction) ?? new Fraction(0),
          ) ?? new Fraction(0),
        )
      }, new Fraction(0)),
    [rewardTokens, tokenFiatBalanceMap, tokensToBalances],
  )

  const vestDurationSeconds = useMemo(() => {
    return (discountPercentInput / 2.5) * ONE_WEEK
  }, [discountPercentInput])

  const qualifiedForAcceleratedVesting =
    level >= LIQUIDITY_MINING_LEVEL_THRESHOLD || totalDepositedFiatValue.greaterThan(REWARDS_DEPOSIT_THRESHOLD)

  const effectiveVestDurationSeconds = useMemo(() => {
    return qualifiedForAcceleratedVesting ? Math.floor((vestDurationSeconds * 2) / 3) : vestDurationSeconds
  }, [qualifiedForAcceleratedVesting, vestDurationSeconds])

  const vestDurationWeeks = useMemo(() => {
    return tryParseAmountWithNoCurrency((vestDurationSeconds / ONE_WEEK).toString())
  }, [vestDurationSeconds])

  const effectiveVestDurationWeeks = useMemo(() => {
    return tryParseAmountWithNoCurrency((effectiveVestDurationSeconds / ONE_WEEK).toString())
  }, [effectiveVestDurationSeconds])

  const vestingRate = useMemo(() => {
    return new Percent(discountPercentInput * 4, 400)
  }, [discountPercentInput])

  const parsedInputValue = useMemo(() => {
    return tryParseAmount(inputValue, oArbToken)
  }, [oArbToken, inputValue])

  const [inputFiatValueAmount] = useFiatValueWithLoadingIndicator(parsedInputValue?.asFraction, ARB[chainId])

  const executionDate = useMemo(() => {
    return new Date(new Date().getTime() + effectiveVestDurationSeconds * 1000)
  }, [effectiveVestDurationSeconds])

  const updateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^\d*(\.\d*)?$/ //Only allow numbers and a single decimal point

    if (e.target.value === '' || re.test(e.target.value)) {
      setInputValue(e.target.value)
    }
  }

  const submitVest = useCallback(() => {
    setShowConfirmationModal(true)
  }, [])

  const onDismiss = useCallback((isSuccess: boolean) => {
    setShowConfirmationModal(false)
    if (isSuccess) {
      setInputValue('')
    }
  }, [])

  const setMax = useCallback(() => {
    setInputValue(
      poolSize?.greaterThan(userOArbBalance?.asFraction || ZERO)
        ? userOArbBalance?.toFixed(arbToken?.decimals) ?? ''
        : poolSize?.toFixed(arbToken?.decimals) ?? '',
    )
  }, [arbToken?.decimals, poolSize, userOArbBalance])

  const setInputToBalance = useCallback(() => {
    setInputValue(userArbBalance?.toFixed(arbToken?.decimals) ?? '')
  }, [arbToken?.decimals, userArbBalance])

  const inputAmountIsValid =
    !isLoadingDolomiteBalanceMap &&
    !!parsedInputValue &&
    parsedInputValue.lessThanOrEqual(userArbBalance ?? ZERO) &&
    parsedInputValue.lessThanOrEqual(userOArbBalance ?? ZERO) &&
    parsedInputValue.greaterThan(ZERO) &&
    parsedInputValue.lessThanOrEqual(poolSize ?? ZERO)

  return (
    <VestOARB>
      <ConfirmationModal
        showVestModal={showConfirmationModal}
        dismissModal={onDismiss}
        vestAmount={parsedInputValue}
        vestDurationSeconds={vestDurationSeconds}
        effectiveVestDurationSeconds={new Fraction(effectiveVestDurationSeconds)}
        vestDiscount={vestingRate}
        executionDate={executionDate}
        level={userLevelOnChain ?? 0}
      />
      <PanelTitle>3. Vest oARB</PanelTitle>
      <PanelSubtitle>
        {`Vest your oARB to be able to purchase ARB at a discount from the market price. To vest, pair up oARB with an
          equivalent amount of ARB and choose a lockup period. The longer the period, the larger your discount. After
          the lockup, the ARB you used for pairing is returned to you and you can purchase ARB using ETH at the
          discount that matches your lockup period..`}
      </PanelSubtitle>
      <DocsLink>
        Read more about oARB vesting in{' '}
        <a href={'https://docs.dolomite.io/oarb-incentives-program'} target={'_blank'} rel={'noreferrer'}>
          our documentation
        </a>
        .
      </DocsLink>
      <ARBPoolWrapper>
        <ARBPoolText>
          Amount of ARB remaining in pool{' '}
          <StyledTooltipWithIcon
            tooltipText={
              'There is a limited amount of ARB from the Arbitrum STIP grant, and you will only be able to vest up to the remaining ARB in the pool.'
            }
          />
        </ARBPoolText>
        <ARBPoolRemaining
          showError={poolSize ? parsedInputValue?.asFraction.greaterThan(poolSize.asFraction) ?? false : false}
        >
          {formatAmount(poolSize)} ARB
        </ARBPoolRemaining>
      </ARBPoolWrapper>
      <VestLeft>
        <InputSection>
          <InputBalance showError={userOArbBalance ? parsedInputValue?.greaterThan(userOArbBalance) ?? false : false}>
            <span>Balance:</span>
            <span style={{ cursor: 'pointer' }} onClick={setMax}>
              {formatAmount(userOArbBalance)} oARB
            </span>
          </InputBalance>
          <StyledInput
            onChange={updateInput}
            multiline={false}
            fullWidth
            spellCheck={false}
            placeholder={'0.00'}
            value={inputValue}
            variant='amountInput'
            disableUnderline={true}
            endAdornment={<span style={{ marginLeft: 4 }}>oARB</span>}
          />
          <InputDollarValue>{formatAmount(inputFiatValueAmount, 2, true, '$0.00', true)}</InputDollarValue>
        </InputSection>
      </VestLeft>
      <VestRight>
        <PlusSign>+</PlusSign>
        <ARBAmountWrapper>
          <ARBIcon>
            <img src={ARBLogo} alt='arb' />
          </ARBIcon>
          <ARBAmount>
            {inputValue && inputValue !== '' ? formatAmount(parsedInputValue, 2) : '0.00'}
            <ARBTicker>ARB</ARBTicker>
          </ARBAmount>
          <ARBWalletBalance
            showError={userArbBalance && parsedInputValue ? parsedInputValue.greaterThan(userArbBalance) : false}
            onClick={setInputToBalance}
          >
            <StyledTooltipWithIcon
              tooltipText={
                'Vesting uses ARB from your Dolomite Balance. If you have ARB in your wallet, make sure to deposit it to Dolomite in order to vest.'
              }
            />
            {formatAmount(userArbBalance, 2)} ARB balance
          </ARBWalletBalance>
        </ARBAmountWrapper>
      </VestRight>
      <DescriptionText
        style={{
          marginBottom: '23px',
          marginTop: '5px',
        }}
      >
        <InfoRoundedIcon />
        <DescriptionContent>
          Users that are level 4 or higher or who hold more than $100k on Dolomite receive accelerated vesting,
          receiving a 3.75% discount per week rather than 2.5%.{' '}
          <a href={'https://docs.dolomite.io/community-xp'} target={'_blank'} rel={'noreferrer'}>
            Learn more about the Dolomite XP Program
          </a>
          .
        </DescriptionContent>
      </DescriptionText>
      <YourLevelWrapper>
        <span style={{ marginLeft: '-4px' }}>Your Level:</span>
        <span style={{ marginLeft: '4px' }}>{level}</span>
      </YourLevelWrapper>
      <DiscountWrapper disabled={qualifiedForAcceleratedVesting}>
        <LevelWrapper>Level 0-3</LevelWrapper>
        <DiscountAmount>Discount: 2.5% per week</DiscountAmount>
      </DiscountWrapper>
      <DiscountWrapper disabled={level < LIQUIDITY_MINING_LEVEL_THRESHOLD} style={{ marginLeft: '10px' }}>
        <LevelWrapper>Level 4+</LevelWrapper>
        <DiscountAmount>Discount: 3.75% per week</DiscountAmount>
      </DiscountWrapper>
      <EarnMoreLevels>
        Earn XP and level up on the <a href={'https://app.dolomite.io/achievements'}>Achievements Page</a>
      </EarnMoreLevels>
      <OrWrapper>OR</OrWrapper>
      <TotalDepositSizeWrapper
        percent={
          totalDepositedFiatValue.greaterThanOrEqual(REWARDS_DEPOSIT_THRESHOLD)
            ? 100
            : parseFloat(totalDepositedFiatValue.divide(REWARDS_DEPOSIT_THRESHOLD).toFixed(2))
        }
      >
        <DepositSizeLeft>
          <DepositSizeTitle>Hold $100k in assets on Dolomite</DepositSizeTitle>
        </DepositSizeLeft>
        <DepositSizeRight>
          <DepositSizeFraction>
            ${formatAmount(totalDepositedFiatValue, 2, true, '')} <span style={{ fontWeight: 400 }}>/ $100k</span>
          </DepositSizeFraction>
          <DepositSizeDiscountWrapper>
            <DiscountText>Current Discount: </DiscountText>
            <DiscountTextSmall>Discount: </DiscountTextSmall>
            <DepositSizeDiscount>
              {totalDepositedFiatValue.greaterThanOrEqual(REWARDS_DEPOSIT_THRESHOLD)
                ? '3.75% per week'
                : '2.5% per week'}
            </DepositSizeDiscount>
          </DepositSizeDiscountWrapper>
        </DepositSizeRight>
      </TotalDepositSizeWrapper>
      <PeriodSelector>
        <SliderTitleBar>
          <SliderTitle>Discount: {discountPercentInput}%</SliderTitle>
          <PeriodTitle>
            Lockup Period:{' '}
            {qualifiedForAcceleratedVesting && (
              <span
                style={{
                  textDecoration: 'line-through',
                  color: '#606375',
                }}
              >
                {formatAmount(vestDurationWeeks, 0)} {vestDurationWeeks?.equalTo(1) ? 'week' : 'weeks'}
              </span>
            )}{' '}
            {formatAmount(effectiveVestDurationWeeks, 2)} {effectiveVestDurationWeeks?.equalTo(1) ? 'week' : 'weeks'}
            {/*<PeriodLevel>
              Level {level}
              <StyledTooltipWithIcon tooltipText={'Once Level 4 is reached the vesting duration decreases by 33%'} />
            </PeriodLevel>*/}
          </PeriodTitle>
        </SliderTitleBar>
        <PeriodsWrapper>
          <SliderStart>{SLIDER_START}%</SliderStart>
          <SliderWrapper>
            <Slider
              aria-label='Discount'
              min={SLIDER_START}
              max={SLIDER_END}
              step={SLIDER_START}
              value={discountPercentInput}
              onChange={(event: Event, newValue: number | number[]) => setDiscountPercentInput(newValue as number)}
            />
          </SliderWrapper>
          <SliderEnd>{SLIDER_END}%</SliderEnd>
        </PeriodsWrapper>
      </PeriodSelector>
      <VestingDescription>
        {vestingRate.equalTo(1)
          ? `Can receive ${inputValue ? formatAmount(parsedInputValue, 2) : ''} ARB for free in ${formatAmount(
              effectiveVestDurationWeeks,
              2,
            )} weeks. Can execute on ${executionDate.toDateString()}.`
          : `Can purchase ${inputValue ? formatAmount(parsedInputValue, 2) : ''} ARB in 
        ${formatAmount(effectiveVestDurationWeeks, 2)} weeks at a ${formatAmount(
              vestingRate,
            )} discount from market price. Can execute on ${executionDate.toDateString()}. Market price is calculated when executing.`}
      </VestingDescription>
      <VestButton onClick={submitVest} isActive={inputAmountIsValid}>
        Vest
      </VestButton>
    </VestOARB>
  )
}

export default React.memo(VestPanel)
