import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap, initializeSingleValueChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'mETH'
const name = 'Mantle ETH'

function meth(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const METH: ChainIdMap<Token | undefined> = initializeSingleValueChainIdMap(
  ChainId.MANTLE,
  meth(ChainId.MANTLE, '0xcDA86A272531e8640cD7F1a92c01839911B90bb0'),
)
