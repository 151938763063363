import StrategiesData, { StrategyAmounts, StrategyData, StrategyInfo, TagsColor } from './StrategiesList'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useActiveWeb3React } from '../../hooks'
import { Percent } from '@dolomite-exchange/sdk-core'
import { BorrowPosition, useOpenBorrowPositions } from '../../types/borrowPositionData'
import { useShowYieldAsApr } from '../../state/user/hooks'
import { useDefaultMarginAccount } from '../../types/marginAccount'
import useNetInterestRate, { useStrategyInterestRate } from '../../hooks/useNetInterestRate'
import { formatAmount } from '../../utils/formatAmount'
import { useCloseBorrowPosition } from '../../hooks/useBorrowPositionProtocol'
import { useInterestRateData } from '../../types/interestRateData'
import cleanCurrencyName from '../../utils/cleanCurrencyName'
import { ChainId } from '../../constants'
import { getPositionHealth, PositionHealthTooltip } from '../Borrow/BorrowPositionRow'
import ReactGA from 'react-ga'
import ShareBorrowPositionModal from '../Borrow/ShareBorrowPositionModal'
import ManageStrategyModal from './ManageStrategyModal'
import { NETWORK_LABELS, NETWORK_LOGOS } from '../../constants/chainId'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { StyledTooltip } from '../../components/common/StyledTooltip'
import { Rounding } from '@dolomite-exchange/v2-sdk'
import AllInclusiveIcon from '@material-ui/icons/AllInclusive'
import {
  ActiveYield,
  ActiveYieldValue,
  ChainLabel,
  ChainLogo,
  Expiration,
  StrategSectionHeader,
  StrategySubtitle,
  StrategyTitle,
  StrategyWrapperActive,
  SubmitButton,
  SubmitButtonWrapper,
  NoStrategies,
  Tag,
} from './index'
import styled from 'styled-components/macro'
import { Transfer, useTransfersByWalletAddress } from '../../types/transferData'
import { useTradeDataByWallet } from '../../types/tradeData'
import { useHistory, useParams } from 'react-router-dom'

const ActiveStrategiesWrapper = styled.div<{ active?: boolean }>`
  display: ${({ active }) => (active ? 'block' : 'none')};
`

const ShareButton = styled.div`
  position: absolute;
  left: calc(50% - 20px);
  top: 0;
  z-index: 10;
  font-weight: 600;
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 0 0 5px 5px;
  background: ${({ theme }) => theme.bg3};
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  :hover {
    background: ${({ theme }) => theme.bg4};
  }
`

const ActiveTitle = styled.div`
  display: inline-block;
`

const EquityWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
`

const activeSectionTitle = styled.div`
  color: ${({ theme }) => theme.text3};
  font-size: 12px;
`

const CurrentYieldTitle = styled(activeSectionTitle)``

const EquityTitle = styled(activeSectionTitle)``

const HealthFactorTitle = styled(activeSectionTitle)``

const EquityAmount = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.text1};
`

const EquityAsset = styled.div`
  display: inline-block;
`

const NoStrategiesSubtitle = styled.div`
  font-size: 14px;
  font-weight: 100;
  width: 100%;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text2};
`

const AvgYield = styled.div`
  display: table-cell;
  float: right;
  text-align: right;
  font-size: 18px;
  span {
    font-size: 10px;
  }
`

const AvgYieldTitle = styled.div`
  font-size: 11px;
  font-weight: 600;
  color: ${({ theme }) => theme.text3};
  margin-bottom: -4px;
  margin-top: 8px;
`

const Equity = styled.div`
  display: inline-block;
  margin-left: 30px;
  vertical-align: top;

  @media screen and (max-width: 900px) {
    margin-left: 0px;
  }
`

const HealthWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
`

const HealthFactor = styled.div<{ health: number }>`
  font-size: 16px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  color: ${({ theme, health }) => (health === 3 ? theme.green1 : health == 2 ? theme.yellow1 : theme.red1)};
`

const InfinityWrapper = styled.div<{ expanded: boolean }>`
  width: 14px;

  svg {
    transition: all 0.3s ease-in-out;
    width: 0.8em;
    height: 0.8em;
  }
`

interface ActivePosition {
  position: BorrowPosition
  strategy: number
}

function ActiveStrategy({
  strategy,
  createPosition,
  selectedFilters,
  interestRateMap,
  position,
  totalYieldSelected,
  positionTransfers,
}: {
  strategy: StrategyInfo
  createPosition: () => void
  selectedFilters: string[]
  interestRateMap: Record<string, (Percent | undefined)[]>
  position: BorrowPosition
  totalYieldSelected: boolean
  positionTransfers: Transfer[]
}) {
  const { t } = useTranslation()
  const [showYieldAsApr] = useShowYieldAsApr()
  const defaultMarginAccount = useDefaultMarginAccount()
  const leverage = strategy.leverage ?? 1
  const tokens = [...strategy.collateralAssets, ...strategy.debtAssets]
  const [supplyAmounts, borrowAmounts] = StrategyAmounts(strategy)
  const positionInterestRate = useNetInterestRate(position)
  const historicalInterestRate = useStrategyInterestRate(supplyAmounts, borrowAmounts, strategy.chain, true)
  const parsedRate = useMemo(() => {
    return parseFloat(positionInterestRate?.denominator.toString() ?? '0') > 0
      ? formatAmount(positionInterestRate, 2)
      : '0.00%'
  }, [positionInterestRate])
  const parsedHistoricalRate = useMemo(() => {
    return parseFloat(historicalInterestRate?.denominator.toString() ?? '0') > 0
      ? formatAmount(historicalInterestRate, 2)
      : '0.00%'
  }, [historicalInterestRate])
  const [showShareModal, setShowShareModal] = useState(false)
  const onDismissShare = useCallback(() => setShowShareModal(false), [])
  const [showManageModal, setShowManageModal] = useState(false)
  const onDismissManage = useCallback(() => setShowManageModal(false), [])
  const positionCollateral = position.supplyAmounts.map(amount => amount.token) ?? []
  const { callback: submitCloseBorrowPosition, error: submitCloseError } = useCloseBorrowPosition(
    position?.marginAccount.accountNumber,
    defaultMarginAccount.accountNumber,
    positionCollateral,
    position?.specialInfo.isolationModeVaultAddress,
  )
  const { data: interestRateMapData } = useInterestRateData(strategy.chain)
  const expirationDate = useMemo(() => {
    let expiration: string | undefined = undefined
    const expiryMap = [...strategy.collateralAssets, ...strategy.debtAssets].map(asset => {
      const name = cleanCurrencyName(asset) ?? ''
      const date = name.substring(name.length, name?.length - 10)
      if (Array.from(date)[0] !== '2') return
      if (expiration === undefined) expiration = date
      else if (date && date < expiration) expiration = date
    })
    return expiration
  }, [strategy.collateralAssets, strategy.debtAssets])

  const [positionEquity, positionEquityUsd] = useMemo(() => {
    const positionEquity = position.supplyAmounts[0].amountTokenWei.asFraction.subtract(
      position.totalBorrowUSD.divide(
        position.totalSupplyUSD.divide(position.supplyAmounts[0].amountTokenWei.asFraction),
      ),
    )
    const positionEquityUsd = position.totalSupplyUSD.subtract(position.totalBorrowUSD)
    return [positionEquity, positionEquityUsd]
  }, [position.supplyAmounts, position.totalBorrowUSD, position.totalSupplyUSD])

  const mineralBonus = strategy.chain === ChainId.MANTLE ? 2 : 1
  const parts = interestRateMapData[strategy.collateralAssets[0].address]?.outsideSupplyInterestRateParts
  const partsFormatted = useMemo(() => {
    return parts
      ? parts.map((part, index) => {
          return part.metadata
            ? `${(part.metadata?.units ?? 0) *
                leverage *
                (part.metadata?.unitsLabel === 'Minerals' ? mineralBonus : 1)} ${part.metadata?.unitsLabel}`
            : part.label
        })
      : []
  }, [parts, leverage, mineralBonus])

  const positionHealthFactor = useMemo(() => {
    return getPositionHealth(position.positionHealth)
  }, [position.positionHealth])

  const closePosition = useCallback(async () => {
    if (!submitCloseBorrowPosition) return
    submitCloseBorrowPosition()
      .then(txHash => {
        ReactGA.event({
          category: 'Borrow Position',
          action: 'Close Strategy',
        })
      })
      .catch(e => {
        console.error(e)
      })
  }, [submitCloseBorrowPosition])

  const manageStrategyCallback = useCallback(() => {
    setShowManageModal(true)
  }, [])

  return (
    <StrategyWrapperActive>
      <ShareBorrowPositionModal
        isOpen={showShareModal}
        memoizedOnDismiss={onDismissShare}
        position={position}
        strategy={strategy}
      />
      <ManageStrategyModal
        isOpen={showManageModal}
        memoizedOnDismiss={onDismissManage}
        position={position}
        strategy={strategy}
        totalYieldSelected={totalYieldSelected}
        interestRateMap={interestRateMap}
        positionTransfers={positionTransfers}
      />
      <ShareButton onClick={() => setShowShareModal(true)}>SHARE</ShareButton>
      <ActiveTitle>
        <StrategyTitle>
          {strategy.title}
          <Tag large color={TagsColor.leverage2x}>
            {strategy.leverage ?? 1}x
          </Tag>
        </StrategyTitle>
        <StrategySubtitle>
          <ChainLogo>
            <img alt={`${NETWORK_LABELS[strategy.chain]} logo`} src={NETWORK_LOGOS[strategy.chain]} />
          </ChainLogo>
          <ChainLabel>{NETWORK_LABELS[strategy.chain]}</ChainLabel>
          {expirationDate && <Expiration>Expires: {expirationDate}</Expiration>}
        </StrategySubtitle>
      </ActiveTitle>
      {/*{parseFloat(interestRate?.denominator.toString() ?? '0') > 0 ? interestRate?.toFixed(2) : '0.00'}%*/}
      <ActiveYield isNegative={parsedRate.charAt(0) === '-'}>
        <CurrentYieldTitle>Current Yield</CurrentYieldTitle>
        <ActiveYieldValue>
          {parsedRate}
          <span>{showYieldAsApr ? t('apr') : t('apy')}</span>
        </ActiveYieldValue>
        {/*<AvgYield>*/}
        {/*  <AvgYieldTitle>30 Day Avg.</AvgYieldTitle>*/}
        {/*  {parsedHistoricalRate}*/}
        {/*</AvgYield>*/}
      </ActiveYield>
      <EquityWrapper>
        <EquityTitle>Equity</EquityTitle>
        <EquityAmount>
          <EquityAsset>
            {formatAmount(positionEquity)} {cleanCurrencySymbol(supplyAmounts[0].currency)}
          </EquityAsset>
        </EquityAmount>
      </EquityWrapper>
      <HealthWrapper>
        <HealthFactorTitle>Health</HealthFactorTitle>
        <HealthFactor health={positionHealthFactor}>
          <StyledTooltip
            title={
              <PositionHealthTooltip
                positionHealth={
                  position.positionHealth
                    ? parseFloat(position.positionHealth.toFixed(2, undefined, Rounding.ROUND_DOWN))
                    : undefined
                }
                positionHealthFactor={positionHealthFactor}
                t={t}
                isNew={false}
              />
            }
            placement='top'
            arrow={true}
          >
            <span>
              {!position.positionHealth ? (
                <InfinityWrapper expanded={true}>
                  <AllInclusiveIcon />
                </InfinityWrapper>
              ) : position.positionHealth.greaterThan('10') ? (
                '>10'
              ) : (
                position.positionHealth.toFixed(2, undefined, Rounding.ROUND_DOWN)
              )}
            </span>
          </StyledTooltip>
        </HealthFactor>
      </HealthWrapper>
      {/*<TagsWrapper>
        <TagSpacer>
          {strategy.tags.map((tag: string, i: number) => {
            const tagText = Tags[tag as keyof typeof Tags]
            const matchingTag = partsFormatted.find(part => part.includes(tagText))
            if (tag.startsWith('leverage')) return
            return (
              <Tag
                key={`${strategy.title}-tag-${i}`}
                color={TagsColor[tag as keyof typeof Tags]}
                selected={selectedFilters.includes(tag)}
              >
                <StyledTooltip title={TagsTooltip[tag as keyof typeof Tags]} placement={'top'}>
                  <div>{matchingTag ?? tagText}</div>
                </StyledTooltip>
              </Tag>
            )
          })}
        </TagSpacer>
      </TagsWrapper>*/}
      <SubmitButtonWrapper onClick={() => manageStrategyCallback()} active>
        <SubmitButton card>Manage</SubmitButton>
      </SubmitButtonWrapper>
    </StrategyWrapperActive>
  )
}

const isActiveStrategy = (position: BorrowPosition) => {
  const positionId = parseFloat(position?.id.split('-')[1])
  return (
    positionId >= 100000 && positionId <= 1000000 && position.status === 'OPEN' && position.supplyAmounts.length > 0
  )
}

export default function ActiveStrategies({
  interestRateMap,
  totalYieldSelected,
}: {
  interestRateMap: Record<string, (Percent | undefined)[]>
  totalYieldSelected: boolean
}) {
  const { t } = useTranslation()
  const { chainId, account } = useActiveWeb3React()
  const { data: positions } = useOpenBorrowPositions(account)
  const { id, positionId } = useParams<{ id: string; positionId: string }>()
  const history = useHistory()
  const strats = StrategyData()
  const { data: allTransfers } = useTransfersByWalletAddress(account)
  const { data: allTrades } = useTradeDataByWallet(account)
  const filteredPositions = useMemo(() => {
    return positions?.filter(position => {
      return isActiveStrategy(position)
    })
  }, [positions])
  const positionIds = useMemo(() => new Set(filteredPositions.map(position => position.id)), [filteredPositions])
  const filteredTransfers = useMemo(() => {
    return allTransfers?.filter(transfer => {
      return positionIds.has(transfer.toMarginAccount.toString())
    })
  }, [allTransfers, positionIds])
  const filteredTrades = useMemo(() => {
    return allTrades?.filter(trade => {
      return trade.makerAccount && positionIds.has(trade.makerAccount.toString())
    })
  }, [allTrades, positionIds])
  const urlPosition = useMemo(() => {
    if (positionId) {
      return filteredPositions.find(position => position.id === positionId)
    }
    return undefined
  }, [positionId, filteredPositions])
  const activePositions = useMemo(() => {
    return filteredPositions?.reduce((filteredPositions: ActivePosition[], position: BorrowPosition) => {
      const positionTransfers = filteredTransfers?.filter(
        transfer => transfer.toMarginAccount.toString() === position.id,
      )
      if (positionTransfers.length < 2 || positionTransfers[0].token.symbol !== positionTransfers[1].token.symbol) {
        return filteredPositions
      }
      const initialDeposit = positionTransfers[0]
      const loop = positionTransfers[1]
      // Divides the initial deposit + looped amount by initial deposit to get a leverage ratio, then rounds to the nearest whole number to determine which strategy it matches
      const calculatedLeverage = Math.round(
        parseFloat(
          initialDeposit.amount.asFraction
            .add(loop.amount.asFraction)
            .divide(initialDeposit.amount.asFraction)
            .toFixed(2),
        ),
      )
      const matchedStrategy = strats.find(
        strategy =>
          strategy.leverage === calculatedLeverage &&
          strategy.chain === chainId &&
          strategy.collateralAssets[0].address === initialDeposit.token.address &&
          strategy.debtAssets[0].address === position.borrowAmounts[0].token.address,
      )
      if (matchedStrategy) {
        filteredPositions.push({ position: position, strategy: parseInt(matchedStrategy.id) - 1 })
      }
      return filteredPositions
    }, [])
  }, [chainId, filteredPositions, filteredTransfers, strats])

  const closeModal = useCallback(() => {
    history.push('/strategies')
  }, [history])

  return (
    <ActiveStrategiesWrapper active={true /*activePositions.length > 0*/}>
      <StrategSectionHeader>Active Strategies</StrategSectionHeader>
      {activePositions.length > 0 ? (
        activePositions.map((position: ActivePosition, index: number) => {
          const positionTransfers = filteredTransfers?.filter(
            transfer => transfer.toMarginAccount.toString() === position.position.id,
          )
          return (
            <ActiveStrategy
              createPosition={() => console.log('Generate position')}
              key={`strategy-${position.strategy}`}
              strategy={StrategiesData[position.strategy]}
              selectedFilters={[]}
              interestRateMap={interestRateMap}
              position={position.position}
              totalYieldSelected={totalYieldSelected}
              positionTransfers={positionTransfers}
            />
          )
        })
      ) : (
        <NoStrategies>
          No Active Strategies
          <NoStrategiesSubtitle>
            Expecting to a see a strategy? Make sure you switch to the chain you opened the strategy on.
          </NoStrategiesSubtitle>
        </NoStrategies>
      )}
      {urlPosition ? (
        <ManageStrategyModal
          isOpen={!!positionId}
          memoizedOnDismiss={closeModal}
          position={urlPosition}
          strategy={StrategiesData[parseFloat(id)]}
          totalYieldSelected={totalYieldSelected}
          interestRateMap={interestRateMap}
          positionTransfers={filteredTransfers?.filter(
            transfer => transfer.toMarginAccount.toString() === urlPosition.id,
          )}
        />
      ) : null}
    </ActiveStrategiesWrapper>
  )
}
