import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Percent, Token } from '@dolomite-exchange/sdk-core'
import StrategiesData, {
  Risk,
  TagsColor,
  Tags,
  TagsTooltip,
  StrategyInfo,
  StrategyAmounts,
  useStrategyInterestRateMap,
} from './StrategiesList'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import PageTitle from '../../components/common/PageTitle'
import TOSPopover from '../../components/TOSPopover'
import useApprovedToS from '../../hooks/useApprovedToS'
import CreatePositionModal from './CreatePositionModal'
import getLogoOrDefault from '../../components/common/TokenLogos'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { ChainId, ZERO_PERCENT } from '../../constants'
import Input from '@material-ui/core/Input'
import { X } from 'react-feather'
import { lighten } from 'polished'
import { NETWORK_LABELS, NETWORK_LOGOS } from '../../constants/chainId'
import { useInterestRateData } from '../../types/interestRateData'
import { useStrategyInterestRate } from '../../hooks/useNetInterestRate'
import { useFiatPricesWithLoadingIndicator } from '../../hooks/useFiatValue'
import { useActiveWeb3React } from '../../hooks'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt'
import SignalCellularAlt1BarIcon from '@mui/icons-material/SignalCellularAlt1Bar'
import SignalCellularAlt2BarIcon from '@mui/icons-material/SignalCellularAlt2Bar'
import { StyledTooltip } from '../../components/common/StyledTooltip'
import { formatAmount } from '../../utils/formatAmount'
import { useHistory, useParams } from 'react-router-dom'
import { useShowYieldAsApr } from '../../state/user/hooks'
import DepositedBalances from '../Pool/DepositedBalances'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useDefaultMarginAccount } from '../../types/marginAccount'
import LinkIcon from '@mui/icons-material/Link'
import cleanCurrencyName from '../../utils/cleanCurrencyName'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import StarIcon from '@mui/icons-material/Star'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import ActiveStrategies from './ActiveStrategies'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 2800px;
  margin: 0 auto;
  text-align: center;
  > div {
    text-align: left;
  }

  @media screen and (max-width: 2200px) {
    width: calc(100% - 80px);
    max-width: 2400px;
  }
  @media screen and (max-width: 900px) {
    max-width: 600px;
  }
  @media screen and (max-width: 550px) {
    width: 90vw;
  }
  @media screen and (max-width: 420px) {
    width: calc(100% - 40px);
  }
  @media screen and (max-width: 390px) {
    width: calc(100% - 20px);
  }
`

const StrategiesWrapper = styled.div`
  width: 1155px;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 60px;

  @media screen and (max-width: 1750px) {
    width: 770px;
  }
  @media screen and (max-width: 900px) {
    max-width: 500px;
  }
  @media screen and (max-width: 550px) {
    width: 100%;
  }
`

export const StrategSectionHeader = styled.div`
  width: 100%;
  font-size: 21px;
  font-weight: 400;
  margin: 5px 10px 5px;
`

const Header = styled.div`
  width: calc(100% - 80px);
  max-width: 1135px;
  font-size: 32px;
  font-weight: 600;
  margin: 25px auto -5px;
  @media screen and (max-width: 1750px) {
    max-width: 750px;
  }
  @media screen and (max-width: 1480px) {
    //margin-left: 25px auto -5px;
  }
  @media screen and (max-width: 900px) {
    max-width: 500px;
  }
  @media screen and (max-width: 550px) {
    width: 100%;
  }
  @media screen and (max-width: 500px) {
    font-size: 28px;
  }
`

const DisclaimerWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  padding: 20px 30px;
  color: ${({ theme }) => theme.text1};
  margin-bottom: 10px;
  position: relative;
`

const CloseButton = styled.div`
  position: absolute;
  right: 20px;
  top: 5px;
  cursor: pointer;
`

const DisclaimerTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
  width: 100%;
`

const DisclaimerContent = styled.div<{ big?: boolean }>`
  width: 100%;
  font-size: ${({ big }) => (big ? 17 : 14)}px;
  font-weight: ${({ big }) => (big ? 400 : 400)};
  margin-bottom: 10px;

  a {
    color: ${({ theme }) => theme.text1};
    text-decoration: none;
    font-weight: 800;
  }
`

const Title = styled.div`
  width: 100%;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 0;

  @media screen and (max-width: 500px) {
    font-size: 28px;
  }
`

const Descriptor = styled.div`
  width: 100%;
  font-size: 15px;
  font-weight: 300;
  color: #d5d6e1;
  margin-bottom: 15px;
  line-height: 22px;

  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
`

const BalancesWrapper = styled.div`
  margin-right: 10px;
  width: 307px;
  vertical-align: top;
  margin-top: 48px;
  display: inline-block;
  position: sticky;
  top: 30px;
  margin-left: -13px;

  @media screen and (max-width: 1480px) {
    display: none;
  }
`

const FiltersWrapper = styled.div`
  margin-top: 48px;
  margin-left: 10px;
  margin-right: 13px;
  width: 280px;
  vertical-align: top;
  display: inline-block;
  background-color: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  font-family: 'Open Sans', sans-serif;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  text-align: left;
  padding: 25px 30px 25px;
  position: sticky;
  top: 30px;
  @media screen and (max-width: 1400px) {
    display: none;
  }
`

const FiltersWrapperMobile = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  vertical-align: top;
  display: none;
  background-color: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  font-family: 'Open Sans', sans-serif;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  text-align: left;
  padding: 25px 30px 25px;

  @media screen and (max-width: 1400px) {
    display: inline-block;
  }
  @media screen and (max-width: 900px) {
    width: calc(100% - 10px);
  }
`

const TagsMobile = styled.div`
  font-size: 0px;
`

const StrategyWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  display: inline-block;
  vertical-align: top;
  width: 365px;
  height: 325px;
  margin: 10px;
  font-family: 'Open Sans', sans-serif;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  text-align: left;
  padding: 25px 30px 0;
  position: relative;

  @media screen and (max-width: 900px) {
    width: calc(100% - 20px);
  }
`

export const StrategyWrapperActive = styled(StrategyWrapper)`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 20px);
  height: auto;
  padding: 25px 30px;
  margin: 15px 10px;
`

export const StrategyTitle = styled.div`
  font-size: 22px;
  font-weight: 100;
`

export const StrategySubtitle = styled.div`
  margin-top: -6px;
  height: 22px;
  overflow: hidden;
`

const SpecialTag = styled.div`
  position: absolute;
  top: -12px;
  left: -8px;
`

const TagIcon = styled.div`
  display: inline-block;
  position: relative;
  bottom: -2px;
  right: -2px;
  svg {
    font-size: 12px;
  }
`

export const ChainLabel = styled.div`
  display: inline-block;
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
  font-weight: 400;
  margin-top: 3px;
  margin-left: 4px;
  vertical-align: top;
`

export const Expiration = styled.div<{ closeToExpiry?: boolean }>`
  font-weight: 400;
  color: ${({ theme, closeToExpiry }) => (closeToExpiry ? theme.yellow2 : theme.text2)};
  display: inline-block;
  font-size: 12px;
  margin: 3px 4px 1px 8px;
  vertical-align: top;

  svg {
    margin-left: 3px;
    font-size: 15px;
    color: ${({ theme }) => theme.yellow2};
    overflow: hidden;
    vertical-align: top;
  }
`

export const ChainLogo = styled.div`
  display: inline-block;
  vertical-align: top;
  img {
    width: 12px;
  }
`

export const AssetTitle = styled.div<{ right?: boolean; small?: boolean }>`
  font-weight: 600;
  font-size: ${({ small }) => (small ? 10 : 12)}px;
  margin-bottom: 3px;
  text-align: ${({ right }) => (right ? 'right' : 'left')};
  color: ${({ theme }) => theme.text3};
  display: inline-block;
`

const AssetRate = styled.div<{ right?: boolean; small?: boolean }>`
  font-weight: 100;
  font-size: ${({ small }) => (small ? '13' : '15')}px;
  display: inline-block;
  float: ${({ right }) => (right ? 'left' : 'right')};
  margin-top: ${({ small }) => (small ? '3' : '2')}px;

  @media screen and (max-width: 1780px) and (min-width: 1500px) {
    display: none;
  }
`
const AssetDescription = styled.div`
  width: 100%;
  height: 20px;
  margin-top: -6px;
  position: relative;
`

const TokenName = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.text2};
  font-weight: 200;
  position: absolute;
  top: 0;
  left: 23px;

  @media screen and (max-width: 615px) {
    font-size: 10px;
    margin-top: -4px;
  }
`

const AmountInUSD = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
  font-weight: 200;
  vertical-align: bottom;
  position: absolute;
  top: 0;
  right: 0;

  @media screen and (max-width: 615px) {
    font-size: 11px;
  }
`

export const NoStrategies = styled.div`
  font-size: 22px;
  font-weight: 100;
  width: 100%;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 40px;
`

export const Yield = styled.div<{ isNegative?: boolean }>`
  font-size: 40px;
  color: ${({ theme, isNegative }) => (isNegative ? theme.yellow2 : theme.green1)};
  margin-top: -3px;
  width: 100%;
  display: table;
  vertical-align: bottom;

  span {
    font-size: 14px;
    color: ${({ theme }) => theme.text1};
    margin-left: 5px;
  }
`

export const ActiveYield = styled(Yield)`
  width: auto;
  display: inline-block;
  vertical-align: top;
  margin-left: 14px;
  margin-top: 0;
  font-size: 20px;
`

export const YieldValue = styled.div`
  display: table-cell;
  vertical-align: bottom;
`

export const ActiveYieldValue = styled(YieldValue)`
  font-size: 16px;
`

export const AvgYield = styled.div`
  display: table-cell;
  float: right;
  text-align: right;
  font-size: 18px;
  span {
    font-size: 10px;
  }
`

export const AvgYieldTitle = styled.div`
  font-size: 11px;
  font-weight: 600;
  color: ${({ theme }) => theme.text3};
  margin-bottom: -4px;
  margin-top: 8px;
`

export const RiskWrapper = styled.div`
  display: inline-block;
  text-align: right;
  float: right;
  position: relative;
`

export const RiskBackground = styled.div<{ large?: boolean }>`
  color: ${({ theme }) => theme.text3};
  position: absolute;
  top: 0;
  right: 0;
  svg {
    height: ${({ large }) => (large ? 32 : 27)}px;
    width: ${({ large }) => (large ? 32 : 27)}px;
  }
`

export const RiskIcon = styled.div<{ risk: Risk; large?: boolean }>`
  color: ${({ theme, risk }) => (risk === Risk.low ? theme.green1 : risk === Risk.medium ? theme.yellow1 : theme.red1)};
  position: absolute;
  top: 0;
  right: 0;
  svg {
    height: ${({ large }) => (large ? 32 : 27)}px;
    width: ${({ large }) => (large ? 32 : 27)}px;
  }
`

export const RiskTitle = styled.div<{ large?: boolean }>`
  color: ${({ theme }) => theme.text3};
  margin-right: 4px;
  font-weight: 600;
  margin-top: 23px;
  font-size: ${({ large }) => (large ? 11 : 9)}px;
`

export const YieldParts = styled.div`
  font-size: 11px !important;
  margin-top: -4px;
`

export const YieldPart = styled.div`
  color: ${({ theme }) => theme.text2} !important;
  font-weight: 600 !important;
  margin-right: 2px !important;
  display: inline-block;
`

export const TagsWrapper = styled.div`
  font-size: 11px;
  width: 100%;
  margin-top: 5px;
`

const FilterTitle = styled.div`
  width: 100%;
  font-size: 20px;
  font-weight: 700;
`

const FiltersDescription = styled.div<{ mobile?: boolean }>`
  width: 100%;
  font-size: 15px;
  font-weight: 300;
  color: #d5d6e1;
  margin-bottom: 5px;
  margin-top: 10px;

  @media screen and (max-width: 500px) {
    display: none;
  }

  ${({ mobile }) =>
    mobile &&
    `
    margin-bottom: 10px;
  `}
`

const RewardTagTitle = styled.div`
  font-size: 20px;
  font-weight: 100;
  margin: 10px 0;
`

const TagSpacer = styled.div``

export const Tag = styled.div<{ color: string; selected?: boolean; filter?: boolean; large?: boolean }>`
  display: inline-block;
  margin-right: ${({ filter }) => (filter ? 6 : 4)}px;
  margin-bottom: ${({ filter }) => (filter ? 6 : 4)}px;
  background-color: ${({ theme, color }) => (color ? color : theme.bg6)};
  color: ${({ theme }) => theme.text1};
  ${({ selected }) => selected && `filter: brightness(110%);`}
  padding: 2px 7px 4px;
  border-radius: 4px;
  font-weight: 600;
  user-select: none;
  font-size: ${({ filter }) => (filter ? 11 : 10)}px;

  ${({ large }) =>
    large &&
    `vertical-align: top;
    margin-left: 7px;
    font-size: 12px;
    margin-top: 4px;
    margin-right: 0;
  `}

  ${({ selected, color }) =>
    selected &&
    `box-shadow: 0 0 11px 0 ${color};
    outline: 2px solid ${lighten(0.1, color)};
    `}
  ${({ filter }) => filter && `cursor: pointer`};
`

export const AssetList = styled.div<{ right?: boolean; rightModal?: boolean }>`
  display: inline-block;
  width: calc(50% - 10px);
  vertical-align: top;
  margin-top: 10px;
  margin-left: ${({ right, rightModal }) => (rightModal || right ? 20 : 0)}px;
  height: 70px;
  text-align: ${({ right, rightModal }) => (rightModal || right ? 'right' : 'left')} !important;
`

export const Asset = styled.div<{ small?: boolean }>`
  font-size: ${({ small }) => (small ? 13 : 16)}px;
`

export const SubmitButtonWrapper = styled.div<{ card?: boolean; active?: boolean }>`
  width: 100%;
  margin: 20px auto;
  display: inline-block;
  vertical-align: bottom;
  pointer-events: all;
  ${({ card }) =>
    card &&
    `
    position: absolute;
    bottom: 0px;
    left: 20px;
    width: calc(100% - 40px);
  `}

  ${({ active }) =>
    active &&
    `
    width: fit-content;
    margin: 5px 0 0;
    
    > div {
      width: fit-content;
      padding: 0 16px;
      margin: 0;
    }
    /*float: right;
    position: absolute;
    right: 25px;
    top: 5px;*/
  `}
`

export const SubmitButton = styled.div<{ card?: boolean; wrongChain?: boolean; disabled?: boolean }>`
  width: 100%;
  height: 36px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme, card, wrongChain }) => (card ? theme.bg4 : wrongChain ? theme.yellow2 : theme.green2)};
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  pointer-events: all;
  :hover {
    background-color: ${({ theme, card, wrongChain }) =>
      card ? theme.bg5 : wrongChain ? theme.yellow1 : theme.green1};
  }
  ${({ disabled }) =>
    disabled ? `pointer-events: none; opacity: 0.6; cursor: none !important;` : `pointer-events: auto !important;`}

  > div {
    height: 19px !important;
    width: 19px !important;
    margin-top: 8px;
  }

  svg {
    color: white !important;
  }
`

export const TokenLogo = styled.div<{ small?: boolean }>`
  display: inline-block;
  margin-right: 3px;
  margin-top: 2px;
  vertical-align: top;
  width: ${({ small }) => (small ? 15 : 18)}px;
  img {
    width: 100%;
  }

  @media screen and (max-width: 615px) {
    margin-top: 0;
  }
`

const MobileSelectors = styled.div`
  width: 220px;
  height: 28px;
  display: inline-block;
  margin-top: 12px;
  vertical-align: top;
  @media screen and (max-width: 500px) {
    display: none;
  }
`

const MobileSelectorsSmall = styled.div`
  display: none;
  @media screen and (max-width: 500px) {
    width: 100%;
    margin-top: 4px;
    display: inline-block;
  }
`

const SelectorsWrapper = styled.div`
  width: 100%;
`

const Selectors = styled.div<{ bottom?: boolean }>`
  width: 100%;
  margin-top: ${({ bottom }) => (bottom ? 10 : 0)}px;
  height: 30px;
`

const SortWrapper = styled.div`
  float: right;
  display: inline-block;
  margin-right: 20px;
`

const StyledNetworkButton = styled.button`
  height: 28px;
  border: none;
  width: 122px;
  background-color: ${({ theme }) => theme.bg7};
  color: ${({ theme }) => theme.text1};
  padding: 0;
  border-radius: 5px;
  text-align: left;
  padding-left: 5px;
  overflow: hidden;
  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: #3a3a4f;
  }

  svg {
    margin-top: 0px;
  }
  img {
    margin-top: 0px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    box-shadow: none;
  `};
`

const FlyoutWrapper = styled.div<{ isOpen: boolean }>`
  > span {
    top: 35px;
    transition: opacity 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  }

  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};

  ${({ theme, isOpen }) => theme.mediaWidth.upToSmall`
    overflow: hidden;
    position: absolute;
    width: 120px;
    height: 185px;
    right: 0px;
    bottom: 56px;
  
    > span {
      opacity: 1;
      transition: top 0.3s ease-in-out;
      top: ${isOpen ? '0' : '220px'};
    }
  `};
`

const NetworkSelectorWrapper = styled.div`
  position: relative;
  display: inline-block;
  border-radius: 4px;
  height: 30px;
  right: 0px;
  vertical-align: top;
  text-align: center;
  color: ${({ theme }) => theme.text1};
`

const MenuFlyout = styled.span`
  min-width: 140px;
  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 4px;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: -55px;
  left: 0;
  z-index: 100;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: -160px;
  `};
`

export const FilterSelect = styled.div<{ middle?: boolean; right?: boolean }>`
  display: inline-block;
  height: 22px;
  vertical-align: top;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.bg6};
  font-size: 13px;
  cursor: pointer;
  font-weight: 500;
  float: ${({ right }) => (right ? 'right' : 'left')};
  margin: ${({ middle }) => (middle ? '0 8px' : 0)};

  :hover {
    background-color: ${({ theme }) => theme.bg3};
  }
`

export const FilterSelector = styled.div<{ active: boolean }>`
  width: 34px;
  height: 22px;
  display: inline-block;
  padding: 2px 1px;
  border-radius: 3px;
  text-align: center;
  background-color: ${({ active, theme }) => active && theme.bg4};
`

export const StyledNoneIcon = styled(DoNotDisturbIcon)`
  width: 18px !important;
  height: 18px !important;
`

export const StyledAutoAwesomeIcon = styled(AutoAwesomeIcon)`
  width: 18px !important;
  height: 18px !important;
`

const InputWrapper = styled.div<{ mobile?: boolean }>`
  margin-top: 5px;
  display: inline-block;
  width: 100%;
  position: relative;
  ${({ mobile }) =>
    mobile &&
    `
    width: calc(100% - 230px);
    vertical-align: top;
    margin-top: -5px;
    margin-right: 10px;
    @media screen and (max-width: 500px) {
      width: 100%;
      margin-bottom: 10px;
    }
  `};

  input {
    height: 20px !important;
    color: #f9f9f9 !important;
    display: inline-flex !important;
    position: relative !important;
    font-size: 1rem !important;
    background: ${({ theme }) => theme.bg6} !important;
    font-family: Open Sans, serif !important;
    line-height: 1.1875em !important;
    font-weight: 300 !important;
    border-radius: 8px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  > div {
    padding-top: 0;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  > div > div {
    padding-top: 0 !important;
  }
`

const InputOverflowFix = styled.div`
  overflow: hidden;
`

const StyledInput = styled(({ ...props }) => <Input {...props} />)<{ multiline: boolean }>`
  overflow: hidden;
  margin-bottom: 0 !important;
  height: 33px !important;

  input {
    margin-bottom: 0 !important;
  }

  ${({ multiline }) =>
    multiline &&
    `
    margin-top: 2px;
    width: 100% !important;

    textarea {
      overflow: hidden !important;
      padding: 0 8px !important;
      width: calc(100% - 8px) !important;
    }
  `};
  @media (max-width: 1400px) {
    input {
      font-size: 0.9rem;
    }

    p {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 375px) {
    input {
      font-size: 12px !important;
    }
  }
`

const ClearFilters = styled(X)`
  color: ${({ theme }) => theme.text3};
  font-weight: 500;
  display: inline-block;
  cursor: pointer;
  height: 24px;
  margin-bottom: -5px;
  margin-left: 5px;
`

const Clear = styled.div<{ disabled: boolean }>`
  color: ${({ theme }) => theme.text3};
  font-weight: 500;
  position: absolute;
  right: 3px;
  top: 7px;
  display: inline-block;
  ${({ disabled }) => disabled && `display: none`};
`

const Close = styled(X)`
  cursor: pointer;
  height: 18px;
`

const OutsideRate = styled.div`
  color: ${({ theme }) => theme.blue2};
  cursor: pointer;
  > span {
    color: ${({ theme }) => theme.text2};
    font-weight: 200;
  }
`

const RateLabel = styled.span`
  font-size: 8px;
  color: ${({ theme }) => theme.text2};
  font-weight: 200;
  margin-left: 3px;
`

/*export const AvgYield = styled.div`
  display: table-cell;
  float: right;
  text-align: right;
  font-size: 18px;
  span {
    font-size: 10px;
  }
`

export const AvgYieldTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.text3};
  margin-bottom: -3px;
  margin-top: 3px;
`*/

const FilterDropdown = styled.div<{ expanded: boolean }>`
  background-color: #3a3a4f;
  border-radius: 4px;
  cursor: pointer;
  height: 33px;
  overflow: hidden;
  width: 90px;
  left: calc(100% - 90px);
  bottom: 0;
  position: absolute;
  z-index: 1;

  ${({ expanded }) =>
    expanded &&
    `
    border-top-left-radius: 4px;
    height: fit-content;
  `}
`

const ArrowDown = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #606375;
  top: 12px;
  right: 7px;
`

const LinkIconWrapper = styled.div`
  position: relative;
  left: -4px;
  bottom: -6px;
  display: inline-block;
`

const LinkButtonWrapper = styled.div`
  position: relative;
  bottom: -1.5px;
  overflow: hidden;
`

const StyledChainIcon = styled.img`
  height: 15px;
  display: inline-block;
  vertical-align: top;
`

const StyledLinkIcon = styled(LinkIcon)`
  font-size: 15px;
  color: white;
  display: inline-block;
`

const ChainName = styled.div<{ all?: boolean }>`
  vertical-align: top;
  font-size: 14px;
  margin-left: 4px;
  margin-top: ${({ all }) => (all ? '2.5px' : '-2px')};
  display: inline-block;
`

const NetworkRow = styled.div<{ noChain?: boolean }>`
  width: 100%;
  text-align: left;
  cursor: pointer;
  padding: ${({ noChain }) => (noChain ? '2px 15px 8px' : '8px 15px')};
  font-size: 15px;

  :hover {
    background-color: ${({ theme }) => lighten(0.1, theme.bg2)};
  }

  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    margin-bottom: -2px;
  }
`

const Sorting = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  right: 122px;
`

const SortSelect = styled.div<{ expanded: boolean }>`
  background-color: ${({ theme }) => theme.bg7};
  border-radius: 4px;
  cursor: pointer;
  height: 28px;
  overflow: hidden;
  width: 70px;
  right: 10px;
  display: inline-block;
  top: 0px;
  position: absolute;
  z-index: 1;
  color: ${({ theme }) => theme.text1};

  ${({ expanded }) =>
    expanded &&
    `
    border-top-left-radius: 4px;
    height: fit-content;
  `}
`

const SortSelectRow = styled.div`
  font-size: 14px;
  font-weight: 500;
  padding: 4px 8px;
  height: 27px;

  &:hover {
    background-color: #474956;
  }
`

/*const HealthFactorTitle = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.text3};
  font-size: 12px;
  font-weight: 600;
  margin-right: 2px;
`*/

export const THREE_DAYS_MILLIS = 1_000 * 86_400 * 3

function Strategy({
  strategy,
  createPosition,
  selectedFilters,
  interestRatemap,
  total,
  highestYield,
}: {
  strategy: StrategyInfo
  createPosition: () => void
  selectedFilters: string[]
  interestRatemap: Record<string, (Percent | undefined)[]>
  total: boolean
  highestYield: boolean
}) {
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const [showYieldAsApr] = useShowYieldAsApr()
  const defaultMarginAccount = useDefaultMarginAccount()
  const leverage = strategy.leverage ?? 1
  const tokens = [...strategy.collateralAssets, ...strategy.debtAssets]
  const [fiatPrices, loading] = useFiatPricesWithLoadingIndicator(tokens, strategy.chain)
  const [supplyAmounts, borrowAmounts] = StrategyAmounts(strategy)
  const interestRate = interestRatemap[strategy.id][total ? 0 : 1]
  const historicalInterestRate = useStrategyInterestRate(supplyAmounts, borrowAmounts, strategy.chain, true, true)
  const parsedRate = useMemo(() => {
    return parseFloat(interestRate?.denominator.toString() ?? '0') > 0 ? formatAmount(interestRate, 2) : '0.00%'
  }, [interestRate])
  const historicalPeriod = 7 // TODO - determine the max time period (7, 14, 30) that can be shown based on available data
  const parsedHistoricalRate = useMemo(() => {
    return parseFloat(historicalInterestRate?.denominator.toString() ?? '0') > 0
      ? formatAmount(historicalInterestRate, 2)
      : '0.00%'
  }, [historicalInterestRate])

  const { data: interestRateMap } = useInterestRateData(strategy.chain)
  const mineralBonus = strategy.chain === ChainId.MANTLE ? 2 : 1
  const parts = interestRateMap[strategy.collateralAssets[0].address]?.outsideSupplyInterestRateParts
  const partsFormatted = useMemo(() => {
    return parts
      ? parts.map((part, index) => {
          return part.metadata
            ? `${(part.metadata?.units ?? 0) *
                leverage *
                (part.metadata?.unitsLabel === 'Minerals' ? mineralBonus : 1)} ${part.metadata?.unitsLabel}`
            : part.label
        })
      : []
  }, [parts, leverage, mineralBonus])

  const supplyRate = interestRateMap[strategy.collateralAssets[0].address]
  const supplyInterestRate = supplyRate?.borrowInterestRate ?? ZERO_PERCENT
  const totalSupplyRate = supplyRate?.totalSupplyInterestRate ?? ZERO_PERCENT
  const supplyInterestRateParts = supplyRate?.outsideSupplyInterestRateParts

  const borrowRate = interestRateMap[strategy.debtAssets[0].address]
  const borrowInterestRate = borrowRate?.borrowInterestRate ?? ZERO_PERCENT
  const totalBorrowRate = borrowRate?.totalBorrowInterestRate ?? ZERO_PERCENT
  const borrowInterestRateParts = borrowRate?.outsideBorrowInterestRateParts

  const expirationDate = useMemo(() => {
    let expiration: string | undefined = undefined
    const expiryMap = [...strategy.collateralAssets, ...strategy.debtAssets].map(asset => {
      const name = cleanCurrencyName(asset) ?? ''
      const date = name.substring(name.length, name?.length - 10)
      if (Array.from(date)[0] !== '2') return
      if (expiration === undefined) expiration = date
      else if (date && date < expiration) expiration = date
    })
    return expiration
  }, [strategy.collateralAssets, strategy.debtAssets])

  const submit = useCallback(() => {
    createPosition()
  }, [createPosition])

  const expirationDateParsed = expirationDate ? new Date(expirationDate) : undefined
  const showExpirationWarning = expirationDateParsed
    ? expirationDateParsed.getTime() - Date.now() < THREE_DAYS_MILLIS
    : false

  return (
    <StrategyWrapper>
      {(strategy.specialTag || highestYield) && (
        <SpecialTag>
          <Tag color={TagsColor.minerals}>
            {highestYield ? 'Highest Yield' : strategy.specialTag}
            <TagIcon>{highestYield ? <AutoAwesomeIcon /> : <StarIcon />}</TagIcon>
          </Tag>
        </SpecialTag>
      )}
      <StrategyTitle>
        {strategy.title}
        <Tag large color={TagsColor.leverage2x}>
          {strategy.leverage ?? 1}x
        </Tag>
        <StyledTooltip title={strategy.riskAssessment} placement={'top'}>
          <RiskWrapper>
            <RiskBackground>
              <SignalCellularAltIcon />
            </RiskBackground>
            <RiskIcon risk={strategy.risk}>
              {strategy.risk === Risk.low ? (
                <SignalCellularAlt1BarIcon />
              ) : strategy.risk === Risk.medium ? (
                <SignalCellularAlt2BarIcon />
              ) : (
                <SignalCellularAltIcon />
              )}
            </RiskIcon>
            <RiskTitle>Risk</RiskTitle>
          </RiskWrapper>
        </StyledTooltip>
      </StrategyTitle>
      <StrategySubtitle>
        <ChainLogo>
          <img alt={`${NETWORK_LABELS[strategy.chain]} logo`} src={NETWORK_LOGOS[strategy.chain]} />
        </ChainLogo>
        <ChainLabel>{NETWORK_LABELS[strategy.chain]}</ChainLabel>
        {expirationDate && (
          <Expiration closeToExpiry={showExpirationWarning}>
            Expires: {expirationDate}
            {showExpirationWarning && <WarningRoundedIcon />}
          </Expiration>
        )}
      </StrategySubtitle>
      {/*{parseFloat(interestRate?.denominator.toString() ?? '0') > 0 ? interestRate?.toFixed(2) : '0.00'}%*/}
      <Yield isNegative={parsedRate.charAt(0) === '-'}>
        <YieldValue>
          {parsedRate.charAt(0) === '-' ? (
            <StyledTooltip
              title={`This strategy has a negative yield. This may be because the strategy maximizes rewards earned rather than yield, or it may be a result of changes in borrow and supply rates. Review the strategy to make sure it fits your needs.`}
              placement={'top'}
            >
              <>{parsedRate}</>
            </StyledTooltip>
          ) : (
            parsedRate
          )}
          <span>{showYieldAsApr ? t('apr') : t('apy')}</span>
        </YieldValue>
        <AvgYield>
          <AvgYieldTitle>{historicalPeriod} Day Avg.</AvgYieldTitle>
          {parsedHistoricalRate}
        </AvgYield>
      </Yield>
      <TagsWrapper>
        <TagSpacer>
          {strategy.tags.map((tag: string, i: number) => {
            const tagText = Tags[tag as keyof typeof Tags]
            const matchingTag = partsFormatted.find(part => part.includes(tagText))
            const color = TagsColor[tag as keyof typeof Tags]
            const inactive = !total && color === '#2b6dc2' && !strategy.includeRates?.includes(tagText)
            if (tag.startsWith('leverage')) return
            return (
              <Tag
                key={`${strategy.title}-tag-${i}`}
                color={inactive ? '#565A69' : color}
                selected={selectedFilters.includes(tag)}
              >
                <StyledTooltip title={TagsTooltip[tag as keyof typeof Tags]} placement={'top'}>
                  <div>{matchingTag ?? tagText}</div>
                </StyledTooltip>
              </Tag>
            )
          })}
        </TagSpacer>
      </TagsWrapper>
      <AssetList>
        <AssetTitle>Collateral</AssetTitle>
        {/*<AssetRate small>{!position ? (showYieldAsApr ? t('apr') : t('apy')) : ''}</AssetRate>*/}
        {strategy.collateralAssets.map((asset: Token, i: number) => (
          <Asset key={i}>
            <TokenLogo>
              <img
                src={getLogoOrDefault(cleanCurrencySymbol(asset) ?? '')}
                alt={`${cleanCurrencySymbol(asset)} logo`}
              />
            </TokenLogo>
            {cleanCurrencySymbol(asset)}
            {/*<AssetRate>*/}
            {/*  {supplyInterestRateParts && supplyInterestRateParts.length > 0 ? (*/}
            {/*    <StyledTooltip*/}
            {/*      title={*/}
            {/*        <YieldTooltip*/}
            {/*          interestRateParts={supplyInterestRateParts}*/}
            {/*          lendingYield={supplyInterestRate}*/}
            {/*          totalSupplyYield={totalSupplyRate}*/}
            {/*          showYieldAsApr={showYieldAsApr}*/}
            {/*          isBorrowRate={true}*/}
            {/*        />*/}
            {/*      }*/}
            {/*      placement={'bottom'}*/}
            {/*      arrow={true}*/}
            {/*    >*/}
            {/*      <OutsideRate>{formatAmount(totalSupplyRate, 2, true, '0%')}</OutsideRate>*/}
            {/*    </StyledTooltip>*/}
            {/*  ) : (*/}
            {/*    formatAmount(totalSupplyRate, 2, true, '0%')*/}
            {/*  )}*/}
            {/*</AssetRate>*/}
          </Asset>
        ))}
      </AssetList>
      <AssetList right>
        <AssetTitle>Debt</AssetTitle>
        {/*<AssetRate small>{showYieldAsApr ? t('apr') : t('apy')}</AssetRate>*/}
        {strategy.debtAssets.map((asset: Token, i: number) => (
          <Asset key={i}>
            <TokenLogo>
              <img
                src={getLogoOrDefault(cleanCurrencySymbol(asset) ?? '')}
                alt={`${cleanCurrencySymbol(asset)} logo`}
              />
            </TokenLogo>
            {cleanCurrencySymbol(asset)}
            {/*<AssetRate>*/}
            {/*  {borrowInterestRateParts && borrowInterestRateParts.length > 0 ? (*/}
            {/*    <StyledTooltip*/}
            {/*      title={*/}
            {/*        <YieldTooltip*/}
            {/*          interestRateParts={borrowInterestRateParts}*/}
            {/*          lendingYield={borrowInterestRate}*/}
            {/*          totalSupplyYield={totalBorrowRate}*/}
            {/*          showYieldAsApr={showYieldAsApr}*/}
            {/*          isBorrowRate={true}*/}
            {/*        />*/}
            {/*      }*/}
            {/*      placement={'bottom'}*/}
            {/*      arrow={true}*/}
            {/*    >*/}
            {/*      <OutsideRate>{formatAmount(totalBorrowRate, 2, true, '0%')}</OutsideRate>*/}
            {/*    </StyledTooltip>*/}
            {/*  ) : (*/}
            {/*    formatAmount(totalBorrowRate, 2, true, '0%')*/}
            {/*  )}*/}
            {/*</AssetRate>*/}
          </Asset>
        ))}
      </AssetList>
      <SubmitButtonWrapper onClick={() => submit()} card>
        <SubmitButton card>Create Position</SubmitButton>
      </SubmitButtonWrapper>
    </StrategyWrapper>
  )
}

function TagSelector({
  tag,
  activateTag,
  selectedFilters,
}: {
  tag: string
  activateTag: (activate: boolean) => void
  selectedFilters: string[]
}) {
  const [selected, setSelected] = useState(false)
  useEffect(() => {
    if (selectedFilters.length === 0) setSelected(false)
  }, [selectedFilters])
  const selectTag = useCallback(() => {
    if (!selected) {
      setSelected(true)
      activateTag(true)
    } else {
      setSelected(false)
      activateTag(false)
    }
  }, [selected, activateTag])

  return (
    <Tag selected={selected} filter color={TagsColor[tag as keyof typeof TagsColor]} onClick={() => selectTag()}>
      <StyledTooltip title={TagsTooltip[tag as keyof typeof Tags]} placement={'top'}>
        <div>{Tags[tag as keyof typeof Tags]}</div>
      </StyledTooltip>
    </Tag>
  )
}

export default function Strategies() {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const [hasApprovedToS, setHasApprovedToS] = useApprovedToS()
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedStrategy, setSelectedStrategy] = useState(0)
  const [showYieldAsApr, setShowYieldAsApr] = useShowYieldAsApr()
  const [filterAll, setFilterAll] = useState(true)
  const [totalYield, setTotalYield] = useState(true)
  const [inputValue, setInputValue] = useState<string>('')
  const [showInfoPanel, setShowInfoPanel] = useState(true)
  const interestRatemap = useStrategyInterestRateMap()
  const { id, positionId } = useParams<{ id: string; positionId: string }>()
  const history = useHistory()
  const [chainSelectOpen, setChainSelectOpen] = useState<boolean>(false)
  const node = useRef<HTMLDivElement>()
  const [chainFilter, setChainFilter] = useState<ChainId | undefined>(undefined)
  const [sortSelectOpen, setSortSelectOpen] = useState<boolean>(false)
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  const sortTypes = ['APR ↓', 'APR ↑', 'Tags']
  const [sortType, setSortType] = useState<string>('APR ↓')
  useEffect(() => {
    const idNumber = parseFloat(id)
    if (idNumber > -1 && !positionId) {
      setSelectedStrategy(idNumber)
      setModalOpen(true)
    }
  }, [id, positionId])
  const updateFilter = useCallback((tag, activate) => {
    if (activate) {
      setSelectedFilters(oldFilters => [...oldFilters, tag])
    } else {
      setSelectedFilters(oldFilters => oldFilters.filter(filter => filter !== tag))
    }
  }, [])
  const taggedStrategies: StrategyInfo[] = useMemo(() => {
    if (selectedFilters.length === 0) return StrategiesData
    if (!filterAll) {
      return StrategiesData.filter(strategy => strategy.tags.some(stratTag => selectedFilters.includes(stratTag)))
    } else {
      return StrategiesData.filter(strategy => selectedFilters.every(stratTag => strategy.tags.includes(stratTag)))
    }
  }, [selectedFilters, filterAll])

  const chainStrategies: StrategyInfo[] = useMemo(() => {
    if (!chainFilter) return taggedStrategies
    return taggedStrategies.filter(strategy => strategy.chain === chainFilter)
  }, [taggedStrategies, chainFilter])

  const filteredStrategies: StrategyInfo[] = useMemo(() => {
    if (inputValue.length === 0) return chainStrategies
    const value = inputValue.toUpperCase()
    return chainStrategies.filter(strategy => {
      if (strategy.title.toUpperCase().startsWith(value)) return true
      if (strategy.tags.some(tag => tag.toUpperCase().startsWith(value))) return true
      if (
        strategy.collateralAssets.some(asset =>
          cleanCurrencySymbol(asset)
            ?.toUpperCase()
            .startsWith(value),
        )
      ) {
        return true
      }
      if (
        strategy.debtAssets.some(asset =>
          cleanCurrencySymbol(asset)
            ?.toUpperCase()
            .startsWith(value),
        )
      ) {
        return true
      }
      return false
    })
  }, [inputValue, chainStrategies])

  const sortedYieldStrategies: StrategyInfo[] = useMemo(() => {
    return [...filteredStrategies].sort((a, b) => {
      return (interestRatemap[a.id][totalYield ? 0 : 1] ?? ZERO_PERCENT).greaterThan(
        interestRatemap[b.id][totalYield ? 0 : 1] ?? ZERO_PERCENT,
      )
        ? -1
        : 1
    })
  }, [filteredStrategies, interestRatemap, totalYield])

  const sortedStrategies: StrategyInfo[] = useMemo(() => {
    if (sortType === 'APR ↓') return sortedYieldStrategies
    if (sortType === 'APR ↑') return [...sortedYieldStrategies].reverse()
    return [...filteredStrategies].sort((a, b) => {
      return b.tags.length - a.tags.length
    })
  }, [filteredStrategies, sortedYieldStrategies, sortType])

  const highestYieldId = useMemo(() => {
    return sortedYieldStrategies[0].id
  }, [sortedYieldStrategies])

  const tagsList = Object.keys(Tags)
  const splitIndex = tagsList.indexOf('usdm')
  const otherTags = tagsList.slice(0, splitIndex)
  const rewards = tagsList.slice(splitIndex)

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (wrapperRef?.current && !wrapperRef?.current.contains(event.target as Node)) {
      setSortSelectOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [handleClickOutside])

  const handleFilterClickOutside = useCallback((event: MouseEvent) => {
    if (node?.current && !node?.current.contains(event.target as Node)) {
      setChainSelectOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleFilterClickOutside)
    return () => document.removeEventListener('mousedown', handleFilterClickOutside)
  }, [handleFilterClickOutside])

  const createPosition = useCallback((index: number) => {
    setSelectedStrategy(index)
    setModalOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setModalOpen(false)
    history.push('/strategies')
  }, [history])

  const closeInfoPanel = useCallback(() => {
    setShowInfoPanel(false)
  }, [])

  const FilterSliders = () => {
    return (
      <SelectorsWrapper>
        <Selectors>
          <FilterSelect onClick={() => setFilterAll(!filterAll)}>
            <FilterSelector active={filterAll}>All</FilterSelector>
            <FilterSelector active={!filterAll}>Any</FilterSelector>
          </FilterSelect>
          <StyledTooltip title={'Toggle yields including external rewards'}>
            <FilterSelect onClick={() => setTotalYield(!totalYield)} middle>
              <FilterSelector active={totalYield}>
                <StyledAutoAwesomeIcon />
              </FilterSelector>
              <FilterSelector active={!totalYield}>
                <StyledNoneIcon />
              </FilterSelector>
            </FilterSelect>
          </StyledTooltip>
          <FilterSelect onClick={() => setShowYieldAsApr(!showYieldAsApr)} right>
            <FilterSelector active={showYieldAsApr}>APR</FilterSelector>
            <FilterSelector active={!showYieldAsApr}>APY</FilterSelector>
          </FilterSelect>
        </Selectors>
      </SelectorsWrapper>
    )
  }

  return (
    <PageWrapper>
      <PageTitle title={'Dolomite | Strategies'} />
      {!hasApprovedToS && <TOSPopover setApprovedToS={setHasApprovedToS} />}
      <Header>
        {showInfoPanel && (
          <DisclaimerWrapper>
            <CloseButton onClick={closeInfoPanel}>
              <Close />
            </CloseButton>
            <DisclaimerTitle>Welcome Level 10s and Above!</DisclaimerTitle>
            <DisclaimerContent>{`Make sure to check the Level 10+ channel on the Dolomite Discord for full information about this early look!`}</DisclaimerContent>
            <DisclaimerContent>{`We've been working on this page for quite a while and are excited to finally be able to put it into your hands for an early look! We're still putting the final touches on the page, but at this point you should be able to smoothly open and close strategies. If you face any issues, Strategies utilizes standard Dolomite Borrow Positions under the hood, so you can still manage them from the Borrow page if necessary.`}</DisclaimerContent>
            <DisclaimerContent>{`We really want to hear your feedback, things you like, things you dislike, any issues you ran into, or things that weren't a smooth experience. If you've reached level 10, you're certainly familiar with all of Dolomite's features, but try looking at the page from the perspective of a simpler user. We could love Strategies to make Dolomite more accessible to new, less advanced users!`}</DisclaimerContent>
            <DisclaimerContent>
              {`In exchange for some thorough feedback, we'll send you 25 USDC (we will verify you're level 10+), you can find the feedback link here: `}
              <a href={'https://xb4zjbxv5mz.typeform.com/to/ZldqXGSu'}>Survey</a>
            </DisclaimerContent>
          </DisclaimerWrapper>
        )}
        <Title>{t('Strategies')}</Title>
        <Descriptor>
          Dolomite Strategies are prebuilt positions that you can create with a single click. These strategies are
          designed to let you maximize your yield, rewards, or more.
          {/*<div>
            Expecting to a see an active strategy? Make sure you switch to the chain you opened the strategy on.
          </div>*/}
        </Descriptor>
        <FiltersWrapperMobile>
          <FilterTitle>
            Filters
            {selectedFilters.length > 0 && <ClearFilters onClick={() => setSelectedFilters([])} />}
          </FilterTitle>
          <InputWrapper mobile>
            <InputOverflowFix>
              <StyledInput
                onChange={(e: any) => setInputValue(e.target.value)}
                multiline={false}
                fullWidth
                spellCheck={false}
                placeholder={'Search for Strategy'}
                value={inputValue}
                variant=''
                disableUnderline={true}
                endAdornment={''}
              />
            </InputOverflowFix>
            <Clear onClick={() => setInputValue('')} disabled={inputValue === ''}>
              <Close />
            </Clear>
          </InputWrapper>
          <MobileSelectors>
            <FilterSliders />
          </MobileSelectors>
          <FiltersDescription mobile>
            Select the tags below to filter the strategies by categories. You can choose to search by any or all of the
            tags selected.
          </FiltersDescription>
          <TagsMobile>
            {otherTags.map((tag: string, i: number) => (
              <TagSelector
                tag={tag}
                key={`tag-filter-${i}`}
                activateTag={activate => updateFilter(tag, activate)}
                selectedFilters={selectedFilters}
              />
            ))}
            <RewardTagTitle></RewardTagTitle>
            {rewards.map((tag: string, i: number) => (
              <TagSelector
                tag={tag}
                key={`tag-filter-${i}`}
                activateTag={activate => updateFilter(tag, activate)}
                selectedFilters={selectedFilters}
              />
            ))}
          </TagsMobile>
          <MobileSelectorsSmall>
            <FilterSliders />
          </MobileSelectorsSmall>
        </FiltersWrapperMobile>
      </Header>
      <BalancesWrapper>
        <DepositedBalances />
      </BalancesWrapper>
      <StrategiesWrapper>
        <ActiveStrategies interestRateMap={interestRatemap} totalYieldSelected={totalYield} />
        <StrategSectionHeader>
          Browse Strategies
          <SortWrapper>
            <NetworkSelectorWrapper ref={node as any}>
              <StyledNetworkButton onClick={() => setChainSelectOpen(true)}>
                {chainFilter ? (
                  <LinkButtonWrapper>
                    <StyledChainIcon alt={`${NETWORK_LABELS[chainFilter]} logo`} src={NETWORK_LOGOS[chainFilter]} />
                    <ChainName>{NETWORK_LABELS[chainFilter]}</ChainName>
                  </LinkButtonWrapper>
                ) : (
                  <LinkButtonWrapper>
                    <StyledLinkIcon />
                    <ChainName all>All Chains</ChainName>
                  </LinkButtonWrapper>
                )}
                <ArrowDown />
              </StyledNetworkButton>
              <FlyoutWrapper isOpen={chainSelectOpen}>
                <MenuFlyout>
                  <NetworkRow
                    noChain
                    onClick={() => {
                      setChainFilter(undefined)
                      setChainSelectOpen(false)
                    }}
                  >
                    <LinkIconWrapper>
                      <StyledLinkIcon />
                    </LinkIconWrapper>
                    All Chains
                  </NetworkRow>
                  {[ChainId.ARBITRUM_ONE, ChainId.MANTLE].map(chain => (
                    <NetworkRow
                      key={chain}
                      onClick={() => {
                        setChainFilter(chain)
                        setChainSelectOpen(false)
                      }}
                    >
                      <img alt={`${NETWORK_LABELS[chain]} logo`} src={NETWORK_LOGOS[chain]} />
                      {NETWORK_LABELS[chain]}
                    </NetworkRow>
                  ))}
                </MenuFlyout>
              </FlyoutWrapper>
            </NetworkSelectorWrapper>
            <Sorting>
              <SortSelect
                expanded={sortSelectOpen}
                ref={wrapperRef}
                onClick={() => !sortSelectOpen && setSortSelectOpen(true)}
              >
                {!sortSelectOpen && (
                  <SortSelectRow onClick={() => setSortSelectOpen(false)}>
                    {sortType === 'None' ? 'Sort' : sortType}
                  </SortSelectRow>
                )}
                {sortTypes.map((sort, index) => {
                  return (
                    <SortSelectRow
                      key={`filter-dropdown-${index}`}
                      onClick={() => {
                        setSortType(sort)
                        setSortSelectOpen(false)
                      }}
                    >
                      {sort}
                    </SortSelectRow>
                  )
                })}
                <ArrowDown />
              </SortSelect>
            </Sorting>
          </SortWrapper>
        </StrategSectionHeader>
        {sortedStrategies.map((strategy: StrategyInfo, index: number) => (
          <Strategy
            createPosition={() => createPosition(index)}
            key={`strategy-${index}`}
            strategy={strategy}
            interestRatemap={interestRatemap}
            selectedFilters={selectedFilters}
            total={totalYield}
            highestYield={highestYieldId === strategy.id}
          />
        ))}
        {sortedStrategies.length === 0 && <NoStrategies>No Strategies</NoStrategies>}
      </StrategiesWrapper>
      <FiltersWrapper>
        <FilterTitle>
          Filters
          {selectedFilters.length > 0 && <ClearFilters onClick={() => setSelectedFilters([])} />}
        </FilterTitle>
        <InputWrapper>
          <InputOverflowFix>
            <StyledInput
              onChange={(e: any) => setInputValue(e.target.value)}
              multiline={false}
              fullWidth
              spellCheck={false}
              placeholder={'Search for Strategy'}
              value={inputValue}
              variant=''
              disableUnderline={true}
              endAdornment={''}
            />
          </InputOverflowFix>
          <Clear onClick={() => setInputValue('')} disabled={inputValue === ''}>
            <Close />
          </Clear>
        </InputWrapper>
        <FiltersDescription>Select the tags below to filter the strategies by categories</FiltersDescription>
        {otherTags.map((tag: string, i: number) => (
          <TagSelector
            tag={tag}
            key={`tag-filter-${i}`}
            activateTag={activate => updateFilter(tag, activate)}
            selectedFilters={selectedFilters}
          />
        ))}
        <RewardTagTitle></RewardTagTitle>
        {rewards.map((tag: string, i: number) => (
          <TagSelector
            tag={tag}
            key={`tag-filter-${i}`}
            activateTag={activate => updateFilter(tag, activate)}
            selectedFilters={selectedFilters}
          />
        ))}
        <FiltersDescription>Filter by any/all of selected tags</FiltersDescription>
        <FilterSliders />
      </FiltersWrapper>
      {!!sortedStrategies[selectedStrategy] && modalOpen && (
        <CreatePositionModal
          strategy={sortedStrategies[selectedStrategy]}
          isOpen={modalOpen}
          closeModal={() => closeModal()}
          interestRatePair={interestRatemap[sortedStrategies[selectedStrategy].id]}
          totalYieldSelected={totalYield}
        />
      )}
    </PageWrapper>
  )
}
