import { createFraction, InterestRate as InterestRateGql } from './gqlTypeHelpers'
import { Ether, Fraction, Percent } from '@dolomite-exchange/sdk-core'
import { useMemo } from 'react'
import JSBI from 'jsbi'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import { ChainId, ONE_ETH_IN_WEI, ZERO_PERCENT } from '../constants'
import { Token } from '@dolomite-exchange/v2-sdk'
import { useDailyBlockNumbersByTimestamps } from './useTimestampToBlockNumberData'
import { NO_VARIABLES, useGraphqlResult, useGraphqlResultList } from '../state/graphql/hooks'
import { GraphqlCall, GraphqlClientType } from '../state/graphql/actions'
import { deserializeInterestRatePartJson } from '../state/graphql/useAllOutsideInterestRates'
import { SpecialAsset, useAllSpecialAssets } from '../constants/isolation/special-assets'
import { address } from '@dolomite-exchange/dolomite-margin/dist/src/types'
import { useActiveWeb3React } from '../hooks'
import mergeMaps from '../utils/mergeMaps'
import { useShowYieldAsApr } from '../state/user/hooks'
import calculateAprToApy from '../utils/calculateAprToApy'
import { RefreshFrequency, useBlockTimestamp } from '../state/chain/hooks'
import cleanCurrencySymbol from '../utils/cleanCurrencySymbol'
import { useAllTokens } from '../hooks/Tokens'
import { InterestRatePart, InterestRatePartApi } from './InterestRatePart'
import { DOLOMITE_API_SERVER_URL } from '@dolomite-exchange/zap-sdk'
import { mapListedTokenToExternalToken } from '../utils/externalTokens'
import { formatAmount } from '../utils/formatAmount'
import { IpErrorType } from '../hooks/useIpGeolocation'

interface InterestRateResponse {
  interestRates: InterestRateApi[]

  [key: string]: InterestRateApi[]
}

interface InterestRateApi {
  token: {
    tokenAddress: string
    marketId: string
  }
  interestSetter: string
  lowerOptimalRate: string
  upperOptimalRate: string
  optimalUtilizationRate: string
  supplyInterestRate: string
  borrowInterestRate: string
  totalSupplyInterestRate: string
  totalBorrowInterestRate: string
  outsideSupplyInterestRateParts: InterestRatePartApi[]
  outsideBorrowInterestRateParts: InterestRatePartApi[]
}

export interface InterestRate {
  marketId: JSBI
  blockTimestamp: number
  tokenAddress: string
  borrowInterestRate: Percent
  supplyInterestRate: Percent
  outsideSupplyInterestRateParts: InterestRatePart[] | undefined
  outsideBorrowInterestRateParts: InterestRatePart[] | undefined
  totalSupplyInterestRate: Percent
  totalBorrowInterestRate: Percent
  interestSetter: string
  optimalUtilizationRate: Fraction
  lowerOptimalRate: Fraction
  upperOptimalRate: Fraction
}

export function useHistoricalInterestRateDataByToken(
  token?: Token,
): {
  loading: boolean
  error: boolean
  data: InterestRate[] | undefined
} {
  const { data: blocks } = useDailyBlockNumbersByTimestamps(90)
  const query = useMemo(() => {
    return `
    query getHistoricalInterestRatesByToken(
      ${blocks.map(block => `$block_${block.blockNumber}: Int!,`).join('\n')}
      $tokenAddress: String!
    ) {
    ${blocks.map(block => {
      return `block_${block.blockNumber}:interestRate(block: { number: $block_${block.blockNumber}} id: $tokenAddress) {
      id
      borrowInterestRate
      supplyInterestRate
      token {
        id
        marketId
      }
      interestSetter
      optimalUtilizationRate
      lowerOptimalRate
      upperOptimalRate
    }`
    })}
  }
  `
  }, [blocks])

  const variables = useMemo(() => {
    if (!token || blocks.every(block => block.blockNumber === 0)) {
      return undefined
    }
    return {
      tokenAddress: token.address.toLowerCase(),
      ...blocks.reduce<Record<string, number>>((memo, block) => {
        memo[`block_${block.blockNumber}`] = block.blockNumber
        return memo
      }, {}),
    }
  }, [token, blocks])
  const queryState = useGraphqlResult<Record<string, InterestRateGql>>(
    GraphqlClientType.Dolomite,
    query,
    variables,
    RefreshFrequency.Slow,
  )

  return useMemo(() => {
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const interestRates = blocks
      .map<InterestRate | undefined>(block => {
        const gql = result?.[`block_${block.blockNumber}`]
        if (!gql) {
          return undefined
        }
        const tokenAddress = toChecksumAddress(gql.token.id)
        const borrowRateFraction = createFraction(gql.borrowInterestRate)
        const supplyRateFunction = createFraction(gql.supplyInterestRate)
        return {
          marketId: JSBI.BigInt(gql.token.marketId),
          blockTimestamp: block.timestamp,
          tokenAddress: tokenAddress,
          interestSetter: toChecksumAddress(gql.interestSetter),
          borrowInterestRate: Percent.fromFraction(borrowRateFraction),
          supplyInterestRate: Percent.fromFraction(supplyRateFunction),
          outsideSupplyInterestRateParts: undefined,
          outsideBorrowInterestRateParts: undefined,
          totalSupplyInterestRate: Percent.fromFraction(supplyRateFunction),
          totalBorrowInterestRate: Percent.fromFraction(borrowRateFraction),
          optimalUtilizationRate: new Fraction(gql.optimalUtilizationRate, ONE_ETH_IN_WEI),
          lowerOptimalRate: new Fraction(gql.lowerOptimalRate, ONE_ETH_IN_WEI),
          upperOptimalRate: new Fraction(gql.upperOptimalRate, ONE_ETH_IN_WEI),
        }
      })
      .filter(value => value !== undefined) as InterestRate[]

    return {
      loading: anyLoading,
      error: anyError,
      data: interestRates,
    }
  }, [queryState, blocks])
}

export function useInterestRateData(
  chain?: ChainId,
  includeParts?: string[],
): {
  loading: boolean
  error: boolean
  data: Record<string, InterestRate | undefined>
} {
  const { chainId } = useActiveWeb3React()
  const blockTimestamp = useBlockTimestamp().toNumber()
  const queryState = useGraphqlResult<InterestRateResponse>(
    GraphqlClientType.Fetch,
    `${DOLOMITE_API_SERVER_URL}/tokens/${chain ?? chainId}/interest-rates`,
    NO_VARIABLES,
    RefreshFrequency.Medium,
  )
  const outsideAprData = useAllOutsideInterestRates()
  const [showYieldAsApr] = useShowYieldAsApr()
  const tokenMap = useAllTokens()

  return useMemo(() => {
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const interestRates = (result?.interestRates ?? []).reduce<Record<string, InterestRate>>((memo, apiData) => {
      const tokenAddress = toChecksumAddress(apiData.token.tokenAddress)
      const borrowRateFraction = createFraction(apiData.borrowInterestRate)
      const supplyRateFunction = createFraction(apiData.supplyInterestRate)
      const borrowInterestRate = showYieldAsApr
        ? Percent.fromFraction(borrowRateFraction)
        : calculateAprToApy(Percent.fromFraction(borrowRateFraction))
      const supplyInterestRate = showYieldAsApr
        ? Percent.fromFraction(supplyRateFunction)
        : calculateAprToApy(Percent.fromFraction(supplyRateFunction))

      const initialSupplyInterestParts = apiData.outsideSupplyInterestRateParts.map<InterestRatePart>(p => ({
        interestRate: p.interestRate ? Percent.fromFraction(createFraction(p.interestRate)) : undefined,
        label: p.label,
        isBorrowRateImpacted: undefined,
        metadata: p.metadata,
      }))
      const outsideSupplyInterestRateParts = initialSupplyInterestParts.reduce((memo, part) => {
        return memo.concat({
          label: showYieldAsApr ? part.label : part.label.replace('APR', 'APY'),
          interestRate: showYieldAsApr || !part.interestRate ? part.interestRate : calculateAprToApy(part.interestRate),
          isBorrowRateImpacted: part.isBorrowRateImpacted,
          metadata: part.metadata,
        })
      }, outsideAprData[tokenAddress] ?? [])

      const initialBorrowInterestParts = apiData.outsideBorrowInterestRateParts.map<InterestRatePart>(p => ({
        interestRate: p.interestRate ? Percent.fromFraction(createFraction(p.interestRate)) : undefined,
        label: p.label,
        isBorrowRateImpacted: true,
        metadata: p.metadata,
      }))
      const outsideBorrowInterestRateParts = outsideSupplyInterestRateParts?.reduce((memo, part) => {
        if (!part.isBorrowRateImpacted) {
          return memo
        }

        return memo ? memo.concat(part) : [part]
      }, initialBorrowInterestParts)

      const totalSupplyRate =
        outsideSupplyInterestRateParts?.reduce((prev: Percent, interestRatePart) => {
          return prev.add(interestRatePart.interestRate ?? ZERO_PERCENT)
        }, supplyInterestRate) ?? supplyInterestRate

      const totalBorrowRate =
        outsideBorrowInterestRateParts?.reduce((prev: Percent, interestRatePart) => {
          return prev.add(interestRatePart.interestRate ?? ZERO_PERCENT)
        }, borrowInterestRate) ?? borrowInterestRate

      const noRewardsSupplyInterestRate = includeParts
        ? outsideSupplyInterestRateParts?.reduce((prev: Percent, interestRatePart) => {
            // console.log(interestRatePart.label, includeParts)
            return includeParts.includes(interestRatePart.label)
              ? prev.add(interestRatePart.interestRate ?? ZERO_PERCENT)
              : ZERO_PERCENT
          }, supplyInterestRate)
        : totalSupplyRate
      const noRewardsBorrowRate = includeParts
        ? outsideBorrowInterestRateParts?.reduce((prev: Percent, interestRatePart) => {
            return includeParts.includes(interestRatePart.label)
              ? prev.add(interestRatePart.interestRate ?? ZERO_PERCENT)
              : ZERO_PERCENT
          }, borrowInterestRate)
        : totalBorrowRate

      memo[tokenAddress] = {
        blockTimestamp,
        marketId: JSBI.BigInt(apiData.token.marketId),
        tokenAddress: tokenAddress,
        interestSetter: toChecksumAddress(apiData.interestSetter),
        borrowInterestRate: borrowInterestRate,
        supplyInterestRate: supplyInterestRate,
        outsideSupplyInterestRateParts: outsideSupplyInterestRateParts,
        outsideBorrowInterestRateParts: outsideBorrowInterestRateParts,
        totalSupplyInterestRate: totalSupplyRate,
        totalBorrowInterestRate: totalBorrowRate,
        optimalUtilizationRate: createFraction(apiData.optimalUtilizationRate),
        lowerOptimalRate: createFraction(apiData.lowerOptimalRate),
        upperOptimalRate: createFraction(apiData.upperOptimalRate),
      }

      const token = tokenMap[tokenAddress]
      const currencySymbol = token ? Ether.onChain(token.chainId).symbol : undefined
      if (currencySymbol && currencySymbol === cleanCurrencySymbol(token)) {
        memo[currencySymbol] = memo[tokenAddress]
      }
      if (token && !mapListedTokenToExternalToken(token).equals(token)) {
        memo[mapListedTokenToExternalToken(token).address] = memo[tokenAddress]
      }

      return memo
    }, {})

    return {
      loading: anyLoading,
      error: anyError,
      data: interestRates,
    }
  }, [queryState, showYieldAsApr, outsideAprData, includeParts, blockTimestamp, tokenMap])
}

interface SpecialGraphqlCalls {
  fetchSpecialAssets: SpecialAsset[]
  fetchCallDatas: GraphqlCall[]
  functionalSpecialAssets: SpecialAsset[]
  functionalCallDatas: GraphqlCall[]
  postSpecialAssets: SpecialAsset[]
  postCallDatas: GraphqlCall[]
}

function accumulateSpecialGraphqlCalls(
  accumulator: SpecialGraphqlCalls,
  asset: SpecialAsset,
  callData: GraphqlCall | undefined,
) {
  if (callData?.clientType === GraphqlClientType.Fetch) {
    accumulator.fetchSpecialAssets.push(asset)
    accumulator.fetchCallDatas.push(callData)
  } else if (callData?.clientType === GraphqlClientType.Functional) {
    accumulator.functionalSpecialAssets.push(asset)
    accumulator.functionalCallDatas.push(callData)
  } else if (callData?.clientType === GraphqlClientType.Post) {
    accumulator.postSpecialAssets.push(asset)
    accumulator.postCallDatas.push(callData)
  }
}

export function useAllOutsideInterestRates(): Record<address, InterestRatePart[] | undefined> {
  const { chainId } = useActiveWeb3React()
  const allSpecialAssets = useAllSpecialAssets()

  const {
    fetchSpecialAssets,
    fetchCallDatas,
    functionalSpecialAssets,
    functionalCallDatas,
    postSpecialAssets,
    postCallDatas,
  } = useMemo(() => {
    return allSpecialAssets.reduce<SpecialGraphqlCalls>(
      (acc, asset) => {
        accumulateSpecialGraphqlCalls(acc, asset, asset.rewardInfo?.getOutsideAprCallData(chainId))
        accumulateSpecialGraphqlCalls(acc, asset, asset.rewardInfo?.getExtraOutsideAprCallData?.(chainId))
        return acc
      },
      {
        fetchSpecialAssets: [],
        fetchCallDatas: [],
        functionalSpecialAssets: [],
        functionalCallDatas: [],
        postSpecialAssets: [],
        postCallDatas: [],
      },
    )
  }, [chainId, allSpecialAssets])

  const fetchStates = useGraphqlResultList<any>(fetchCallDatas, RefreshFrequency.Slowest)
  const functionalStates = useGraphqlResultList<any>(functionalCallDatas, RefreshFrequency.Fast)
  const postStates = useGraphqlResultList<any>(postCallDatas, RefreshFrequency.Slowest)

  return useMemo(() => {
    const tokenAddressToFetchYields = fetchStates.reduce<Record<string, InterestRatePart[] | undefined>>(
      (memo, state, i) => {
        const asset = fetchSpecialAssets[i]
        if (state.result) {
          memo[asset.chainIdToAddressMap[chainId] ?? ''] = asset.rewardInfo?.parser?.(state.result)
        }
        return memo
      },
      {},
    )
    const tokenAddressToFunctionalYields = functionalStates.reduce<Record<string, InterestRatePart[] | undefined>>(
      (memo, state, i) => {
        const asset = functionalSpecialAssets[i]
        if (state.result) {
          memo[asset.chainIdToAddressMap[chainId] ?? ''] = deserializeInterestRatePartJson(state.result)
        }
        return memo
      },
      {},
    )
    const tokenAddressToPostYields = postStates.reduce<Record<string, InterestRatePart[] | undefined>>(
      (memo, state, i) => {
        const asset = postSpecialAssets[i]
        if (state.result) {
          memo[asset.chainIdToAddressMap[chainId] ?? ''] = asset.rewardInfo?.parser?.(state.result)
        }
        return memo
      },
      {},
    )

    return mergeMaps(
      interestRatePartsMergeFunction,
      tokenAddressToFetchYields,
      tokenAddressToFunctionalYields,
      tokenAddressToPostYields,
    )
  }, [
    chainId,
    fetchSpecialAssets,
    fetchStates,
    functionalSpecialAssets,
    functionalStates,
    postStates,
    postSpecialAssets,
  ])
}

export function interestRatePartsMergeFunction(
  a: InterestRatePart[] | undefined,
  b: InterestRatePart[] | undefined,
): InterestRatePart[] | undefined {
  if (a && b) {
    return a.concat(b)
  } else if (a) {
    return a
  } else if (b) {
    return b
  } else {
    return undefined
  }
}
