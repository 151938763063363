import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import Modal from '../../components/Modal'
import { useTranslation } from 'react-i18next'
import { useActiveWeb3React } from '../../hooks'
import { BorrowPosition } from '../../types/borrowPositionData'
import { StrategyInfo, Tags, TagsColor, TagsTooltip } from './StrategiesList'
import {
  Asset,
  AssetList,
  AssetTitle,
  ChainLogo,
  FilterSelect,
  FilterSelector,
  StyledAutoAwesomeIcon,
  StyledNoneIcon,
  SubmitButton,
  SubmitButtonWrapper,
  Tag,
  TagsWrapper,
  THREE_DAYS_MILLIS,
  TokenLogo,
  Yield,
} from './index'
import { ChainId, NETWORK_LABELS, NETWORK_LOGOS } from '../../constants/chainId'
import cleanCurrencyName from '../../utils/cleanCurrencyName'
import { StyledTooltip, StyledTooltipWithIcon } from '../../components/common/StyledTooltip'
import { useInterestRateData } from '../../types/interestRateData'
import { formatAmount } from '../../utils/formatAmount'
import { getPositionHealth, PositionHealthTooltip } from '../Borrow/BorrowPositionRow'
import { CurrencyAmount, Rounding, Token } from '@dolomite-exchange/v2-sdk'
import AllInclusiveIcon from '@material-ui/icons/AllInclusive'
import toDate from '../../utils/toDate'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import Button from '@material-ui/core/Button'
import { useZapExactTokensForTokens, ZapEventType, ZapTransferType, ZapType } from '../../hooks/useExecuteZap'
import { useActiveDolomiteZapClient } from '../../apollo/client'
import { useGetZapExactTokensForTokensParams } from '../../hooks/useGetZapParams'
import { useDefaultFiatValuesWithLoadingIndicator } from '../../hooks/useFiatValue'
import BorrowTradeDetails from '../Borrow/BorrowPosition/BorrowTradeDetails'
import { useDefaultMarginAccount } from '../../types/marginAccount'
import { ZERO_FRACTION, ZERO_PERCENT } from '../../constants'
import ReactGA from 'react-ga'
import { useIsTransactionPending } from '../../state/transactions/hooks'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useIsTokenGeoRestricted } from '../../constants/isolation/special-assets'
import { NewValue, NewValueWrapper } from '../Borrow/BorrowPosition/BorrowExpandedContent'
import ArrowRight from '@material-ui/icons/ArrowForward'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import getLogoOrDefault from '../../components/common/TokenLogos'
import DepositWithdrawModal from '../../components/DepositWithdrawModal'
import ZapModal from '../Borrow/ZapModal'
import { Column, Header, ModalInner, StrategyDescription, TokenValue, YieldBreakdown } from './CreatePositionModal'
import calculateAprToApy from '../../utils/calculateAprToApy'
import { useShowYieldAsApr } from '../../state/user/hooks'
import { Percent } from '@dolomite-exchange/sdk-core'
import { Transfer } from '../../types/transferData'
import { tryParseAmountWithNoCurrency } from '../../state/trade/hooks'

const ManageStrategyWrapper = styled.div`
  width: 100%;
  padding: 24px 32px;
  margin-bottom: 15px;
`

const StrategyStats = styled.div`
  width: 100%;
  /*display: flex;
  justify-content: space-between;*/
`

const EquityStats = styled.div`
  width: 100%;
  text-align: center;
`

const BasicStats = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 8px;
`

const StatsLeft = styled.div`
  width: fit-content;
`

const StatsRight = styled.div`
  width: fit-content;
  text-align: right;
`

const CurrentYieldTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.text2};
  display: inline-block;
  vertical-align: top;
  margin-bottom: -8px;
  margin-top: 5px;
`

const CurrentYield = styled.div<{ isNegative?: boolean }>`
  font-size: 40px;
  color: ${({ theme, isNegative }) => (isNegative ? theme.yellow2 : theme.green1)};
  margin-top: -3px;
  width: 100%;
  display: table;
  vertical-align: bottom;

  span {
    font-size: 14px;
    color: ${({ theme }) => theme.text1};
  }
`

const StatRow = styled.div`
  width: fit-content;
  font-size: 14px;
  color: ${({ theme }) => theme.text1};
  font-weight: 400;
`

const InterestRateStats = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
`

const InterestRateLeft = styled.div`
  width: 50%;
  text-align: left;
`

const InterestRateRight = styled.div`
  width: 50%;
  text-align: right;
`

const AvgYieldTitle = styled.div`
  font-size: 11px;
  font-weight: 600;
  color: ${({ theme }) => theme.text3};
  display: inline-block;
  vertical-align: top;
  margin-bottom: -10px;
`

const AvgYieldValue = styled.div<{ isNegative?: boolean }>`
  font-size: 18px;
  color: ${({ theme, isNegative }) => (isNegative ? theme.yellow1 : theme.green1)};
  margin-top: -10px;
  width: 100%;
  display: table;
  vertical-align: bottom;
  margin-bottom: 5px;

  span {
    font-size: 11px;
    color: ${({ theme }) => theme.text2};
  }
`

const CurrentPosition = styled.div`
  width: 100%;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.bg6};
  padding: 0 18px 18px;
  margin-bottom: 8px;

  > div {
    height: fit-content;
  }
`

const DetailsWrapper = styled.div<{ isVisible: boolean }>`
  width: 100%;
  max-height: ${({ isVisible }) => (isVisible ? '500' : '0')}px;
  overflow: hidden;
  height: fit-content;
  transition: max-height 0.3s ease-in-out;

  > div:nth-of-type(1) {
    margin-top: 0 !important;
    padding-top: 6px !important;
  }
`

const DescriptionTextWrapper = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
  margin-top: 5px;
`

const Title = styled.div`
  width: 100%;
  font-size: 28px;
  margin-bottom: 15px;
`

const StrategyTitle = styled.div`
  font-size: 22px;
  font-weight: 100;
`

const StrategySubtitle = styled.div`
  margin-top: -6px;
  margin-bottom: 6px;
  height: 12px;
`

const ChainLabel = styled.div`
  display: inline-block;
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
  font-weight: 400;
  margin-top: 3px;
  margin-left: 4px;
  vertical-align: top;
`

const Expiration = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.text2};
  display: inline-block;
  font-size: 12px;
  /*margin: 3px 4px 1px 8px;*/
  margin-left: 10px;
  vertical-align: top;
`

const ExpirationTitle = styled.span`
  color: ${({ theme }) => theme.text3};
`

const TagSpacer = styled.div``

const EquityWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
`

const EquityTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.text2};
  display: inline-block;
  vertical-align: top;
  margin-bottom: -3px;

  + div {
    width: 100%;
    margin-top: -5px;
    display: inline-block;
    vertical-align: top;
  }

  svg {
    transform: translate(-3px, 2px);
    margin-right: -8px;
  }
`

const EquityValue = styled.div`
  /*font-size: 28px;*/
  font-size: 38px;
  font-weight: 300;
  color: ${({ theme }) => theme.text1};
  display: inline-block;
  vertical-align: top;
  width: 100%;

  span {
    /*font-size: 20px;*/
    font-size: 28px;
    color: ${({ theme }) => theme.text2};
  }
`

const EquityValueUsd = styled.div`
  /*font-size: 13px;*/
  font-size: 21px;
  font-weight: 300;
  color: ${({ theme }) => theme.text2};
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-top: -5px;
`

const HealthWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
`

const HealthFactorTitle = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.text3};
  font-size: 12px;
  font-weight: 600;
  margin-right: 2px;
`

const HealthFactor = styled.div<{ health: number }>`
  font-size: 12px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  color: ${({ theme, health }) => (health === 3 ? theme.green1 : health == 2 ? theme.yellow1 : theme.red1)};
`

const InfinityWrapper = styled.div<{ expanded: boolean }>`
  width: 14px;

  svg {
    transition: all 0.3s ease-in-out;
    width: 0.8em;
    height: 0.8em;
  }
`

const OpenedWrapper = styled.div`
  width: fit-content;
  margin-top: 0;
  color: ${({ theme }) => theme.text2};
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
`

const OpenedTitle = styled.span`
  color: ${({ theme }) => theme.text3};
`

const OpenedValue = styled.span``

const CloseWrapper = styled.div`
  width: 100%;
  margin-top: 15px;
`

const CloseButton = styled(Button)`
  background-color: ${({ theme }) => theme.bg3} !important;
  color: ${({ theme }) => theme.text1} !important;
  width: 100% !important;
  text-align: center !important;
  padding: 8px 0 !important;
  text-transform: capitalize !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
  margin-bottom: 15px !important;
`

interface ManageStrategyProps {
  isOpen: boolean
  memoizedOnDismiss: () => void
  position: BorrowPosition
  strategy: StrategyInfo
  totalYieldSelected: boolean
  interestRateMap: Record<string, (Percent | undefined)[]>
  positionTransfers: Transfer[]
}

function ManageStrategyModalComparator(prevProps: ManageStrategyProps, nextProps: ManageStrategyProps) {
  return (
    prevProps.isOpen === nextProps.isOpen &&
    prevProps.memoizedOnDismiss === nextProps.memoizedOnDismiss &&
    prevProps.position.totalBorrowUSD === nextProps.position.totalBorrowUSD &&
    prevProps.position.totalSupplyUSD === nextProps.position.totalSupplyUSD &&
    prevProps.strategy === nextProps.strategy
  )
}

const ManageStrategyModal = React.memo(function ManageStrategyModalComponent({
  isOpen,
  memoizedOnDismiss,
  position,
  strategy,
  totalYieldSelected,
  interestRateMap,
  positionTransfers,
}: ManageStrategyProps) {
  const ref = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const [zapRefreshIncrementor, setZapRefreshIncrementor] = useState(0)
  const [totalYield, setTotalYield] = useState(totalYieldSelected)
  const [, setPendingHash] = useState<string | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [zapErrorMessage, setZapErrorMessage] = useState<string | undefined>(undefined)
  const [fiatValueMap] = useDefaultFiatValuesWithLoadingIndicator([
    ...strategy.collateralAssets,
    ...strategy.debtAssets,
  ])
  // TODO - temp, while designed only for a single collateral and debt asset
  const collateralAsset = strategy.collateralAssets[0]
  const collateralSymbol = cleanCurrencySymbol(collateralAsset)
  const debtAsset = strategy.debtAssets[0]
  const debtSymbol = cleanCurrencySymbol(strategy.debtAssets[0])
  const [isAttemptingTx, setIsAttemptingTx] = useState(false)
  const [txHash, setTxHash] = useState<string | undefined>(undefined)
  const isTxPending = useIsTransactionPending(txHash)
  const isGeoRestrictedCollateral = useIsTokenGeoRestricted(collateralAsset.wrapped.address)
  const isGeoRestrictedDebt = useIsTokenGeoRestricted(debtAsset.wrapped.address)
  const isGeoRestricted = isGeoRestrictedCollateral || isGeoRestrictedDebt
  const [showYieldAsApr, setShowYieldAsApr] = useShowYieldAsApr()
  const initialDeposit = positionTransfers[0]
  const [showClose, setShowClose] = useState(false)
  const [zapAmount, setZapAmount] = useState<CurrencyAmount<Token> | undefined>(undefined)

  const leverage = strategy.leverage ?? 1
  const expirationDate = useMemo(() => {
    let expiration: string | undefined = undefined
    const expiryMap = [...strategy.collateralAssets, ...strategy.debtAssets].map(asset => {
      const name = cleanCurrencyName(asset) ?? ''
      const date = name.substring(name.length, name?.length - 10)
      if (Array.from(date)[0] !== '2') return
      if (expiration === undefined) expiration = date
      else if (date && date < expiration) expiration = date
    })
    return expiration
  }, [strategy.collateralAssets, strategy.debtAssets])
  const expirationDateParsed = expirationDate ? new Date(expirationDate) : undefined
  const showExpirationWarning = expirationDateParsed
    ? expirationDateParsed.getTime() - Date.now() < THREE_DAYS_MILLIS
    : false

  const { data: assetInterestRateMap } = useInterestRateData(strategy.chain)
  const mineralBonus = strategy.chain === ChainId.MANTLE ? 2 : 1
  //const interestRate = interestRateMap[strategy.collateralAssets[0].address]
  const interestRate = interestRateMap[strategy.id][totalYield ? 0 : 1]

  const parts = assetInterestRateMap?.outsideSupplyInterestRateParts
  const collateralInterestRateParts =
    assetInterestRateMap[strategy.collateralAssets[0].address]?.outsideSupplyInterestRateParts
  const collateralLendingYield = showYieldAsApr
    ? assetInterestRateMap[strategy.collateralAssets[0].address]?.supplyInterestRate
    : calculateAprToApy(assetInterestRateMap[strategy.collateralAssets[0].address]?.supplyInterestRate ?? ZERO_PERCENT)
  const collateralTotalYield = showYieldAsApr
    ? assetInterestRateMap[strategy.collateralAssets[0].address]?.totalSupplyInterestRate
    : calculateAprToApy(
        assetInterestRateMap[strategy.collateralAssets[0].address]?.totalSupplyInterestRate ?? ZERO_PERCENT,
      )
  const debtInterestRate = showYieldAsApr
    ? assetInterestRateMap[strategy.debtAssets[0].address]?.totalBorrowInterestRate
    : calculateAprToApy(assetInterestRateMap[strategy.debtAssets[0].address]?.totalBorrowInterestRate ?? ZERO_PERCENT)
  const partsFormatted = useMemo(() => {
    return collateralInterestRateParts
      ? collateralInterestRateParts.map((part, index) => {
          return part.metadata
            ? `${(part.metadata?.units ?? 0) *
                leverage *
                (part.metadata?.unitsLabel === 'Minerals' ? mineralBonus : 1)} ${part.metadata?.unitsLabel}`
            : part.label
        })
      : []
  }, [collateralInterestRateParts, leverage, mineralBonus])

  const [positionEquity, positionEquityUsd] = useMemo(() => {
    const positionEquity = position.supplyAmounts[0].amountTokenWei.asFraction.subtract(
      position.totalBorrowUSD.divide(
        position.totalSupplyUSD.divide(position.supplyAmounts[0].amountTokenWei.asFraction),
      ),
    )
    const positionEquityUsd = position.totalSupplyUSD.subtract(position.totalBorrowUSD)
    return [positionEquity, positionEquityUsd]
  }, [position.supplyAmounts, position.totalBorrowUSD, position.totalSupplyUSD])
  const profit = positionEquityUsd.asFraction.subtract(initialDeposit.amountUSD.asFraction)
  const profitApr = useMemo(() => {
    const currentDate = new Date()
    const initialDepositDate = new Date(initialDeposit.transaction.timestamp)
    const daysBetween = tryParseAmountWithNoCurrency(
      ((currentDate.getTime() - initialDepositDate.getTime()) / (1000 * 60 * 60 * 24)).toString(),
    )
    if (!daysBetween || daysBetween.equalTo(ZERO_FRACTION)) return undefined
    return profit
      .divide(initialDeposit.amountUSD.asFraction)
      .multiply(365)
      .divide(daysBetween)
      .multiply(100)
  }, [profit, initialDeposit])

  const positionHealthFactor = useMemo(() => {
    return getPositionHealth(position.positionHealth)
  }, [position.positionHealth])

  const refreshZapData = useCallback(() => {
    setZapRefreshIncrementor(prev => prev + 1)
  }, [])

  const zapClient = useActiveDolomiteZapClient()
  useEffect(() => {
    const newZapAmount = position.supplyAmounts[0].amountTokenWei.multiply(
      position.totalBorrowUSD
        .divide(position.totalSupplyUSD)
        .multiply(106)
        .divide(100),
    )
    if (
      !zapAmount ||
      newZapAmount
        .subtract(zapAmount)
        .divide(zapAmount)
        .abs.multiply(1000)
        .greaterThan(5)
    ) {
      setZapAmount(newZapAmount)
    }
  }, [position.supplyAmounts, position.totalBorrowUSD, position.totalSupplyUSD, zapAmount])

  const { outputs: zaps, error: zapError } = useGetZapExactTokensForTokensParams(
    showClose ? zapAmount : undefined,
    showClose ? debtAsset : undefined,
    true,
    zapRefreshIncrementor,
    position.marginAccount.accountNumber.toString(),
    fiatValueMap,
  )
  const bestZap = useMemo(() => zaps?.[0], [zaps])

  const defaultAccountNumber = useDefaultMarginAccount().accountNumber
  const zapEither = {
    transfers: [
      {
        amount: position.supplyAmounts[0].amountTokenWei.multiply(ZERO_FRACTION),
        eventType: ZapEventType.None,
        isToTradeAccount: false,
        transferOpts: ZapTransferType.ALL_BALANCE,
      },
      {
        amount: position.borrowAmounts[0].amountTokenWei.multiply(ZERO_FRACTION),
        eventType: ZapEventType.None,
        isToTradeAccount: false,
        transferOpts: ZapTransferType.ALL_BALANCE,
      },
    ],
    zapType: undefined,
  }
  const { callback: submitCloseStrategy, error: submitCloseStrategyError } = useZapExactTokensForTokens(
    showClose ? position.marginAccount.accountNumber : undefined,
    showClose ? defaultAccountNumber : undefined,
    showClose ? zaps : undefined,
    zapEither,
    position.specialInfo.specialAsset,
    false,
    true,
  )

  const closePositionCallback = useCallback(() => {
    if (!submitCloseStrategy) {
      return
    }
    setZapErrorMessage(undefined)
    setIsAttemptingTx(true)
    submitCloseStrategy()
      .then(hash => {
        setTxHash(txHash)
        setIsAttemptingTx(false)
        setPendingHash(hash)
        ReactGA.event({
          category: 'Strategy',
          action: `Closing strategy ${strategy.title} and withdrawing equity`,
        })
      })
      .catch(e => {
        setIsAttemptingTx(false)
        setTxHash(undefined)
        setPendingHash(undefined)
        if (e.message === 'insufficient-gas' || e.message?.includes('insufficient funds for gas')) {
          setErrorMessage('Insufficient ETH balance for gas')
          // TODO display error in the interface's dialogue box and don't close it
        } else if (
          e.message.includes('Cannot execute when paused') ||
          e.message.includes('Cannot lever up when paused')
        ) {
          setErrorMessage('Pause sentinel active')
        } else if (e.message !== 'transaction-rejected') {
          console.error('Caught error in web3 callback:', e)
          setErrorMessage('Zap failed')
          // TODO display error in the interface's dialogue box and don't close it
        } else {
          console.log(e.message)
        }
      })
  }, [strategy.title, submitCloseStrategy, txHash])

  // const projectedProfit =
  //   parsedInputValue && interestRate
  //     ? parsedInputValue?.asFraction.add(
  //       parsedInputValue?.asFraction.multiply(interestRate?.asFraction ?? ZERO_FRACTION),
  //     )
  //     : ZERO_FRACTION

  const handleCloseButton = useCallback(() => {
    !showClose ? setShowClose(true) : closePositionCallback()
  }, [closePositionCallback, showClose])

  const submitButtonDisabled = useMemo(() => {
    return (
      isGeoRestricted ||
      isAttemptingTx ||
      isTxPending ||
      //insufficientLiquidityError ||
      //showSupplyCapError ||
      //gmFeeError ||
      //!!minGmTradeError ||
      (showClose && !bestZap)
    )
  }, [isGeoRestricted, isAttemptingTx, isTxPending, showClose, bestZap])

  return (
    <ModalInner>
      <Column>
        <Header
          strategy={strategy}
          totalYield={totalYield}
          showExpirationWarning={showExpirationWarning}
          expirationDate={expirationDate}
          partsFormatted={partsFormatted}
        />
        <YieldBreakdown
          collateralInterestRateParts={collateralInterestRateParts}
          collateralLendingYield={collateralLendingYield}
          collateralTotalYield={collateralTotalYield}
          debtInterestRate={debtInterestRate}
          totalYield={totalYield}
          debtToken={debtAsset}
          showYieldAsApr={showYieldAsApr}
          strategy={strategy}
          leverage={leverage}
        />
        <StrategyDescription showDescription={true} collateral={collateralSymbol} debt={debtSymbol} />
      </Column>
      <Column right>
        <StrategyStats>
          <EquityStats>
            <EquityTitle>
              Your Equity{' '}
              <StyledTooltipWithIcon
                tooltipText={`This is the approximate amount that you will receive after closing this strategy.`}
              />
            </EquityTitle>
            <EquityValue>
              {formatAmount(positionEquity, undefined, true, '-', false)} <span>{collateralSymbol}</span>
            </EquityValue>
            <EquityValueUsd>{formatAmount(positionEquityUsd, 2, true, '-', true)}</EquityValueUsd>
          </EquityStats>
          <BasicStats>
            <StatRow>
              Health:{' '}
              <HealthFactor health={positionHealthFactor}>
                <StyledTooltip
                  title={
                    <PositionHealthTooltip
                      positionHealth={
                        position.positionHealth
                          ? parseFloat(position.positionHealth.toFixed(2, undefined, Rounding.ROUND_DOWN))
                          : undefined
                      }
                      positionHealthFactor={positionHealthFactor}
                      t={t}
                      isNew={false}
                    />
                  }
                  placement='top'
                  arrow={true}
                >
                  <span>
                    {!position.positionHealth ? (
                      <InfinityWrapper expanded={true}>
                        <AllInclusiveIcon />
                      </InfinityWrapper>
                    ) : position.positionHealth.greaterThan('10') ? (
                      '>10'
                    ) : (
                      position.positionHealth.toFixed(2, undefined, Rounding.ROUND_DOWN)
                    )}
                  </span>
                </StyledTooltip>
              </HealthFactor>
            </StatRow>
            <StatRow>
              Opened:{' '}
              <StyledTooltip
                title={`${toDate(position.openTimestamp, true)}`}
                placement={'right'}
                style={{ cursor: 'pointer' }}
              >
                <>{toDate(position.openTimestamp)}</>
              </StyledTooltip>
            </StatRow>
            {position.expirationTimestamp && (
              <StatRow>
                Expiration:{' '}
                <StyledTooltip
                  title={`${toDate(position.openTimestamp, true)}`}
                  placement={'right'}
                  style={{ cursor: 'pointer' }}
                >
                  <>{toDate(position.expirationTimestamp)}</>
                </StyledTooltip>
              </StatRow>
            )}
          </BasicStats>
        </StrategyStats>
        <InterestRateStats>
          <InterestRateLeft>
            <CurrentYieldTitle>Current Yield</CurrentYieldTitle>
            <CurrentYield>
              {interestRate?.toFixed(2) ?? '-'}% <span>{showYieldAsApr ? 'APR' : 'APY'}</span>
            </CurrentYield>
          </InterestRateLeft>
          <InterestRateRight>
            <AvgYieldTitle>30 Day Avg.</AvgYieldTitle>
            <AvgYieldValue>
              21.54% <span>APR</span>
            </AvgYieldValue>
            <AvgYieldTitle>Avg. Since Open </AvgYieldTitle>
            <StyledTooltip
              title={
                'The APR since opening the position, based only on lending and borrowing interest, not including other rewards.'
              }
            >
              {/*<AvgYieldValue isNegative={profitApr && profitApr.lessThan(ZERO_FRACTION)}>
                {/* TODO - account for APY, confirm veracity */}
              {/*{profitApr?.toFixed(2) ?? '-'}% <span>APR</span>
              </AvgYieldValue>*/}
              <AvgYieldValue isNegative={false}>
                {'-'}% <span>APR</span>
              </AvgYieldValue>
            </StyledTooltip>
          </InterestRateRight>
        </InterestRateStats>
        <CurrentPosition>
          <AssetList>
            <AssetTitle small>Collateral</AssetTitle>
            {strategy.collateralAssets.map((asset: Token, i: number) => (
              <Asset key={i} small>
                <TokenLogo small>
                  <img
                    src={getLogoOrDefault(cleanCurrencySymbol(asset) ?? '')}
                    alt={`${cleanCurrencySymbol(asset)} logo`}
                  />
                </TokenLogo>
                {cleanCurrencySymbol(asset)}
                <TokenValue>
                  {/* TODO - only designed for a single collateral and debt asset */}
                  {formatAmount(position.supplyAmounts[0].amountTokenWei, undefined, true, '-', false)}
                </TokenValue>
              </Asset>
            ))}
          </AssetList>
          <AssetList rightModal>
            <AssetTitle right small>
              Debt
            </AssetTitle>
            {strategy.debtAssets.map((asset: Token, i: number) => {
              return (
                <Asset key={i} small>
                  <TokenValue right>
                    {formatAmount(position.borrowAmounts[0].amountTokenWei, undefined, true, '-', false)}
                  </TokenValue>
                  <TokenLogo small>
                    <img
                      src={getLogoOrDefault(cleanCurrencySymbol(asset) ?? '')}
                      alt={`${cleanCurrencySymbol(asset)} logo`}
                    />
                  </TokenLogo>
                  {cleanCurrencySymbol(asset)}
                </Asset>
              )
            })}
          </AssetList>
        </CurrentPosition>
        <DetailsWrapper isVisible={showClose}>
          <BorrowTradeDetails
            bestZap={bestZap}
            isLoading={!bestZap}
            refreshZapData={refreshZapData}
            showError={!!zapError}
            open={true}
            small={true}
            loadingDisabled={!bestZap}
            priceImpact={bestZap?.priceImpact}
          />
          {zapAmount && (
            <DescriptionTextWrapper>
              Will use {formatAmount(zapAmount, undefined, true, '')} {collateralSymbol} to repay {debtSymbol} debt. The
              remaining{' '}
              {formatAmount(position.supplyAmounts[0].amountTokenWei.subtract(zapAmount), undefined, true, '')}{' '}
              {collateralSymbol} and any excess {debtSymbol} will be returned to your Dolomite Balance.
            </DescriptionTextWrapper>
          )}
        </DetailsWrapper>
        <CloseWrapper>
          <CloseButton onClick={handleCloseButton} disabled={submitButtonDisabled}>
            {showClose ? 'Confirm Close' : 'Close Strategy and Withdraw'}
          </CloseButton>
        </CloseWrapper>
      </Column>
    </ModalInner>
  )
},
ManageStrategyModalComparator)

function ManageStrategyModalWrapper({
  isOpen,
  memoizedOnDismiss,
  position,
  strategy,
  totalYieldSelected,
  interestRateMap,
  positionTransfers,
}: ManageStrategyProps) {
  return (
    <Modal isOpen={isOpen} onDismiss={memoizedOnDismiss} maxHeight={90} maxWidthPx={800}>
      {!isOpen || !position ? (
        <></>
      ) : (
        <ManageStrategyModal
          isOpen={isOpen}
          memoizedOnDismiss={memoizedOnDismiss}
          position={position}
          strategy={strategy}
          totalYieldSelected={totalYieldSelected}
          interestRateMap={interestRateMap}
          positionTransfers={positionTransfers}
        />
      )}
    </Modal>
  )
}

export default ManageStrategyModalWrapper
