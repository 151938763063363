import { useMemo } from 'react'
import { BorrowPosition } from '../types/borrowPositionData'
import { Fraction, Percent, Token } from '@dolomite-exchange/sdk-core'
import { useInterestRateData } from '../types/interestRateData'
import { ChainId, ZERO_FRACTION, ZERO_PERCENT } from '../constants'
import { useFiatPricesWithLoadingIndicator } from './useFiatValue'
import { Currency, CurrencyAmount } from '@dolomite-exchange/v2-sdk'
import { createFraction } from '../types/gqlTypeHelpers'
import { NO_VARIABLES, useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { DOLOMITE_API_SERVER_URL } from '@dolomite-exchange/zap-sdk'
import { RefreshFrequency } from '../state/chain/hooks'
import { formatAmount } from '../utils/formatAmount'

const amountsToTokens = (amounts: CurrencyAmount<Currency>[]) => amounts.map(amount => amount.currency.wrapped)

interface InterestRate {
  supplyInterestRate: Percent
  borrowInterestRate: Percent
}

interface HistoricalInterestRate {
  token: {
    tokenAddress: string
  }
  historicalRates: {
    '30d': {
      supplyInterestRate: string
      borrowInterestRate: string
    }
  }
}
interface InterestRateResponse {
  interestRates: HistoricalInterestRate[]
}

export function useHistoricalInterestRates(tokens: Token[], chain: ChainId | undefined) {
  const queryState = useGraphqlResult<InterestRateResponse>(
    GraphqlClientType.Fetch,
    `${DOLOMITE_API_SERVER_URL}/tokens/${chain}/interest-rates`,
    NO_VARIABLES,
    RefreshFrequency.Medium,
  )

  const serverInterestRates = useMemo(() => {
    if (queryState.result) {
      return queryState.result.interestRates.reduce((memo, t) => {
        const supply = createFraction(t.historicalRates['30d'].supplyInterestRate)
        const borrow = createFraction(t.historicalRates['30d'].borrowInterestRate)
        memo[t?.token?.tokenAddress?.toLowerCase() ?? ''] = {
          supplyInterestRate: new Percent(supply.numerator, supply.denominator),
          borrowInterestRate: new Percent(borrow.numerator, borrow.denominator),
        }
        return memo
      }, {} as Record<string, InterestRate>)
    }
    return {}
  }, [queryState.result])

  return useMemo(() => {
    if (serverInterestRates && tokens) {
      return tokens.reduce((memo, t) => {
        const interestRate = serverInterestRates[t.address.toLowerCase()]
        if (serverInterestRates) {
          memo[t.address.toLowerCase()] = interestRate
        }
        return memo
      }, {} as Record<string, InterestRate | undefined>)
    }
    return {}
  }, [tokens, serverInterestRates])
}

interface InterestRateRecordResponse {
  supplyInterestRate: string
  borrowInterestRate: string
  timestamp: number
}

export interface InterestRateRecord {
  supplyInterestRate: Fraction
  borrowInterestRate: Fraction
  timestamp: Date
}

interface InterestRateSeriesResponse {
  interestRateSeries: InterestRateRecordResponse[]
}

export function useHistoricalInterestRateSeries(token: Token, chain: ChainId | undefined) {
  const queryState = useGraphqlResult<InterestRateSeriesResponse>(
    GraphqlClientType.Fetch,
    `${DOLOMITE_API_SERVER_URL}/tokens/${chain}/interest-rates/${token.address.toLowerCase()}/series`,
    NO_VARIABLES,
    RefreshFrequency.Medium,
  )

  const serverInterestRates = useMemo(() => {
    if (queryState.result) {
      return queryState.result.interestRateSeries.reduce((memo, t) => {
        const supplyRate = createFraction(t.supplyInterestRate)
        const borrowRate = createFraction(t.borrowInterestRate)
        const timestamp = new Date(t.timestamp * 1000)
        memo.push({
          supplyInterestRate: supplyRate.multiply(100), // new Percent(supplyRate.numerator, supplyRate.denominator),
          borrowInterestRate: borrowRate.multiply(100), // new Percent(borrowRate.numerator, borrowRate.denominator),
          timestamp: timestamp,
        })
        return memo
      }, [] as InterestRateRecord[])
    }
    return undefined
  }, [queryState.result])

  return useMemo(() => {
    if (serverInterestRates) {
      return serverInterestRates
    }
    return undefined
  }, [serverInterestRates])
}

export function useStrategyInterestRate(
  supplyAmounts: CurrencyAmount<Currency>[],
  borrowAmounts: CurrencyAmount<Currency>[],
  chain: ChainId | undefined,
  totalRate: boolean,
  historical?: boolean,
  includeParts?: string[] | undefined,
): Percent | undefined {
  const { data: interestRateMap } = useInterestRateData(chain)
  const tokens = amountsToTokens([...supplyAmounts, ...borrowAmounts])
  const historicalInterestRateMap = useHistoricalInterestRates(tokens, chain)
  const [fiatPrices] = useFiatPricesWithLoadingIndicator(tokens, chain)
  return useMemo(() => {
    if (!supplyAmounts || !borrowAmounts || !interestRateMap || !fiatPrices) {
      return undefined
    }
    const [netSupplyInterest, totalSupplyAmount] = supplyAmounts.reduce(
      (interestAndTotal, supplyAmount) => {
        const address = supplyAmount.currency.wrapped.address
        const price = fiatPrices[address]
        const amountUSD = price?.multiply(supplyAmount.asFraction) ?? ZERO_FRACTION
        const interestRate = interestRateMap[address]
        const parts = includeParts
        const noRewardsSupplyInterestRate =
          totalRate && interestRate && interestRate.outsideSupplyInterestRateParts
            ? interestRate.outsideSupplyInterestRateParts.reduce((prev: Percent, interestRatePart) => {
                return parts && parts.includes(interestRatePart.label)
                  ? prev.add(interestRatePart.interestRate ?? ZERO_PERCENT)
                  : ZERO_PERCENT
              }, interestRate.supplyInterestRate)
            : interestRate?.supplyInterestRate
        const currentRate = totalRate ? interestRate?.totalSupplyInterestRate : noRewardsSupplyInterestRate
        const historicalRate = historicalInterestRateMap[address.toLowerCase()]?.supplyInterestRate
        const finalInterestRate = !historical ? currentRate : historicalRate
        if (!finalInterestRate) {
          return interestAndTotal
        }
        return [interestAndTotal[0].add(amountUSD.multiply(finalInterestRate)), interestAndTotal[1].add(amountUSD)]
      },
      [ZERO_FRACTION, ZERO_FRACTION],
    )
    const [netBorrowInterest, totalBorrowAmount] = borrowAmounts.reduce(
      (interestAndTotal, borrowAmount) => {
        const address = borrowAmount.currency.wrapped.address
        const price = fiatPrices[address]
        const amountUSD = price?.multiply(borrowAmount.asFraction) ?? ZERO_FRACTION
        const currentRate = interestRateMap[address]?.totalBorrowInterestRate
        const historicalRate = historicalInterestRateMap[address.toLowerCase()]?.borrowInterestRate
        const interestRate = !historical ? currentRate : historicalRate
        if (!interestRate) {
          return interestAndTotal
        }
        return [interestAndTotal[0].add(amountUSD.multiply(interestRate)), interestAndTotal[1].add(amountUSD)]
      },
      [ZERO_FRACTION, ZERO_FRACTION],
    )
    return Percent.fromFraction(
      netSupplyInterest.subtract(netBorrowInterest).divide(totalSupplyAmount.subtract(totalBorrowAmount)),
    )
  }, [
    borrowAmounts,
    fiatPrices,
    historical,
    totalRate,
    historicalInterestRateMap,
    interestRateMap,
    supplyAmounts,
    includeParts,
  ])
}

export default function useNetInterestRate(position: BorrowPosition | undefined): Percent | undefined {
  const { data: interestRateMap } = useInterestRateData()
  return useMemo(() => {
    if (!position || !interestRateMap) {
      return undefined
    }
    const [netSupplyInterest, totalSupplyAmount] = position.supplyAmounts.reduce(
      (interestAndTotal, supplyAmount) => {
        const interestRate = interestRateMap[supplyAmount.token.address]?.totalSupplyInterestRate
        if (!interestRate) {
          return interestAndTotal
        }
        return [
          interestAndTotal[0].add(supplyAmount.amountUSD.multiply(interestRate)),
          interestAndTotal[1].add(supplyAmount.amountUSD),
        ]
      },
      [ZERO_FRACTION, ZERO_FRACTION],
    )
    const [netBorrowInterest, totalBorrowAmount] = position.borrowAmounts.reduce(
      (interestAndTotal, borrowAmount) => {
        const interestRate = interestRateMap[borrowAmount.token.address]?.totalBorrowInterestRate
        if (!interestRate) {
          return interestAndTotal
        }
        return [
          interestAndTotal[0].add(borrowAmount.amountUSD.multiply(interestRate)),
          interestAndTotal[1].add(borrowAmount.amountUSD),
        ]
      },
      [ZERO_FRACTION, ZERO_FRACTION],
    )
    return Percent.fromFraction(
      netSupplyInterest.subtract(netBorrowInterest).divide(totalSupplyAmount.subtract(totalBorrowAmount)),
    )
  }, [interestRateMap, position])
}
