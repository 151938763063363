import { BalanceCheckFlag, CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import { Web3CallbackState } from './useTradeCallback'
import { estimateGasAsync, SuccessfulContractCall, useActiveWeb3React } from './index'
import {
  useAsyncIsolationModeUserVaultContract,
  useGenericTraderProxyContract,
  useIsolationModeUserVaultContract,
} from './useContract'
import { useTransactionAdder } from '../state/transactions/hooks'
import { useMemo } from 'react'
import { MAX_UINT_256, NEGATIVE_TWO_UINT_256, USER_ERROR_CODES, USER_ERROR_MESSAGES } from '../constants'
import { calculateGasMargin } from '../utils'
import JSBI from 'jsbi'
import useTransactionDeadline from './useTransactionDeadline'
import { useDolomiteMarginTokenAddressToIdMap, useDolomiteMarginTokenIdMap } from './useDolomiteMarginProtocol'
import { useAllTokens } from './Tokens'
import useIsolationModeUserVaultAddressIfCreated from './useIsolationModeUserVaultAddressIfCreated'
import { SpecialAsset, useSpecialAsset } from '../constants/isolation/special-assets'
import cleanCurrencySymbol from '../utils/cleanCurrencySymbol'
import invariant from 'tiny-invariant'
import { ZapOutputParam } from './useGetZapParams'
import { useActiveDolomiteZapClient } from '../apollo/client'
import { BigNumber } from 'ethers'

export enum ExtraZapInfo {
  SwapDebt,
  AddCollateralAndZapInput,
  ZapAndRemoveZapOutput,
  ZapAndRemoveOutputToZero,
  SwapCollateral,
}

export enum ZapType {
  SwapDebt = 'SWAP_DEBT',
  SwapCollateral = 'SWAP_COLLATERAL',
  SwapAndRepay = 'SWAP_AND_REPAY',
  BorrowAndSwap = 'BORROW_AND_SWAP',
  Swap = 'SWAP',
}

const MIN_PLV_GLP_AMOUNT = '100000'

/**
 * Used by the smart contracts
 */
export enum ZapEventType {
  None = 0,
  OpenBorrowPosition = 1,
  MarginPosition = 2,
}

export enum ZapTransferType {
  /**
   * The entirety of your balance (before swap) should be transferred. Could be from source account or margin account
   */
  ALL_BALANCE,
  /**
   * All the outputted amount should be transferred. Can only be from the margin account
   */
  ALL_OUTPUT,
}

export interface ZapTransfer {
  amount: CurrencyAmount<Token>
  eventType: ZapEventType
  isToTradeAccount: boolean
  transferOpts: ZapTransferType | undefined
}

/**
 * Must be one or the other. Cannot be both, else throws
 */
export interface ZapEither {
  transfers: ZapTransfer[] | undefined
  zapType: ZapType | undefined
}

/**
 * @deprecated Use the new function
 */
export function useZapExactTokensForTokensOLD(
  tradeAccountNumber: JSBI | undefined,
  otherAccountNumber: JSBI | undefined,
  zaps: ZapOutputParam[] | undefined,
  specialAssetForBorrowPosition: SpecialAsset | undefined,
  extraZapInfo: ExtraZapInfo,
  isMaxSelected: boolean,
  eventType: ZapEventType,
  zapOnly: boolean,
  extraAmount?: CurrencyAmount<Token>,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account, chainId } = useActiveWeb3React()
  const genericTraderProxyContract = useGenericTraderProxyContract()
  const deadline = useTransactionDeadline()
  const addTransaction = useTransactionAdder()
  const tokenAddressToMarketIdMap = useDolomiteMarginTokenAddressToIdMap()
  const marketIdToTokenAddressMap = useDolomiteMarginTokenIdMap()
  const tokenMap = useAllTokens()
  const isolationModeVaultAddress = useMemo(() => {
    return specialAssetForBorrowPosition?.isolationModeInfo?.remapAccountAddress?.(account, chainId)
  }, [specialAssetForBorrowPosition, account, chainId])
  const isolationModeVaultContract = useIsolationModeUserVaultContract(isolationModeVaultAddress)
  const asyncIsolationModeVaultContract = useAsyncIsolationModeUserVaultContract(isolationModeVaultAddress)

  const inputToken = useMemo(() => {
    const inputMarketId = zaps?.[0]?.marketIdsPath[0]
    if (!inputMarketId) {
      return undefined
    }

    return tokenMap[marketIdToTokenAddressMap[inputMarketId.toFixed()]]
  }, [marketIdToTokenAddressMap, tokenMap, zaps])
  const inputSpecialAsset = useSpecialAsset(inputToken)
  const inputIsolationVaultAddress = useIsolationModeUserVaultAddressIfCreated(inputToken)
  const inputIsolationModeVaultContract = useIsolationModeUserVaultContract(inputIsolationVaultAddress)

  const outputToken = useMemo(() => {
    const zap = zaps?.[0]
    if (!zap) {
      return undefined
    }
    const outputMarketId = zap.marketIdsPath[zap.marketIdsPath.length - 1]
    return tokenMap[marketIdToTokenAddressMap[outputMarketId.toFixed()]]
  }, [marketIdToTokenAddressMap, tokenMap, zaps])
  const outputSpecialAsset = useSpecialAsset(outputToken)
  const outputIsolationVaultAddress = useIsolationModeUserVaultAddressIfCreated(outputToken)
  const outputIsolationModeVaultContract = useIsolationModeUserVaultContract(outputIsolationVaultAddress)
  const zapClient = useActiveDolomiteZapClient()

  return useMemo(() => {
    if (
      !genericTraderProxyContract ||
      !account ||
      !tradeAccountNumber ||
      !otherAccountNumber ||
      !inputToken ||
      !outputToken
    ) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    } else if (!zaps || zaps.length === 0) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'No zaps found',
      }
    } else if (inputSpecialAsset?.isIsolationMode && !inputIsolationVaultAddress) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Input isolation vault not yet created',
      }
    } else if (outputSpecialAsset?.isIsolationMode && !outputIsolationVaultAddress) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Output isolation vault not yet created',
      }
    } else if (inputToken.symbol === 'dplvGLP' && zaps[0].amountWeisPath[0].isLessThan(MIN_PLV_GLP_AMOUNT)) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Input amount is too small for plvGLP',
      }
    } else if (
      outputToken.symbol === 'dplvGLP' &&
      zaps[0].amountWeisPath[zaps[0].amountWeisPath.length - 1].isLessThan(MIN_PLV_GLP_AMOUNT)
    ) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Output amount is too small for plvGLP',
      }
    }

    let contract =
      isolationModeVaultContract ??
      inputIsolationModeVaultContract ??
      outputIsolationModeVaultContract ??
      genericTraderProxyContract

    let methodName: string
    let paramArrays: any[][]
    const marketIdIn = zaps[0]?.marketIdsPath?.[0]
    if (zapClient && zapClient.getIsAsyncAssetByMarketId(marketIdIn)) {
      methodName = 'initiateUnwrapping'
      paramArrays = getAsyncWithdrawContractCallParams(tradeAccountNumber, zaps, marketIdToTokenAddressMap)
      contract = asyncIsolationModeVaultContract!
    } else if (extraZapInfo === ExtraZapInfo.SwapDebt || extraZapInfo === ExtraZapInfo.SwapCollateral) {
      methodName = 'swapExactInputForOutput'
      paramArrays = getContractCallParams(tradeAccountNumber, zaps, undefined, isMaxSelected)
      paramArrays.forEach(params => {
        params.push({
          deadline: deadline?.toString() ?? '0',
          balanceCheckFlag:
            extraZapInfo === ExtraZapInfo.SwapCollateral ? BalanceCheckFlag.Both : BalanceCheckFlag.None,
          eventType: eventType,
        })
      })
    } else if (extraZapInfo === ExtraZapInfo.AddCollateralAndZapInput) {
      if (isolationModeVaultContract || inputIsolationModeVaultContract || outputIsolationVaultAddress) {
        methodName = 'addCollateralAndSwapExactInputForOutput'
        paramArrays = getContractCallParams(tradeAccountNumber, zaps, otherAccountNumber, isMaxSelected)
        paramArrays.forEach(params => {
          params.push({
            deadline: deadline?.toString() ?? '0',
            balanceCheckFlag: BalanceCheckFlag.Both,
            eventType: eventType,
          })
        })
      } else {
        methodName = 'swapExactInputForOutputAndModifyPosition'
        paramArrays = getContractCallParams(tradeAccountNumber, zaps, undefined, isMaxSelected)
        paramArrays.forEach((params, i) => {
          if (extraAmount) {
            params.push({
              fromAccountNumber: otherAccountNumber.toString(),
              toAccountNumber: tradeAccountNumber.toString(),
              transferAmounts: [
                extraAmount && tokenAddressToMarketIdMap[extraAmount.currency.address]
                  ? {
                      marketId: tokenAddressToMarketIdMap[extraAmount.currency.address]!.toString(),
                      amountWei: isMaxSelected ? MAX_UINT_256.toString() : extraAmount.quotient.toString(),
                    }
                  : undefined,
              ],
            })
          } else {
            params.push({
              fromAccountNumber: otherAccountNumber.toString(),
              toAccountNumber: tradeAccountNumber.toString(),
              transferAmounts: [
                {
                  marketId: zaps[i].marketIdsPath[0].toFixed(),
                  amountWei: isMaxSelected ? MAX_UINT_256.toString() : zaps[i].amountWeisPath[0].toFixed(),
                },
              ],
            })
          }
          params.push({
            marketId: zaps[i].marketIdsPath[0].toFixed(),
            expiryTimeDelta: 0,
          })
          params.push({
            deadline: deadline?.toString() ?? '0',
            balanceCheckFlag: BalanceCheckFlag.Both,
            eventType: eventType,
          })
        })
      }
    } else if (extraZapInfo === ExtraZapInfo.ZapAndRemoveZapOutput) {
      invariant(extraZapInfo === ExtraZapInfo.ZapAndRemoveZapOutput, 'Invalid extra zap info')
      if (isolationModeVaultContract || inputIsolationModeVaultContract || outputIsolationVaultAddress) {
        methodName = 'swapExactInputForOutputAndRemoveCollateral'
        paramArrays = getContractCallParams(tradeAccountNumber, zaps, otherAccountNumber, isMaxSelected)
        paramArrays.forEach(params => {
          params.push({
            deadline: deadline?.toString() ?? '0',
            balanceCheckFlag: BalanceCheckFlag.Both,
            eventType: eventType,
          })
        })
      } else {
        methodName = 'swapExactInputForOutputAndModifyPosition'
        paramArrays = getContractCallParams(tradeAccountNumber, zaps, undefined, isMaxSelected)
        paramArrays.forEach((params, i) => {
          const lastMarketId = zaps[i].marketIdsPath[zaps[i].marketIdsPath.length - 1].toFixed()
          params.push({
            fromAccountNumber: tradeAccountNumber.toString(),
            toAccountNumber: otherAccountNumber.toString(),
            transferAmounts: [
              {
                marketId: lastMarketId,
                amountWei: NEGATIVE_TWO_UINT_256.toString(),
              },
            ],
          })
          params.push({
            marketId: lastMarketId,
            expiryTimeDelta: 0,
          })
          params.push({
            deadline: deadline?.toString() ?? '0',
            balanceCheckFlag: BalanceCheckFlag.Both,
            eventType: eventType,
          })
        })
      }
    } else {
      invariant(extraZapInfo === ExtraZapInfo.ZapAndRemoveOutputToZero, 'Invalid extra zap info')
      if (isolationModeVaultContract || inputIsolationModeVaultContract || outputIsolationVaultAddress) {
        throw new Error('Unsupported for isolation mode vaults!')
        // methodName = 'swapExactInputForOutputAndRemoveCollateral'
        // paramArrays = getContractCallParams(tradeAccountNumber, zaps, otherAccountNumber, isMaxSelected)
        // paramArrays.forEach(params => {
        //   params.push({
        //     deadline: deadline?.toString() ?? '0',
        //     balanceCheckFlag: BalanceCheckFlag.Both,
        //     eventType: eventType,
        //   })
        // })
      } else {
        methodName = 'swapExactInputForOutputAndModifyPosition'
        paramArrays = getContractCallParams(tradeAccountNumber, zaps, undefined, isMaxSelected)
        paramArrays.forEach((params, i) => {
          const lastMarketId = zaps[i].marketIdsPath[zaps[i].marketIdsPath.length - 1].toFixed()
          params.push({
            fromAccountNumber: tradeAccountNumber.toString(),
            toAccountNumber: otherAccountNumber.toString(),
            transferAmounts: [
              {
                marketId: lastMarketId,
                amountWei: MAX_UINT_256.toString(),
              },
            ],
          })
          params.push({
            marketId: lastMarketId,
            expiryTimeDelta: 0,
          })
          params.push({
            deadline: deadline?.toString() ?? '0',
            balanceCheckFlag: BalanceCheckFlag.Both,
            eventType: eventType,
          })
        })
      }
    }

    return {
      state: Web3CallbackState.VALID,
      callback: async function onCallFunctions(): Promise<string> {
        let firstError: Error | undefined
        for (let i = 0; i < paramArrays.length; i++) {
          const options = { value: zaps[i].executionFee.times(1.5).toFixed() }
          const estimatedCall = await estimateGasAsync(contract, methodName, paramArrays[i], options)
          let successfulCall: SuccessfulContractCall
          if ('gasEstimate' in estimatedCall) {
            successfulCall = estimatedCall
          } else {
            if (!firstError) {
              firstError = estimatedCall.error
            }
            console.error(`Estimate gas for function at index ${i} failed:`, estimatedCall.error)
            continue
          }

          try {
            return await contract[methodName](...paramArrays[i], {
              ...options,
              gasLimit: calculateGasMargin(successfulCall.gasEstimate),
              from: account,
            }).then((response: any) => {
              let summary: string
              if (extraZapInfo === ExtraZapInfo.SwapDebt || zapOnly) {
                summary = `Zap ${cleanCurrencySymbol(inputToken)} for ${cleanCurrencySymbol(outputToken)}`
              } else if (extraZapInfo === ExtraZapInfo.AddCollateralAndZapInput) {
                summary = `Add ${cleanCurrencySymbol(inputToken)} collateral and zap for ${cleanCurrencySymbol(
                  outputToken,
                )}`
              } else if (extraZapInfo === ExtraZapInfo.SwapCollateral) {
                summary = `Swap ${cleanCurrencySymbol(inputToken)} collateral and zap for ${cleanCurrencySymbol(
                  outputToken,
                )}`
              } else {
                summary = `Zap ${cleanCurrencySymbol(inputToken)} for ${cleanCurrencySymbol(
                  outputToken,
                )} and remove collateral`
              }
              addTransaction(response, { summary })
              return response.hash
            })
          } catch (e) {
            const error = e as any
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              return Promise.reject(new Error(USER_ERROR_MESSAGES.REJECTED))
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error('Call function failed:', error, methodName, paramArrays)
              return Promise.reject(new Error(`Call function failed: ${error.message}`))
            }
          }
        }

        return Promise.reject(firstError)
      },
      error: null,
    }
  }, [
    genericTraderProxyContract,
    account,
    tradeAccountNumber,
    otherAccountNumber,
    inputToken,
    outputToken,
    zaps,
    inputSpecialAsset?.isIsolationMode,
    inputIsolationVaultAddress,
    outputSpecialAsset?.isIsolationMode,
    outputIsolationVaultAddress,
    isolationModeVaultContract,
    inputIsolationModeVaultContract,
    outputIsolationModeVaultContract,
    zapClient,
    extraZapInfo,
    marketIdToTokenAddressMap,
    tokenAddressToMarketIdMap,
    asyncIsolationModeVaultContract,
    isMaxSelected,
    deadline,
    eventType,
    extraAmount,
    zapOnly,
    addTransaction,
  ])
}

export function useZapExactTokensForTokens(
  tradeAccountNumber: JSBI | undefined,
  otherAccountNumber: JSBI | undefined,
  zaps: ZapOutputParam[] | undefined,
  either: ZapEither | undefined,
  isolationModeSpecialAsset: SpecialAsset | undefined,
  isMaxSelectedForZapInput: boolean,
  hideSummary?: boolean,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account, chainId } = useActiveWeb3React()
  const genericTraderProxyContract = useGenericTraderProxyContract()
  const deadline = useTransactionDeadline()
  const addTransaction = useTransactionAdder()
  const tokenAddressToMarketIdMap = useDolomiteMarginTokenAddressToIdMap()
  const marketIdToTokenAddressMap = useDolomiteMarginTokenIdMap()
  const tokenMap = useAllTokens()
  const isolationModeVaultAddress = useMemo(() => {
    return isolationModeSpecialAsset?.isolationModeInfo?.remapAccountAddress?.(account, chainId)
  }, [isolationModeSpecialAsset, account, chainId])
  const isolationModeVaultContract = useIsolationModeUserVaultContract(isolationModeVaultAddress)
  const asyncIsolationModeVaultContract = useAsyncIsolationModeUserVaultContract(isolationModeVaultAddress)

  const inputToken = useMemo(() => {
    const inputMarketId = zaps?.[0]?.marketIdsPath[0]
    if (!inputMarketId) {
      return undefined
    }

    return tokenMap[marketIdToTokenAddressMap[inputMarketId.toFixed()]]
  }, [marketIdToTokenAddressMap, tokenMap, zaps])
  const inputSpecialAsset = useSpecialAsset(inputToken)
  const inputIsolationVaultAddress = useIsolationModeUserVaultAddressIfCreated(inputToken)
  const inputIsolationModeVaultContract = useIsolationModeUserVaultContract(inputIsolationVaultAddress)

  const outputToken = useMemo(() => {
    const zap = zaps?.[0]
    if (!zap) {
      return undefined
    }
    const outputMarketId = zap.marketIdsPath[zap.marketIdsPath.length - 1]
    return tokenMap[marketIdToTokenAddressMap[outputMarketId.toFixed()]]
  }, [marketIdToTokenAddressMap, tokenMap, zaps])
  const outputSpecialAsset = useSpecialAsset(outputToken)
  const outputIsolationVaultAddress = useIsolationModeUserVaultAddressIfCreated(outputToken)
  const outputIsolationModeVaultContract = useIsolationModeUserVaultContract(outputIsolationVaultAddress)
  const zapClient = useActiveDolomiteZapClient()

  return useMemo(() => {
    if (
      !genericTraderProxyContract ||
      !account ||
      !tradeAccountNumber ||
      !otherAccountNumber ||
      !inputToken ||
      !outputToken ||
      !either
    ) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    } else if (!zaps || zaps.length === 0) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'No zaps found',
      }
    } else if (inputSpecialAsset?.isIsolationMode && !inputIsolationVaultAddress) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Input isolation vault not yet created',
      }
    } else if (outputSpecialAsset?.isIsolationMode && !outputIsolationVaultAddress) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Output isolation vault not yet created',
      }
    } else if (inputToken.symbol === 'dplvGLP' && zaps[0].amountWeisPath[0].isLessThan(MIN_PLV_GLP_AMOUNT)) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Input amount is too small for plvGLP',
      }
    } else if (
      outputToken.symbol === 'dplvGLP' &&
      zaps[0].amountWeisPath[zaps[0].amountWeisPath.length - 1].isLessThan(MIN_PLV_GLP_AMOUNT)
    ) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Output amount is too small for plvGLP',
      }
    }

    if (isolationModeSpecialAsset) {
      invariant(isolationModeSpecialAsset.isIsolationMode, 'special asset must be isolation mode or undefined')
    }

    const { transfers, zapType } = either
    let transferMarketIds: JSBI[] | undefined = undefined
    if (transfers !== undefined) {
      invariant(transfers.length > 0, '`either.transfers` length must be greater than 0')
      invariant(
        transfers.filter(t => t.eventType !== ZapEventType.None).length <= 1,
        'Invalid `either.transfers` array. There can only be one event emitted in the array. Set the rest to `ZapEventType.None`',
      )
      invariant(
        transfers.every(t => t.isToTradeAccount) || transfers.every(t => !t.isToTradeAccount),
        '`either.transfers` must all go into the trade account or out from it. You cannot mix-match it',
      )
      transferMarketIds = transfers
        .map(t => tokenAddressToMarketIdMap[t.amount.currency.address])
        .filter((m): m is JSBI => !!m)
      if (transferMarketIds.length !== transfers.length) {
        return {
          state: Web3CallbackState.INVALID,
          callback: null,
          error: 'tokenAddressToMarketIdMap is not populated yet',
        }
      }
    } else {
      invariant(zapType !== undefined, 'One of `zapType` or `transfers` must be defined in `either`')
    }

    let contract =
      isolationModeVaultContract ??
      inputIsolationModeVaultContract ??
      outputIsolationModeVaultContract ??
      genericTraderProxyContract

    let methodName: string
    let paramArrays: any[][]
    let summary: string
    const marketIdIn = zaps[0]?.marketIdsPath?.[0]
    if (zapClient && zapClient.getIsAsyncAssetByMarketId(marketIdIn)) {
      methodName = 'initiateUnwrapping'
      paramArrays = getAsyncWithdrawContractCallParams(tradeAccountNumber, zaps, marketIdToTokenAddressMap)
      contract = asyncIsolationModeVaultContract!
    } else if (isolationModeVaultAddress || inputIsolationVaultAddress || outputIsolationVaultAddress) {
      const result = getIsolationModeZapParams(
        tradeAccountNumber,
        otherAccountNumber,
        zaps,
        either,
        transferMarketIds,
        deadline,
        isMaxSelectedForZapInput,
        contract,
        isolationModeSpecialAsset!,
      )
      methodName = result.methodName
      paramArrays = result.paramArrays
      summary = result.summary
    } else {
      const result = getNonIsolationModeZapParams(
        tradeAccountNumber,
        otherAccountNumber,
        zaps,
        either,
        transferMarketIds,
        deadline,
        isMaxSelectedForZapInput,
      )
      methodName = result.methodName
      paramArrays = result.paramArrays
      summary = result.summary
    }

    return {
      state: Web3CallbackState.VALID,
      callback: async function onCallFunctions(): Promise<string> {
        let firstError: Error | undefined
        for (let i = 0; i < paramArrays.length; i++) {
          const options = zaps[i].executionFee.gt('0') ? { value: zaps[i].executionFee.times(1.5).toFixed() } : {}
          const estimatedCall = await estimateGasAsync(contract, methodName, paramArrays[i], options)
          let successfulCall: SuccessfulContractCall
          if ('gasEstimate' in estimatedCall) {
            successfulCall = estimatedCall
          } else {
            if (!firstError) {
              firstError = estimatedCall.error
            }
            console.error(`Estimate gas for function at index ${i} failed:`, estimatedCall.error)
            continue
          }

          try {
            return await contract[methodName](...paramArrays[i], {
              ...options,
              gasLimit: calculateGasMargin(successfulCall.gasEstimate),
              from: account,
            }).then((response: any) => {
              addTransaction(response, hideSummary ? undefined : { summary })
              return response.hash
            })
          } catch (e) {
            const error = e as any
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              return Promise.reject(new Error(USER_ERROR_MESSAGES.REJECTED))
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error('Call function failed:', error, methodName, paramArrays)
              return Promise.reject(new Error(`Call function failed: ${error.message}`))
            }
          }
        }

        return Promise.reject(firstError)
      },
      error: null,
    }
  }, [
    genericTraderProxyContract,
    account,
    tradeAccountNumber,
    otherAccountNumber,
    inputToken,
    outputToken,
    either,
    zaps,
    inputSpecialAsset?.isIsolationMode,
    inputIsolationVaultAddress,
    outputSpecialAsset?.isIsolationMode,
    outputIsolationVaultAddress,
    isolationModeSpecialAsset,
    isolationModeVaultContract,
    inputIsolationModeVaultContract,
    outputIsolationModeVaultContract,
    zapClient,
    isolationModeVaultAddress,
    tokenAddressToMarketIdMap,
    marketIdToTokenAddressMap,
    asyncIsolationModeVaultContract,
    deadline,
    isMaxSelectedForZapInput,
    addTransaction,
    hideSummary,
  ])
}

function getIsolationModeZapParams(
  tradeAccountNumber: JSBI,
  otherAccountNumber: JSBI,
  zaps: ZapOutputParam[],
  either: ZapEither,
  transferMarketIds: JSBI[] | undefined,
  deadline: BigNumber | undefined,
  isMaxSelectedForZapInput: boolean,
  isolationModeContract: any,
  specialAsset: SpecialAsset,
): { methodName: string; paramArrays: any[]; summary: string } {
  let methodName: string
  let paramArrays: any[][]
  let summary: string

  const inputToken = zaps[0].tokensPath[0]
  const outputToken = zaps[0].tokensPath[zaps[0].tokensPath.length - 1]
  const isolationModeToken = specialAsset.isolationModeInfo!.getWrappedToken(inputToken.chainId)!
  const transfers = either.transfers
  if (transfers) {
    invariant(transfers.length === 1, '`transfers.length` should eq 1')
    invariant(!!transferMarketIds, '`transferMarketIds` is not defined')
    const isDeposit = transfers[0].isToTradeAccount
    const userConfig = {
      deadline: deadline?.toString() ?? '0',
      balanceCheckFlag: isDeposit ? BalanceCheckFlag.None : BalanceCheckFlag.Both,
      eventType: transfers.find(t => t.eventType !== ZapEventType.None)?.eventType ?? ZapEventType.None,
    }
    const isOpenPosition = userConfig.eventType === ZapEventType.OpenBorrowPosition
    if (
      userConfig.eventType === ZapEventType.OpenBorrowPosition ||
      userConfig.eventType === ZapEventType.MarginPosition
    ) {
      invariant(isDeposit, 'Can only transfer into the position if opening a Borrow or Margin position')
    }

    if (!isOpenPosition && isDeposit && transfers[0].amount.currency.equals(inputToken)) {
      methodName = 'addCollateralAndSwapExactInputForOutput'
      paramArrays = getContractCallParams(tradeAccountNumber, zaps, otherAccountNumber, isMaxSelectedForZapInput)
      paramArrays.forEach(params => {
        params.push(userConfig)
      })
    } else if (!isOpenPosition && !isDeposit && transfers[0].amount.currency.equals(outputToken)) {
      methodName = 'swapExactInputForOutputAndRemoveCollateral'
      paramArrays = getContractCallParams(tradeAccountNumber, zaps, otherAccountNumber, isMaxSelectedForZapInput)
      paramArrays.forEach(params => {
        params.push(userConfig)
      })
    } else {
      methodName = 'multicall'
      paramArrays = getContractCallParams(tradeAccountNumber, zaps, undefined, isMaxSelectedForZapInput)
      const swapFunctionName = 'swapExactInputForOutput'
      const allMethodNames = [swapFunctionName]
      let transferParams: any[]
      if (isDeposit) {
        if (transfers[0].amount.currency.equals(isolationModeToken)) {
          allMethodNames.unshift(isOpenPosition ? 'openBorrowPosition' : 'transferIntoPositionWithUnderlyingToken')
          transferParams = [
            otherAccountNumber.toString(),
            tradeAccountNumber.toString(),
            transfers[0].amount.quotient.toString(),
          ]
        } else {
          allMethodNames.unshift('transferIntoPositionWithOtherToken')
          transferParams = [
            otherAccountNumber.toString(),
            tradeAccountNumber.toString(),
            transferMarketIds[0].toString(),
            transfers[0].amount.quotient.toString(),
            BalanceCheckFlag.Both,
          ]
        }
      } else {
        if (transfers[0].amount.currency.equals(isolationModeToken)) {
          allMethodNames.push('transferFromPositionWithUnderlyingToken')
          transferParams = [
            tradeAccountNumber.toString(),
            otherAccountNumber.toString(),
            transfers[0].amount.quotient.toString(),
          ]
        } else {
          allMethodNames.push('transferFromPositionWithOtherToken')
          transferParams = [
            tradeAccountNumber.toString(),
            otherAccountNumber.toString()!,
            transferMarketIds[0].toString(),
            transfers[0].amount.quotient.toString(),
            BalanceCheckFlag.Both,
          ]
        }
      }
      paramArrays.forEach(params => {
        params.push(userConfig)
      })

      paramArrays = paramArrays.map(zapParams => {
        return allMethodNames.map(methodName => {
          if (methodName === swapFunctionName) {
            return isolationModeContract.interface.encodeFunctionData(methodName, zapParams)
          } else {
            return isolationModeContract.interface.encodeFunctionData(methodName, transferParams)
          }
        })
      })
    }

    if (userConfig.eventType === ZapEventType.MarginPosition) {
      summary = `Open ${cleanCurrencySymbol(outputToken)} margin position`
    } else if (userConfig.eventType === ZapEventType.OpenBorrowPosition) {
      summary = `Open ${cleanCurrencySymbol(outputToken)} borrow position`
    } else {
      if (isDeposit) {
        summary = `Zap ${cleanCurrencySymbol(inputToken)} to ${cleanCurrencySymbol(outputToken)} and add to position`
      } else {
        summary = `Zap ${cleanCurrencySymbol(inputToken)} to ${cleanCurrencySymbol(
          outputToken,
        )} and remove from position`
      }
    }
  } else {
    const zapType = either.zapType!
    const userConfig = {
      deadline: deadline?.toString() ?? '0',
      balanceCheckFlag:
        zapType === ZapType.SwapDebt || zapType === ZapType.BorrowAndSwap
          ? BalanceCheckFlag.None
          : BalanceCheckFlag.Both,
      eventType: ZapEventType.None,
    }

    if (zapType === ZapType.Swap) {
      methodName = inputToken.equals(isolationModeToken)
        ? 'swapExactInputForOutputAndRemoveCollateral'
        : 'addCollateralAndSwapExactInputForOutput'
      paramArrays = getContractCallParams(tradeAccountNumber, zaps, otherAccountNumber, isMaxSelectedForZapInput)
    } else {
      methodName = 'swapExactInputForOutput'
      paramArrays = getContractCallParams(tradeAccountNumber, zaps, undefined, isMaxSelectedForZapInput)
    }

    paramArrays.forEach(params => {
      params.push(userConfig)
    })

    if (zapType === ZapType.Swap) {
      summary = `Swap ${cleanCurrencySymbol(inputToken)} for ${cleanCurrencySymbol(outputToken)}`
    } else if (zapType === ZapType.SwapDebt) {
      summary = `Swap ${cleanCurrencySymbol(inputToken)} debt for ${cleanCurrencySymbol(outputToken)}`
    } else {
      invariant(zapType === ZapType.SwapCollateral, `Invalid zapType, found ${zapType}`)
      summary = `Swap ${cleanCurrencySymbol(inputToken)} collateral for ${cleanCurrencySymbol(outputToken)}`
    }
  }

  return {
    methodName,
    paramArrays,
    summary,
  }
}

function getNonIsolationModeZapParams(
  tradeAccountNumber: JSBI,
  otherAccountNumber: JSBI,
  zaps: ZapOutputParam[],
  either: ZapEither,
  transferMarketIds: JSBI[] | undefined,
  deadline: BigNumber | undefined,
  isMaxSelectedForZapInput: boolean,
): { methodName: string; paramArrays: any[]; summary: string } {
  let methodName: string
  const paramArrays = getContractCallParams(tradeAccountNumber, zaps, undefined, isMaxSelectedForZapInput)
  let summary: string

  const inputToken = zaps[0].tokensPath[0]
  const outputToken = zaps[0].tokensPath[zaps[0].tokensPath.length - 1]
  const transfers = either.transfers
  if (transfers) {
    invariant(!!transferMarketIds, '`transferMarketIds` is not defined')
    methodName = 'swapExactInputForOutputAndModifyPosition'
    const isDeposit = transfers[0].isToTradeAccount
    const transferParams = {
      fromAccountNumber: isDeposit ? otherAccountNumber.toString() : tradeAccountNumber.toString(),
      toAccountNumber: isDeposit ? tradeAccountNumber.toString() : otherAccountNumber.toString(),
      transferAmounts: transfers.map((t, i) => ({
        marketId: transferMarketIds![i].toString(),
        amountWei:
          t.transferOpts === ZapTransferType.ALL_BALANCE
            ? MAX_UINT_256.toString()
            : t.transferOpts === ZapTransferType.ALL_OUTPUT
            ? NEGATIVE_TWO_UINT_256.toString()
            : t.amount.quotient.toString(),
      })),
    }
    const expiry = {
      marketId: 0,
      expiryTimeDelta: 0,
    }
    const userConfig = {
      deadline: deadline?.toString() ?? '0',
      balanceCheckFlag: BalanceCheckFlag.OtherAccount, // only check the funding account
      eventType: transfers.find(t => t.eventType !== ZapEventType.None)?.eventType ?? ZapEventType.None,
    }
    paramArrays.forEach(params => {
      params.push(transferParams, expiry, userConfig)
    })

    if (userConfig.eventType === ZapEventType.MarginPosition) {
      summary = `Open ${cleanCurrencySymbol(outputToken)} margin position`
    } else if (userConfig.eventType === ZapEventType.OpenBorrowPosition) {
      summary = `Open ${cleanCurrencySymbol(outputToken)} borrow position`
    } else {
      if (isDeposit) {
        summary = `Zap ${cleanCurrencySymbol(inputToken)} to ${cleanCurrencySymbol(outputToken)} and add to position`
      } else {
        summary = `Zap ${cleanCurrencySymbol(inputToken)} to ${cleanCurrencySymbol(
          outputToken,
        )} and remove from position`
      }
    }
  } else {
    const zapType = either.zapType!
    methodName = 'swapExactInputForOutput'
    const userConfig = {
      deadline: deadline?.toString() ?? '0',
      balanceCheckFlag:
        zapType === ZapType.SwapDebt || zapType === ZapType.BorrowAndSwap
          ? BalanceCheckFlag.None
          : BalanceCheckFlag.Both,
      eventType: ZapEventType.None,
    }
    paramArrays.forEach(params => {
      params.push(userConfig)
    })

    if (zapType === ZapType.Swap) {
      summary = `Swap ${cleanCurrencySymbol(inputToken)} for ${cleanCurrencySymbol(outputToken)}`
    } else if (zapType === ZapType.SwapDebt) {
      summary = `Zap ${cleanCurrencySymbol(inputToken)} debt for ${cleanCurrencySymbol(outputToken)}`
    } else if (zapType === ZapType.BorrowAndSwap) {
      summary = `Borrow and zap ${cleanCurrencySymbol(inputToken)} debt for ${cleanCurrencySymbol(outputToken)}`
    } else if (zapType === ZapType.SwapAndRepay) {
      summary = `Zap ${cleanCurrencySymbol(inputToken)} collateral and repay ${cleanCurrencySymbol(outputToken)}`
    } else {
      invariant(zapType === ZapType.SwapCollateral, `Invalid zapType, found ${zapType}`)
      summary = `Swap ${cleanCurrencySymbol(inputToken)} collateral for ${cleanCurrencySymbol(outputToken)}`
    }
  }

  return {
    methodName,
    paramArrays,
    summary,
  }
}

function getContractCallParams(
  tradeAccountNumber: JSBI,
  zaps: ZapOutputParam[],
  otherAccountNumber: string | JSBI | undefined,
  isMaxSelected: boolean,
): any[][] {
  return zaps.map<any[]>(zap => {
    const otherAccountArray = otherAccountNumber ? [otherAccountNumber.toString()] : []
    return [
      ...otherAccountArray,
      tradeAccountNumber.toString(),
      zap.marketIdsPath.map(marketId => marketId.toFixed()),
      isMaxSelected ? MAX_UINT_256.toString() : zap.amountWeisPath[0].toFixed(),
      zap.amountWeisPath[zap.amountWeisPath.length - 1].toFixed(),
      zap.traderParams.map(trader => ({
        traderType: trader.traderType,
        makerAccountIndex: trader.makerAccountIndex,
        trader: trader.trader,
        tradeData: trader.tradeData,
      })),
      zap.makerAccounts,
    ]
  })
}

function getAsyncWithdrawContractCallParams(
  tradeAccountNumber: JSBI,
  zaps: ZapOutputParam[],
  marketIdToTokenMap: Record<string, string | undefined>,
): any[][] {
  return zaps.map<any[]>(zap => {
    const outputToken = marketIdToTokenMap[zap.marketIdsPath[1].toFixed()]
    invariant(zap.amountWeisPath.length === 2, 'Invalid amountWeisPath length')
    invariant(zap.traderParams.length === 1, 'Invalid traderParams length')
    invariant(!!outputToken, `Could not find output token for marketId [${zap.marketIdsPath[1].toFixed()}]`)

    return [
      tradeAccountNumber.toString(),
      zap.amountWeisPath[0].toFixed(),
      outputToken,
      zap.amountWeisPath[1].toFixed(),
      zap.traderParams.map(trader => ({
        traderType: trader.traderType,
        makerAccountIndex: trader.makerAccountIndex,
        trader: trader.trader,
        tradeData: trader.tradeData,
      }))[0].tradeData,
    ]
  })
}
