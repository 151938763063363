import { LiquidityMiningVestingPosition } from '../../types/liquidityMiningVestingData'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useArbToken, useWethToken } from '../../hooks/Tokens'
import { useDefaultFiatValuesWithLoadingIndicator } from '../../hooks/useFiatValue'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { CurrencyAmount } from '@dolomite-exchange/v2-sdk'
import { ONE_ETH_IN_WEI } from '../../constants'
import {
  LIQUIDITY_MINING_LEVEL_THRESHOLD,
  REWARDS_DEPOSIT_THRESHOLD,
  useEffectiveLevelForUser,
  useExecuteVest,
  useHasPendingLevelUpdateRequest,
  useInitiateLevelRequest,
  useIsPositionGrandfatherable,
} from '../../hooks/useLiquidityMining'
import { useIsTransactionPending } from '../../state/transactions/hooks'
import Modal from '../../components/Modal'
import OARBLogo from '../../assets/svg/oARB.svg'
import ETHLogo from '../../assets/markets/ETH.svg'
import { formatAmount } from '../../utils/formatAmount'
import ARBLogo from '../../assets/markets/ARB.svg'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components/macro'
import { useDolomiteBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { StyledTooltipWithIcon } from '../../components/common/StyledTooltip'
import { useGalxeAddressData } from '../../types/galxeAddressData'
import { Fraction } from '@dolomite-exchange/sdk-core'

const NftLogo = styled.div`
  height: 48px;
  margin: -6px auto -4px;

  img,
  svg {
    width: auto;
    height: 100%;
  }
`

const Logo = styled.div`
  height: 35px;
  margin: 0 auto 4px;

  img,
  svg {
    width: auto;
    height: 100%;
  }
`

const Amount = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
`

const SubAmount = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
`

const PlusSign = styled.div`
  display: inline-block;
  vertical-align: top;
  width: fit-content;
  font-size: 32px;
  color: ${({ theme }) => theme.text2};
  font-weight: 200;
`

const TradeDetails = styled.div`
  width: 100%;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.bg6};
  padding: 12px 16px 8px;
`

const TradeTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.text1};
  margin-bottom: 10px;
`

const TradeContent = styled.div`
  width: 100%;
`

const TradeRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.text2};
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
`

const Ticker = styled.span`
  color: ${({ theme }) => theme.text3};
`

const TradeSubRow = styled.div`
  width: 100%;
  font-size: 11px;
  color: ${({ theme }) => theme.text3};
  font-weight: 400;
  margin-top: -8px;
  margin-bottom: 8px;
`

const TradeSubRowCentered = styled(TradeSubRow)`
  text-align: center;
`

const TradeTotalRow = styled(TradeRow)`
  font-weight: 600;
  font-size: 16px;
`

const UserEthBalance = styled(TradeSubRow)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;

  > div > svg {
    transform: translate(-5px, 2px);
    height: 12px;
  }
`

const UserEthBalanceAmount = styled.div<{ isTooLow: boolean }>`
  color: ${({ theme, isTooLow }) => (isTooLow ? theme.red1 : theme.text3)};
`

const InsufficientEthError = styled.div`
  width: 90%;
  margin: 10px auto 0;
  font-size: 11px;
  text-align: center;
  color: ${({ theme }) => theme.red1};
`

const PostLevelExplainer = styled.div`
  color: ${({ theme }) => theme.blue2};
  font-size: 11px;
  margin-top: 10px;
  margin-bottom: -10px;
`

const ExecuteButton = styled.div<{ isActive: boolean }>`
  width: 100%;
  height: 38px;
  border-radius: 5px;
  background-color: ${({ theme, isActive }) => (isActive ? theme.green2 : theme.bg3)};
  color: ${({ theme }) => theme.text1};
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  padding: 8px 20px;
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'default')};
  margin-top: 15px;
  text-align: center;

  :hover {
    background-color: ${({ theme, isActive }) => (isActive ? theme.green1 : theme.bg3)};
  }

  > svg,
  > div {
    color: ${({ theme }) => theme.text1} !important;
    font-size: 20px;
    height: 20px !important;
    width: 20px !important;
  }

  @media screen and (max-width: 650px) {
    margin-bottom: 20px;
  }
`

const PostButton = styled(ExecuteButton)`
  background-color: ${({ theme, isActive }) => (isActive ? theme.blue1 : theme.bg3)};

  :hover {
    background-color: ${({ theme, isActive }) => (isActive ? theme.blue2 : theme.bg3)};
  }
`

const ModalInner = styled.div`
  padding: 24px 32px;
  width: 100%;
`

const ModalTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.text1};
  margin-bottom: 15px;
`

const ModalSubtitle = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.text3};
  margin-bottom: 12px;
`

const ModalRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`

const ModalRowSection = styled.div`
  text-align: center;
  width: 35%;
`

const Left = styled(ModalRowSection)``

const Right = styled(ModalRowSection)``

interface VestingExecuteModalProps {
  vestingPosition: LiquidityMiningVestingPosition | undefined
  totalDeposited: Fraction | undefined
  setShowExecuteModal: React.Dispatch<React.SetStateAction<LiquidityMiningVestingPosition | undefined>>
}

function VestingExecuteModalComparator(prevProps: VestingExecuteModalProps, nextProps: VestingExecuteModalProps) {
  return prevProps.vestingPosition?.id === nextProps.vestingPosition?.id
}

const VestingExecuteModal = ({ vestingPosition, totalDeposited, setShowExecuteModal }: VestingExecuteModalProps) => {
  const { account } = useActiveWeb3React()
  const { levelData } = useGalxeAddressData(account)
  const { level } = levelData
  const [isExecuting, setIsExecuting] = React.useState(false)
  const [awaitingSignature, setAwaitingSignature] = useState(false)
  const [pendingHash, setPendingHash] = useState<string | undefined>(undefined)
  const [pendingLevelUpdateHash, setPendingLevelUpdateHash] = useState<string | undefined>(undefined)
  const [showInsufficientEthError, setShowInsufficientEthError] = useState(false)
  const ethToken = useWethToken()
  const arbToken = useArbToken()
  const ethBalance = useDolomiteBalance(account, ethToken)?.asFraction
  const tokens = useMemo(() => {
    if (!ethToken || !arbToken) {
      return []
    } else {
      return [ethToken, arbToken]
    }
  }, [ethToken, arbToken])
  const [priceMap] = useDefaultFiatValuesWithLoadingIndicator(tokens)
  const userLevelOnChain = useEffectiveLevelForUser()
  const hasPendingLevelUpdate = useHasPendingLevelUpdateRequest()
  const hidePostLevel = useIsPositionGrandfatherable(vestingPosition)
  const userMustPostBalance =
    totalDeposited?.greaterThanOrEqual(REWARDS_DEPOSIT_THRESHOLD) && (!userLevelOnChain || userLevelOnChain < 4)
  const userMustPostLevel = level >= LIQUIDITY_MINING_LEVEL_THRESHOLD && (!userLevelOnChain || userLevelOnChain < level)
  const showPendingLevelSpinner = !!pendingLevelUpdateHash || !!hasPendingLevelUpdate || awaitingSignature

  const ethValueOfPosition = useMemo(() => {
    const arbPriceUsd = priceMap[arbToken?.address ?? '']
    const ethPriceUsd = priceMap[ethToken.address ?? '']
    if (!arbPriceUsd || !ethPriceUsd) {
      return undefined
    }

    return vestingPosition?.oTokenAmount.asFraction.multiply(arbPriceUsd).divide(ethPriceUsd)
  }, [arbToken, ethToken, priceMap, vestingPosition])

  const discountEthAmount = useMemo(() => {
    if (!vestingPosition) {
      return undefined
    }

    return ethValueOfPosition?.multiply(vestingPosition.discountPercentage)
  }, [ethValueOfPosition, vestingPosition])

  const expectedEthPaymentAmount = useMemo(() => {
    if (!discountEthAmount || !ethValueOfPosition) {
      return undefined
    }

    return ethValueOfPosition.subtract(discountEthAmount)
  }, [discountEthAmount, ethValueOfPosition])

  const isBalanceSufficient = useMemo(() => {
    if (!expectedEthPaymentAmount || !ethBalance) {
      return false
    }
    return expectedEthPaymentAmount.lessThanOrEqual(ethBalance)
  }, [ethBalance, expectedEthPaymentAmount])

  const expectedEthPaymentAmountUsd = useMemo(() => {
    const ethPriceUsd = priceMap[ethToken.address ?? '']
    if (!expectedEthPaymentAmount || !ethPriceUsd) {
      return undefined
    }

    return expectedEthPaymentAmount.multiply(ethPriceUsd)
  }, [ethToken.address, expectedEthPaymentAmount, priceMap])

  const [slippageTolerance] = useUserSlippageTolerance()
  const maxEthPaymentAmount = useMemo(() => {
    if (!expectedEthPaymentAmount) {
      return undefined
    }
    const max = expectedEthPaymentAmount.add(expectedEthPaymentAmount.multiply(slippageTolerance).divide(10_000))
    return CurrencyAmount.fromRawAmount(ethToken, max.multiply(ONE_ETH_IN_WEI).quotient)
  }, [ethToken, expectedEthPaymentAmount, slippageTolerance])
  const { callback: executeVest } = useExecuteVest(vestingPosition?.nftId, maxEthPaymentAmount)
  const { callback: postLevel } = useInitiateLevelRequest()

  const isHashPending = useIsTransactionPending(pendingHash)
  useEffect(() => {
    if (!isHashPending) {
      setPendingHash(undefined)
      setIsExecuting(prevState => {
        if (prevState) {
          setShowExecuteModal(undefined)
        }
        return false
      })
    }
  }, [isHashPending, setShowExecuteModal])

  const onPostLevel = useCallback(() => {
    if (!postLevel || awaitingSignature) {
      return
    }

    setAwaitingSignature(true)
    postLevel()
      .then(hash => {
        setPendingLevelUpdateHash(hash)
        setAwaitingSignature(false)
      })
      .catch(e => {
        setAwaitingSignature(false)
      })
  }, [awaitingSignature, postLevel])

  const onExecute = useCallback(() => {
    if (!executeVest || awaitingSignature) {
      return
    }

    setIsExecuting(true)
    setAwaitingSignature(true)
    executeVest()
      .then(hash => {
        setPendingHash(hash)
        setAwaitingSignature(false)
      })
      .catch(e => {
        const errorMessage = e.message.toLowerCase()
        console.log(errorMessage)
        if (errorMessage.includes('your balance will go negative')) {
          setShowInsufficientEthError(true)
        }
        setAwaitingSignature(false)
        setIsExecuting(false)
      })
  }, [awaitingSignature, executeVest])

  const onDismiss = useCallback(() => {
    setShowExecuteModal(undefined)
  }, [setShowExecuteModal])

  const insufficientBalance =
    ethBalance && expectedEthPaymentAmount ? ethBalance.lessThan(expectedEthPaymentAmount) : false

  return (
    <Modal isOpen={vestingPosition !== undefined} onDismiss={onDismiss}>
      {vestingPosition && (
        <ModalInner>
          <ModalTitle>Execution Summary</ModalTitle>
          <ModalSubtitle>Send</ModalSubtitle>
          <ModalRow>
            <Left>
              <NftLogo>
                <img src={OARBLogo} alt={'oARB logo'} />
              </NftLogo>
              <Amount>Vesting NFT</Amount>
              <SubAmount>ID {vestingPosition.nftId}</SubAmount>
            </Left>
            <PlusSign>+</PlusSign>
            <Right>
              <Logo>
                <img src={ETHLogo} alt={'ETH logo'} />
              </Logo>
              <Amount>{formatAmount(expectedEthPaymentAmount)} ETH</Amount>
              <SubAmount>Approx. {formatAmount(expectedEthPaymentAmountUsd, 2, true, '-', true)}</SubAmount>
            </Right>
          </ModalRow>
          <ModalSubtitle>Receive</ModalSubtitle>
          <ModalRow>
            <Left>
              <Logo>
                <img src={ARBLogo} alt={'ARB logo'} />
              </Logo>
              <Amount>{formatAmount(vestingPosition.oTokenAmount, 2, true)} ARB</Amount>
              <SubAmount>From your lock</SubAmount>
            </Left>
            <PlusSign>+</PlusSign>
            <Right>
              <Logo>
                <img src={ARBLogo} alt={'ARB logo'} />
              </Logo>
              <Amount>{formatAmount(vestingPosition.oTokenAmount, 2, true)} ARB</Amount>
              <SubAmount>Converted from oARB</SubAmount>
            </Right>
          </ModalRow>
          <TradeDetails>
            <TradeTitle>Execution Details</TradeTitle>
            <TradeContent>
              <TradeRow>
                <div>{formatAmount(vestingPosition.oTokenAmount, 2, true)} ARB market price</div>
                <div>
                  {formatAmount(ethValueOfPosition)} <Ticker>ETH</Ticker>
                </div>
              </TradeRow>
              <TradeRow>
                <div>{formatAmount(vestingPosition.discountPercentage)} Discount</div>
                <div>
                  -{formatAmount(discountEthAmount)} <Ticker>ETH</Ticker>
                </div>
              </TradeRow>
              <TradeSubRow>From {vestingPosition.durationWeeks} week vest</TradeSubRow>
              <TradeTotalRow>
                <div>Total ETH</div>
                <div>
                  {formatAmount(expectedEthPaymentAmount)} <Ticker>ETH</Ticker>
                </div>
              </TradeTotalRow>
              <UserEthBalance>
                <div>
                  Your ETH Balance{' '}
                  <StyledTooltipWithIcon tooltipText={'Your current available ETH balance deposited to Dolomite'} />
                </div>
                <UserEthBalanceAmount isTooLow={insufficientBalance}>
                  {formatAmount(ethBalance)} <Ticker>ETH</Ticker>
                </UserEthBalanceAmount>
              </UserEthBalance>
              <TradeSubRowCentered>
                Will spend at most {formatAmount(maxEthPaymentAmount)} ETH to get{' '}
                {formatAmount(vestingPosition.oTokenAmount, 2, true)} ARB,
              </TradeSubRowCentered>
              <TradeSubRowCentered>any unspent ETH will go back to your Dolomite Balance</TradeSubRowCentered>
            </TradeContent>
          </TradeDetails>
          {showInsufficientEthError && (
            <InsufficientEthError>
              Transaction failed due to insufficient ETH balance. Please deposit ETH to your Dolomite account.
            </InsufficientEthError>
          )}
          {!hidePostLevel && userMustPostBalance ? (
            <PostLevelExplainer>
              In order to receive the faster vesting that your balance allows for, before executing you must submit a
              transaction that confirms your balance on-chain so it can be read by the rewards contract.
            </PostLevelExplainer>
          ) : (
            userMustPostLevel && (
              <PostLevelExplainer>
                In order to receive the faster vesting that your level allows for, before executing you must submit a
                transaction that posts your level on-chain so it can be read by the rewards contract.
              </PostLevelExplainer>
            )
          )}
          {!hidePostLevel && userMustPostBalance ? (
            <PostButton onClick={() => !showPendingLevelSpinner && onPostLevel()} isActive={true}>
              {showPendingLevelSpinner ? <CircularProgress /> : <>Confirm Balance On-Chain</>}
            </PostButton>
          ) : userMustPostLevel ? (
            <PostButton onClick={() => !showPendingLevelSpinner && onPostLevel()} isActive={true}>
              {showPendingLevelSpinner ? <CircularProgress /> : <>Post Level On-Chain</>}
            </PostButton>
          ) : (
            <ExecuteButton
              onClick={() => !isExecuting && !insufficientBalance && onExecute()}
              isActive={!insufficientBalance}
            >
              {isExecuting ? <CircularProgress /> : insufficientBalance ? <>Insufficient ETH Balance</> : <>Execute</>}
            </ExecuteButton>
          )}
        </ModalInner>
      )}
    </Modal>
  )
}

export default React.memo(VestingExecuteModal, VestingExecuteModalComparator)
