import React, { useMemo } from 'react'
import styled from 'styled-components/macro'
import { PanelSubtitle, PanelTitle } from './index'
import { StyledTooltip, StyledTooltipWithIcon } from '../../components/common/StyledTooltip'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'
import { formatAmount } from '../../utils/formatAmount'
import { Fraction, Percent } from '@dolomite-exchange/v2-sdk'
import { useAggregateBalanceData } from '../../types/aggregateBalanceData'
import { useActiveWeb3React } from '../../hooks'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import CurrencyLogo from '../../components/CurrencyLogo'
import {
  O_ARB_REWARDS_PER_WEEK_MAP,
  O_ARB_REWARDS_WEIGHTS_PER_WEEK_MAP,
  O_TOKEN_REWARDS_LABEL_MAP,
} from '../../state/graphql/useLiquidityMiningRewardsInterestRates'
import { useInterestRateData } from '../../types/interestRateData'
import { useMarketsTotalWeiData } from '../../hooks/useDolomiteMarginProtocol'
import { useShowYieldAsApr } from '../../state/user/hooks'
import { useTranslation } from 'react-i18next'
import JSBI from 'jsbi'
import { useTokensWithOTokenRewards } from '../../hooks/useLiquidityMining'
import { ChainId } from '../../constants'

const EarnOARB = styled.div`
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  width: 500px;
  height: auto;
  border-radius: 8px;
  padding: 25px 35px 30px;
  background-color: ${({ theme }) => theme.bg1};
  display: inline-block;
  vertical-align: top;
  margin: 20px;
  position: relative;
  overflow: hidden;
  text-align: left;

  @media screen and (max-width: 650px) {
    width: 100%;
    margin: 20px 0 0;
    padding: 20px 25px 25px;
  }
`

const DescriptionText = styled.div`
  font-size: 12px;
  font-weight: 100;
  line-height: 14px;
  color: ${({ theme }) => theme.text2};
  width: 100%;
  position: relative;
  margin: -10px auto 10px;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.bg2};

  svg {
    margin-right: 5px;
    margin-bottom: -3px;
    font-size: 24px;
    color: ${({ theme }) => theme.text3};
    display: inline-block;
    vertical-align: top;
  }

  a {
    color: ${({ theme }) => theme.text2};
    font-weight: 600;
  }

  @media screen and (max-width: 650px) {
  }
`

const DescriptionContent = styled.div`
  width: calc(100% - 30px);
  display: inline-block;
  vertical-align: top;
`

const EarnRow = styled.div`
  width: 100%;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: ${({ theme }) => theme.text1};

  @media screen and (max-width: 650px) {
    font-size: 12px;
  }
`

const EarnRowTitles = styled(EarnRow)`
  color: ${({ theme }) => theme.text3};
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 600;

  @media screen and (max-width: 650px) {
    font-size: 11px;
  }
`

const EarnAssetTitle = styled.div`
  width: 25%;
`

const EarnRateTitle = styled.div`
  width: 20%;
  text-align: right;

  svg {
    transform: translateY(1.5px);
    margin-left: -2px;
    margin-right: -1px;
  }
`

const EarnYieldTitle = styled.div`
  width: 20%;
  text-align: right;

  svg {
    transform: translateY(1.5px);
    margin-left: -2px;
    margin-right: -1px;
  }
`

const EarnBalanceTitle = styled.div`
  width: 30%;
  text-align: right;

  svg {
    transform: translateY(1.5px);
    margin-left: -2px;
    margin-right: -1px;
  }
`

const EarnAsset = styled.div`
  width: 25%;
`

const AssetLogo = styled.div`
  height: 16px;
  display: inline-block;
  vertical-align: top;
  margin-right: 4px;
  margin-top: 1px;

  img,
  svg {
    width: auto;
    height: 100%;
  }
`

const EarnRate = styled.div`
  width: 20%;
  text-align: right;
`

const EarnInner = styled.div`
  width: fit-content;
  display: inline-block;
  vertical-align: top;
`

const EarnYield = styled.div`
  width: 20%;
  text-align: right;
`

const EarnBalance = styled.div`
  width: 30%;
  text-align: right;
`

const TooltipText = styled.div`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 13px;
  color: #f9f9f9;
  /*padding: 12px 6px;*/
  padding: 6px 4px;
`

const TooltipLine = styled.div`
  width: 100%;
`

const TooltipLineValue = styled.span`
  font-weight: 600;
  /*color: #8fc942;*/
`

export const WeightTooltip = ({ amount }: { amount: Fraction; total: number }) => (
  <TooltipText>
    <TooltipLine>
      <TooltipLineValue>{formatAmount(amount, 2)} oARB / week</TooltipLineValue>
    </TooltipLine>
  </TooltipText>
)

function EarnPanel() {
  const [t] = useTranslation()
  const chainId = ChainId.ARBITRUM_ONE // TODO: make dynamic for other networks
  const { account } = useActiveWeb3React()
  const rewardTokens = useTokensWithOTokenRewards()
  const [showYieldAsApr] = useShowYieldAsApr()
  const yieldText = showYieldAsApr ? t('APR') : t('APY')
  const { data: interestRateMap } = useInterestRateData()
  const { data: tokensToBalances } = useAggregateBalanceData(account, rewardTokens)
  const [totalWeisMap] = useMarketsTotalWeiData()
  // TODO: use tokenToOARBPerSecond to show how much oARB a user is getting right now (per second)
  const tokenToOARBPerSecond = useMemo(
    () =>
      rewardTokens.reduce<Record<string, Fraction>>((memo, token) => {
        const balance = tokensToBalances?.[token.address]
        const totalWei = totalWeisMap[token.address]
        if (!balance || !totalWei) {
          return memo
        }

        memo[token.address] = O_ARB_REWARDS_WEIGHTS_PER_WEEK_MAP[chainId][token.symbol ?? '']!.multiply(
          balance.asFraction,
        )
          .divide(totalWei.supplyWei.asFraction)
          .divide(7 * 86_400)
        return memo
      }, {}),
    [rewardTokens, tokensToBalances, totalWeisMap, chainId],
  )
  return (
    <EarnOARB>
      <PanelTitle>1. Earn oARB</PanelTitle>
      <PanelSubtitle>
        Earn oARB by depositing any of the assets below. The rate that you earn oARB will depend on the amount of each
        asset currently on Dolomite. Earned oARB is available for claiming every week. Claim the oARB you earned from
        the previous week in the panel below.
      </PanelSubtitle>
      <DescriptionText>
        <InfoRoundedIcon />
        <DescriptionContent>
          {`oARB is earned no matter where your assets are on Dolomite, even if they are being used as collateral in a borrow position or a margin position. `}
          <a href={'https://docs.dolomite.io/oarb-incentives-program'} target={'_blank'} rel={'noreferrer'}>
            Learn more
          </a>
        </DescriptionContent>
      </DescriptionText>
      <EarnRowTitles>
        <EarnAssetTitle>Asset</EarnAssetTitle>
        <EarnRateTitle>
          Weight{' '}
          <StyledTooltipWithIcon
            tooltipText={'The percentage of the total oARB distribution being given to suppliers of each asset'}
          />
        </EarnRateTitle>
        <EarnYieldTitle>
          {yieldText} <StyledTooltipWithIcon tooltipText={`${yieldText} assuming oARB is vested at max duration`} />
        </EarnYieldTitle>
        <EarnBalanceTitle>Balance</EarnBalanceTitle>
      </EarnRowTitles>

      {rewardTokens.map(token => {
        const interestParts = interestRateMap[token.address]?.outsideSupplyInterestRateParts
        const label = O_TOKEN_REWARDS_LABEL_MAP[chainId]
        const oArbPart = interestParts?.find(p => label && p.label === label)?.interestRate
        const oArbPerWeek = O_ARB_REWARDS_WEIGHTS_PER_WEEK_MAP[chainId][token.symbol ?? '']!
        return (
          <EarnRow key={`row-${token.address}`}>
            <EarnAsset>
              <AssetLogo>
                <CurrencyLogo currency={token} style={{ borderRadius: '0' }} />
              </AssetLogo>
              {cleanCurrencySymbol(token)}
            </EarnAsset>
            <EarnRate>
              <StyledTooltip
                title={<WeightTooltip amount={oArbPerWeek} total={O_ARB_REWARDS_PER_WEEK_MAP} />}
                placement='top'
                arrow={true}
              >
                <EarnInner>
                  {formatAmount(
                    new Percent(
                      oArbPerWeek.numerator,
                      JSBI.multiply(oArbPerWeek.denominator, JSBI.BigInt(O_ARB_REWARDS_PER_WEEK_MAP)),
                    ),
                  )}
                </EarnInner>
              </StyledTooltip>
            </EarnRate>
            <EarnYield>{formatAmount(oArbPart)}</EarnYield>
            <EarnBalance>{formatAmount(tokensToBalances?.[token.address])}</EarnBalance>
          </EarnRow>
        )
      })}
      {/*<EarnedOARBWrapper>
       <EarnedTitle>oARB Earned This Week</EarnedTitle>
       <EarnedAmount>5,132.16 oARB</EarnedAmount>
       </EarnedOARBWrapper>*/}
    </EarnOARB>
  )
}

export default React.memo(EarnPanel)
