import { TopTokenData } from '../../../types/topTokenData'
import { InterestRate } from '../../../types/interestRateData'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { PageSizeContext } from '../../../pages/Stats'
import Column from '../../Orders/Column'
import { TableLoader } from '../../Loader'
import { Arrow, Backward, Forward, PageSelect } from '../styled'
import { ColumnTitles, StatRows, StatTable } from '../Overview'
import { useToken } from '../../../hooks/Tokens'
import { useHistory } from 'react-router-dom'
import { useMarketRiskInfoData } from '../../../types/marketRiskInfoData'
import cleanCurrencySymbol from '../../../utils/cleanCurrencySymbol'
import { ONE_BIPS, ZERO_PERCENT } from '../../../constants'
import { useShowYieldAsApr } from '../../../state/user/hooks'
import getLogoOrDefault from '../../common/TokenLogos'
import cleanCurrencyName from '../../../utils/cleanCurrencyName'
import { formatAmount } from '../../../utils/formatAmount'
import { StyledTooltip } from '../../common/StyledTooltip'
import { YieldTooltip } from '../../LendingPanel'
import styled from 'styled-components/macro'
import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import Input from '@material-ui/core/Input'
import { X } from 'react-feather'
import Search from '@material-ui/icons/Search'
import { filterTokenStats, FilterType, useFilterTypes } from '../../../constants/tokenLists/FilterTokens'
import { useActiveWeb3React } from '../../../hooks'

const MobileText = styled.span`
  display: none;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: inline;
  `};
`

const DesktopText = styled.span`
  display: inline;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const Index = styled.div`
  width: 5%;
  display: inline-block;
`

const Ticker = styled.span<{ secondary?: boolean | null }>`
  color: ${({ theme, secondary }) => (secondary ? theme.text3 : theme.text1)};
  font-weight: 400;
  margin-top: 0;
  margin-left: 2px;
`

const TokenRowWrapper = styled.div`
  height: 40px;
  cursor: pointer;
  padding: 10px 20px;
  width: calc(100% + 40px);
  margin-left: -20px;
  border-radius: 5px;
  font-size: 15px;

  :hover {
    background-color: ${({ theme }) => theme.bg2};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    padding: 8px 20px;
  `};

  @media screen and (max-width: 420px) {
    font-size: 12px;
  }
`

const TokenLogo = styled.div`
  display: inline-block;
  margin-right: 2px;
  margin-top: 0;
  vertical-align: top;
  width: 19px;

  @media (max-width: 960px) {
    width: 17px;
  }

  @media (max-width: 660px) {
    width: 15px;
  }

  img {
    width: 100%;
  }
`

const LogoHelper = styled.span`
  display: inline-block;
  height: 100%;
  vertical-align: middle;
`

const TokenName = styled.div`
  width: 35%;
  display: inline-block;

  > ${Ticker}:nth-of-type(2) {
    margin-left: 5px;
  }

  @media (max-width: 960px) {
    width: 38%;
  }

  @media (max-width: 660px) {
    width: 43%;
  }
`

const TokenMetric = styled.div<{ hideOnMobile?: boolean | null; hideOnTablet?: boolean | null; large?: boolean }>`
  width: 15%;
  text-align: right;
  display: inline-block;

  @media (max-width: 960px) {
    width: 19%;
    display: ${({ hideOnTablet }) => (hideOnTablet ? 'none' : 'inline-block')};
  }

  @media (max-width: 660px) {
    width: ${({ large }) => (large ? '29%' : '23%')};
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'inline-block')};
  }
`

const OutsideRate = styled.span`
  color: ${({ theme }) => theme.blue1};
`

const ColumnWrapper = styled(Column)`
  cursor: pointer;
`

const InputWrapper = styled.div`
  position: relative;
  vertical-align: top;
  width: calc(100% - 100px);
  margin-top: -10px;
  margin-bottom: -10px;
  z-index: 5;

  input {
    height: 15px !important;
    color: #f9f9f9 !important;
    display: inline-flex !important;
    position: relative !important;
    font-size: 14px !important;
    background: #1e1c29 !important;
    font-family: Open Sans, serif !important;
    line-height: 14px !important;
    font-weight: 300 !important;
    border-radius: 4px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  @media (max-width: 600px) {
    width: calc(100% - 20px);

    input {
      border-radius: 4px 0 0 4px !important;
    }
  }

  > div {
    margin-top: 3px;
    padding-top: 0;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  > div > div {
    padding-top: 0 !important;
  }
`

const InputOverflowFix = styled.div`
  height: 33px;
  overflow: hidden;
`

const StyledInput = styled(({ ...props }) => <Input {...props} />)<{ multiline: boolean }>`
  overflow: hidden;
  margin-bottom: 0 !important;
  height: 33px !important;

  input {
    margin-bottom: 0 !important;
  }

  ${({ multiline }) =>
    multiline &&
    `
    margin-top: 2px;
    width: 100% !important;

    textarea {
      overflow: hidden !important;
      padding: 0 8px !important;
      width: calc(100% - 8px) !important;
    }
  `};
  @media (max-width: 1400px) {
    input {
      font-size: 0.9rem;
    }

    p {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 400px) {
    input {
      font-size: 12px !important;
    }
  }
`

const Clear = styled.div<{ disabled: boolean }>`
  color: ${({ theme }) => theme.text3};
  font-weight: 500;
  position: absolute;
  right: 3px;
  top: 4px;
  display: inline-block;
  ${({ disabled }) => disabled && `display: none`};
`

const Close = styled(X)`
  cursor: pointer;
  height: 18px;
`

const SearchIcon = styled(Search)`
  display: inline !important;
  width: 17px !important;
  height: 17px !important;
  margin-left: 1px;
  margin-bottom: -3px;
`

const FilterSection = styled.div`
  position: relative;
  width: 100%;
  display: inline-block;
  vertical-align: top;
`

const FilterSelect = styled.div<{ expanded: boolean }>`
  background-color: #3a3a4f;
  border-radius: 4px;
  cursor: pointer;
  height: 27px;
  overflow: hidden;
  width: 85px;
  left: calc(100% - 85px);
  bottom: -7px;
  position: absolute;
  z-index: 1;
  color: ${({ theme }) => theme.text1};

  ${({ expanded }) =>
    expanded &&
    `
    border-top-left-radius: 4px;
    height: fit-content;
  `}
`

const FilterSelectRow = styled.div`
  font-size: 14px;
  font-weight: 300;
  padding: 4px 8px;
  height: 27px;

  &:hover {
    background-color: #474956;
  }
`

const ArrowDown = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #606375;
  bottom: 10px;
  right: 7px;
`

const fieldToVal = (field: Fraction | string | Token) => {
  if (field instanceof Token) {
    return field.symbol ?? ''
  } else if (typeof field === 'string') {
    return field
  } else if (field instanceof CurrencyAmount) {
    return parseFloat(field.toFixed(field.currency.decimals))
  } else {
    return parseFloat(field.toFixed(6))
  }
}

const TOKENS_SORT = {
  name: 'name',
  volume: 'volume',
  borrow: 'borrow',
  supply: 'supply',
  supplyLiquidityUSD: 'supplyLiquidityUSD',
  priceUSD: 'priceUSD',
  change: 'change',
}

const tokenWidths = {
  widths: [2, 20, 10, 10, 10, 10],
  starts: [0, 5, 45, 60, 75, 90],
  tabletWidths: [3, 22, 12, 12, 12],
  tabletStarts: [0, 5, 50, 69, 88],
  mobileWidths: [3, 30, 16, 20],
  mobileStarts: [0, 5, 55, 80],
}

let tokenTablePageNumber = 1

export default function TokenTable({
  tokens,
  interestRateMap,
  isLoading,
}: {
  tokens: TopTokenData[]
  interestRateMap: Record<string, InterestRate | undefined>
  isLoading: boolean
  fiatMap: Record<string, Fraction | undefined>
}) {
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const [initialLoading, setInitialLoading] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [tokenSearch, setTokenSearch] = useState<boolean>(false)
  const [filterSelectOpen, setFilterSelectOpen] = useState<boolean>(false)
  const [tokenFilterType, setTokenFilterType] = useState<FilterType>(FilterType.NONE)
  const filterTypes = useFilterTypes()
  const { data: marketRiskInfoMap } = useMarketRiskInfoData()
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  useEffect(() => {
    initialLoading && tokens && setInitialLoading(false)
  }, [isLoading, initialLoading, tokens])

  const [sortField, setSortField] = useState(TOKENS_SORT.supplyLiquidityUSD)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [page, setPage] = useState(tokenTablePageNumber)
  useEffect(() => {
    tokenTablePageNumber = page
  }, [page])

  useEffect(() => {
    setPage(1)
  }, [chainId, tokenFilterType, searchValue])

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (wrapperRef?.current && !wrapperRef?.current.contains(event.target as Node)) {
      setFilterSelectOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [handleClickOutside])

  useEffect(() => {
    setTokenFilterType(FilterType.NONE)
  }, [chainId])

  const filtered = useMemo(() => {
    return filterTokenStats(tokens, tokenFilterType, searchValue, chainId)
  }, [searchValue, tokens, tokenFilterType, chainId])

  const rows = 10
  const len = filtered.length
  const lastPage = Math.floor(len / rows) + (len % rows === 0 ? 0 : 1)

  const sorted = useMemo(() => {
    const sortedTokens = filtered
      ? [...filtered].sort((a, b) => {
          if (a && b) {
            if (sortField === TOKENS_SORT.supply) {
              return interestRateMap[a.token.address ?? '']?.totalSupplyInterestRate.greaterThan(
                interestRateMap[b.token.address ?? '']?.totalSupplyInterestRate ?? ZERO_PERCENT,
              )
                ? (sortDirection ? -1 : 1) * 1
                : (sortDirection ? -1 : 1) * -1
            } else if (sortField === TOKENS_SORT.borrow) {
              return interestRateMap[a.token.address ?? '']?.totalBorrowInterestRate.greaterThan(
                interestRateMap[b.token.address ?? '']?.totalBorrowInterestRate ?? ZERO_PERCENT,
              )
                ? (sortDirection ? -1 : 1) * 1
                : (sortDirection ? -1 : 1) * -1
            } else {
              return fieldToVal(a[sortField as keyof TopTokenData]) > fieldToVal(b[sortField as keyof TopTokenData])
                ? (sortDirection ? -1 : 1) * 1
                : (sortDirection ? -1 : 1) * -1
            }
          } else {
            return -1
          }
        })
      : []
    if (sortField === TOKENS_SORT.borrow && !sortDirection) {
      let borrow: TopTokenData[] = []
      let noBorrow: TopTokenData[] = []
      sortedTokens.map(token =>
        (marketRiskInfoMap[token.token.address ?? '']?.isBorrowingDisabled ? noBorrow : borrow).push(token),
      )
      return [...borrow, ...noBorrow].slice(rows * (page - 1), page * rows)
    }
    return sortedTokens.slice(rows * (page - 1), page * rows)
  }, [filtered, page, sortDirection, sortField, interestRateMap, marketRiskInfoMap])

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField],
  )

  const selectFilter = (field: FilterType) => {
    setTokenFilterType(field)
    setFilterSelectOpen(false)
  }

  const arrow = useCallback(
    (field: string) => {
      return sortField === field ? (!sortDirection ? '↑' : '↓') : ''
    },
    [sortDirection, sortField],
  )

  return (
    <PageSizeContext.Consumer>
      {({ x }) => {
        const isMobile = x < 960
        const isTablet = 660 < x && x < 960
        return (
          <StatTable>
            <ColumnTitles>
              <Column width={5} textAlign={'left'}>
                {t('#')}
              </Column>
              <ColumnWrapper width={35} tabletWidth={38} mobileWidth={43} textAlign={'left'}>
                {tokenSearch ? (
                  <FilterSection>
                    <InputWrapper>
                      <InputOverflowFix>
                        <StyledInput
                          onChange={(e: any) => setSearchValue(e.target.value)}
                          multiline={false}
                          fullWidth
                          spellCheck={false}
                          placeholder={'Search for Token'}
                          value={searchValue}
                          variant=''
                          disableUnderline={true}
                          endAdornment={''}
                        />
                      </InputOverflowFix>
                      <Clear onClick={() => setSearchValue('')} disabled={searchValue === ''}>
                        <Close />
                      </Clear>
                    </InputWrapper>
                    <FilterSelect
                      expanded={filterSelectOpen}
                      ref={wrapperRef}
                      onClick={() => !filterSelectOpen && setFilterSelectOpen(true)}
                    >
                      {!filterSelectOpen && (
                        <FilterSelectRow onClick={() => setFilterSelectOpen(false)}>
                          {tokenFilterType === FilterType.NONE ? 'Filter' : tokenFilterType}
                        </FilterSelectRow>
                      )}
                      {filterTypes
                        .filter(filter => filter !== tokenFilterType)
                        .map((filter, index) => {
                          return (
                            <FilterSelectRow
                              key={`filter-dropdown-${index}`}
                              onClick={() => selectFilter(filter as FilterType)}
                            >
                              {filter}
                            </FilterSelectRow>
                          )
                        })}
                      {filterSelectOpen && (
                        <FilterSelectRow onClick={() => setFilterSelectOpen(false)}>{tokenFilterType}</FilterSelectRow>
                      )}
                      <ArrowDown />
                    </FilterSelect>
                  </FilterSection>
                ) : (
                  <>
                    <span onClick={() => (tokenSearch ? undefined : handleSort(TOKENS_SORT.name))}>
                      {t('token')} {arrow(TOKENS_SORT.name)}
                    </span>
                    <SearchIcon onClick={() => setTokenSearch(true)} />
                  </>
                )}
              </ColumnWrapper>
              <ColumnWrapper
                width={15}
                tabletWidth={19}
                mobileWidth={30}
                textAlign={'right'}
                onClick={() => handleSort(TOKENS_SORT.priceUSD)}
              >
                {t('price')} {arrow(TOKENS_SORT.priceUSD)}
              </ColumnWrapper>
              <ColumnWrapper
                width={15}
                tabletWidth={19}
                hideOnMobile={true}
                textAlign={'right'}
                onClick={() => handleSort(TOKENS_SORT.borrow)}
              >
                {t('borrowRate')} {arrow(TOKENS_SORT.borrow)}
              </ColumnWrapper>
              <ColumnWrapper
                width={15}
                hideOnTablet={true}
                hideOnMobile={true}
                textAlign={'right'}
                onClick={() => handleSort(TOKENS_SORT.supply)}
              >
                {t('supplyRate')} {arrow(TOKENS_SORT.supply)}
              </ColumnWrapper>
              <ColumnWrapper
                width={15}
                tabletWidth={19}
                mobileWidth={40}
                textAlign={'right'}
                onClick={() => handleSort(TOKENS_SORT.supplyLiquidityUSD)}
              >
                {t('supplyLiq')} {arrow(TOKENS_SORT.supplyLiquidityUSD)}
              </ColumnWrapper>
            </ColumnTitles>
            <StatRows>
              {initialLoading ? (
                <TableLoader
                  rows={5}
                  height={20}
                  spacing={40}
                  wrapperHeight={190}
                  marginTop={20}
                  isMobile={isMobile}
                  isTablet={isTablet}
                  widths={tokenWidths}
                />
              ) : (
                sorted.map((token, index) => (
                  <TokenRow
                    key={`topTokens-${token.id}`}
                    token={token}
                    interestRateMap={interestRateMap}
                    index={index + (page - 1) * 10}
                  />
                ))
              )}
              <PageSelect>
                <Arrow onClick={() => setPage(page === 1 ? page : page - 1)} faded={page === 1}>
                  <Backward />
                </Arrow>
                {page}
                <Arrow onClick={() => setPage(page === lastPage ? page : page + 1)} faded={page === lastPage}>
                  <Forward />
                </Arrow>
              </PageSelect>
            </StatRows>
          </StatTable>
        )
      }}
    </PageSizeContext.Consumer>
  )
}

interface TokenRowProps {
  token: TopTokenData
  interestRateMap: Record<string, InterestRate | undefined>
  index: number
}

const TokenRow = React.memo<TokenRowProps>(function WrappedComponent({ token, interestRateMap, index }: TokenRowProps) {
  const sdkToken = useToken(token.id)
  const history = useHistory()
  const symbol = cleanCurrencySymbol(sdkToken)
  const interestRate = interestRateMap[sdkToken?.address ?? '']
  const borrowRate = interestRate?.borrowInterestRate ?? ZERO_PERCENT
  const totalBorrowRate = interestRate?.totalBorrowInterestRate ?? ZERO_PERCENT
  const borrowInterestRateParts = interestRate?.outsideBorrowInterestRateParts
  const supplyRate = interestRate?.totalSupplyInterestRate ?? ZERO_PERCENT
  const lendingRate = interestRate?.supplyInterestRate ?? ZERO_PERCENT
  const supplyInterestRateParts = interestRate?.outsideSupplyInterestRateParts ?? undefined
  const [showYieldAsApr] = useShowYieldAsApr()
  const { data: marketRiskInfoMap } = useMarketRiskInfoData()
  const marketRiskInfo = marketRiskInfoMap[sdkToken?.address ?? '']
  const borrowingDisabled = marketRiskInfo?.isBorrowingDisabled ?? false

  return (
    <TokenRowWrapper onClick={() => history.push(`/stats/token/${token.id}`)}>
      <Index>{index + 1}</Index>
      <TokenName>
        <TokenLogo>
          <LogoHelper>
            <img src={getLogoOrDefault(symbol ?? '')} alt={`${symbol} logo`} />
          </LogoHelper>
        </TokenLogo>
        <Ticker>
          <MobileText>{symbol}</MobileText>
          <DesktopText>{cleanCurrencyName(sdkToken)}</DesktopText>
        </Ticker>
        <DesktopText>
          <Ticker secondary={true}>({symbol})</Ticker>
        </DesktopText>
      </TokenName>
      <TokenMetric>{formatAmount(token.priceUSD, undefined, true, '$0.00', true)}</TokenMetric>
      <TokenMetric hideOnMobile={true}>
        {borrowingDisabled ? (
          <StyledTooltip title={'This asset cannot be borrowed, and as a result has no borrow rate'} position={'top'}>
            <>-</>
          </StyledTooltip>
        ) : borrowInterestRateParts && borrowInterestRateParts.length > 0 ? (
          <StyledTooltip
            title={
              <YieldTooltip
                interestRateParts={borrowInterestRateParts}
                lendingYield={borrowRate}
                totalSupplyYield={totalBorrowRate}
                showYieldAsApr={showYieldAsApr}
                isBorrowRate={true}
              />
            }
            placement={'top'}
            arrow={true}
          >
            <OutsideRate>
              {totalBorrowRate
                ? totalBorrowRate.greaterThan(ZERO_PERCENT) && totalBorrowRate.lessThan(ONE_BIPS)
                  ? '< 0.01'
                  : totalBorrowRate.toFixed(2)
                : '-'}
              %
            </OutsideRate>
          </StyledTooltip>
        ) : (
          <>{formatAmount(borrowRate, 2, true, '0%')}</>
        )}
      </TokenMetric>
      <TokenMetric hideOnTablet={true} hideOnMobile={true}>
        {supplyInterestRateParts && supplyInterestRateParts.length > 0 ? (
          <StyledTooltip
            title={
              <YieldTooltip
                interestRateParts={supplyInterestRateParts}
                lendingYield={lendingRate}
                totalSupplyYield={supplyRate}
                showYieldAsApr={showYieldAsApr}
                isBorrowRate={false}
              />
            }
            placement={'top'}
            arrow={true}
          >
            <OutsideRate>{formatAmount(supplyRate, 2, true, '0%')}</OutsideRate>
          </StyledTooltip>
        ) : (
          <>{formatAmount(supplyRate, 2, true, '0%')}</>
        )}
      </TokenMetric>
      <TokenMetric large={true}>{formatAmount(token.supplyLiquidityUSD, 2, true, '$0.00', true)}</TokenMetric>
    </TokenRowWrapper>
  )
})
