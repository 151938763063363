import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { PageSizeContext } from '../../pages/History'
import styled from 'styled-components/macro'
import { ColumnTitles, ConnectBtn, ConnectWalletButton, DataRows, NoTrades } from './styled'
import { useTranslation } from 'react-i18next'
import Web3Status from '../../components/Web3Status'
import Column from '../../components/Orders/Column'
import DataRow from '../../components/Orders/DataRow'
import { useActiveWeb3React } from '../../hooks'
import { Fraction, Rounding, Token } from '@dolomite-exchange/v2-sdk'
import AllInclusiveIcon from '@material-ui/icons/AllInclusive'
import { BorrowPosition, BorrowPositionStatus, useAllBorrowPositions } from '../../types/borrowPositionData'
import { ChainId, ZERO_ADDRESS, ZERO_FRACTION } from '../../constants'
import { TableLoader } from '../Loader'
import { Transfer, useCombinedTransfersByMarginAccount, useTransfersByWalletAddress } from '../../types/transferData'
import { Trade, useTradeDataByMarginAccount } from '../../types/tradeData'
import { Liquidation, useLiquidationDataByMarginAccount } from '../../types/liquidationData'
import Modal from '../Modal'
import toDate from '../../utils/toDate'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import getLogoOrDefault from '../common/TokenLogos'
import { Token as TokenGql } from '../../types/gqlTypeHelpers'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { ExternalLink } from '../../theme'
import { getEtherscanLink } from '../../utils'
import { StyledTooltip } from '../common/StyledTooltip'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import useSortedActionsWithLabels, { ActionWithLabel } from './hooks/useSortedActionsWithLabels'
import { formatAmount } from '../../utils/formatAmount'
import AmountWithFullTooltip from '../AmountWithFullTooltip'
import { StrategyData, StrategyWithAmounts } from '../../pages/Strategies/StrategiesList'
import { useHistory } from 'react-router-dom'
import useNetInterestRate from '../../hooks/useNetInterestRate'
import { SubmitButton } from '../../pages/Strategies'
import { MarginPositionStatus } from '../../types/marginPositionData'

const BorrowWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const TokenLogo = styled.div<{ large?: boolean }>`
  display: inline-block;
  margin-right: 3px;
  margin-top: ${({ large }) => (large ? '2px' : '0')};
  vertical-align: top;
  width: ${({ large }) => (large ? '18px' : '14px')};

  img {
    width: 100%;
  }

  @media screen and (max-width: 515px) {
    display: none;
  }
`

const LogoHelper = styled.span`
  display: inline-block;
  height: 100%;
  vertical-align: middle;
`

const Ticker = styled.span`
  color: ${({ theme }) => theme.text2};
  margin-left: 4px;
`

const LoaderWrapper = styled.div`
  width: 100%;
  padding: 0 25px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0 20px;
  `};
`

const ModalLoaderWrapper = styled.div`
  width: 100%;
  height: 100px;
`

const ModalRows = styled.div`
  width: 100%;
  font-size: 13px;
  padding-top: 5px;
  @media screen and (max-width: 515px) {
    font-size: 11px;
  }
  @media screen and (max-width: 515px) {
    font-size: 10px;
  }
`

const LiqRow = styled.div`
  width: 100%;
  height: 45px;
  margin-bottom: 4px;
`

const ModalRow = styled.div`
  width: 100%;
  height: 25px;
  margin-bottom: 10px;
`

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.5rem 2rem;
`

const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`

const PositionData = styled.div`
  width: 100%;
`

const ClosedTitle = styled.div`
  font-size: 20px;
  font-weight: 300;

  @media screen and (max-width: 515px) {
    font-size: 18px;
  }
`

const BorrowTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  width: fit-content;
  display: inline-block;
  color: ${({ theme }) => theme.text3};
  margin-bottom: 5px;

  @media screen and (max-width: 515px) {
    font-size: 11px;
  }
`

const BorrowAmount = styled.div`
  font-size: 30px;
  font-weight: 300;
  margin: -5px 0;

  @media screen and (max-width: 515px) {
    font-size: 24px;
  }
`

const HealthTitle = styled.span`
  color: ${({ theme }) => theme.text2};
`

const HealthWrapper = styled.div`
  font-size: 12px;

  @media screen and (max-width: 515px) {
    font-size: 11px;
  }
`

const Health = styled.span<{ health: number }>`
  color: ${({ theme, health }) => (health === 3 ? theme.green1 : health == 2 ? theme.yellow1 : theme.red1)};
`

const Totals = styled.div`
  width: 100%;
  position: relative;
  display: flex;
`

const Left = styled.div`
  width: 50%;
  text-align: left;
  position: relative;
`

const Right = styled.div`
  width: 50%;
  text-align: right;
  position: relative;
`

const Amounts = styled.div`
  margin-top: 10px;
  width: 100%;
  position: relative;
  display: flex;
`

const ArrowWrapper = styled.span<{ up: boolean }>`
  height: 12px;
  margin-left: 2px;

  svg {
    height: 0.6em;
    width: 0.6em;
    margin-bottom: -2px;
    color: ${({ theme, up }) => (up ? theme.green1 : theme.red1)};
  }
`

const LiqArrow = styled.span`
  margin-right: 2px;
  margin-left: -16px;

  svg {
    transform: scaleX(-1);
    height: 0.6em;
    width: 0.6em;
    margin-bottom: -3px;
    color: ${({ theme }) => theme.text3};
  }
`

const TradeRow = styled.div`
  width: calc(100% - 18px);
  color: ${({ theme }) => theme.text2};
  margin-left: 18px;
  font-size: 11px;

  @media screen and (max-width: 515px) {
    font-size: 10px;
  }
`

const DollarValue = styled.span`
  color: ${({ theme }) => theme.text3};
`

const InfinityWrapper = styled.span`
  height: 12px;

  svg {
    height: 0.8em;
    width: 0.8em;
    margin-bottom: -5px;
  }
`

const NetInterest = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
`

const CollateralAsset = styled.div`
  font-size: 15px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 20px;
`

const BorrowedAsset = styled.div`
  font-size: 15px;
  width: 100%;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 20px;

  @media screen and (max-width: 615px) {
    font-size: 15px;
  }
`

const CollateralAssetDescription = styled.div`
  position: absolute;
  text-align: left;
  width: fit-content;
  left: 0;
`

const BorrowedAssetDescription = styled.div`
  position: absolute;
  text-align: left;
  width: fit-content;
  left: 15px;
`

const LogoTicker = styled.div`
  display: flex;
`

const TokenInfo = styled.div`
  width: 100%;
`

const TokenTicker = styled.div`
  width: fit-content;
  display: inline-block;
  font-size: 16px;

  @media screen and (max-width: 615px) {
    font-size: 15px;
  }
`

const CollateralAssetAmount = styled.div`
  width: fit-content;
  position: absolute;
  right: 15px;
  text-align: right;
`

const BorrowedAssetAmount = styled.div`
  width: fit-content;
  position: absolute;
  right: 0;
  text-align: left;
`

const AmountInAsset = styled.div`
  font-size: 16px;
`

const ClosedTicker = styled.span`
  margin-right: 7px;
  text-transform: none;
`

const SubmitButtonWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  display: inline-block;
  vertical-align: bottom;
  text-align: right;
  pointer-events: all;
`

const getPositionHealth = (health: Fraction | undefined) => {
  return health
    ? health.greaterThan(new Fraction('125', '100'))
      ? 3
      : health.greaterThanOrEqual(new Fraction('115', '100'))
      ? 2
      : 1
    : 3
}

function isLiquidationOrExpiration(action: ActionWithLabel): boolean {
  if (!action) {
    return false
  }
  return action.actionType === 'Liquidation' || action.actionType === 'Expiration'
}

interface StrategyModalProps {
  strategy: string
  transfers: Transfer[]
  open: boolean
  close: () => void
  chainId: ChainId
  isMobile: boolean
  isTablet: boolean
}

function StrategyModalComparator(prevProps: StrategyModalProps, nextProps: StrategyModalProps) {
  return (
    prevProps.strategy === nextProps.strategy &&
    prevProps.open === nextProps.open &&
    prevProps.chainId === nextProps.chainId &&
    prevProps.transfers.length === nextProps.transfers.length
  )
}

const StrategyModal = React.memo(function StrategyModalInner({
  strategy,
  transfers,
  open,
  close,
  chainId,
}: StrategyModalProps) {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Modal isOpen={open} onDismiss={close} maxHeight={200} maxWidthPx={480}>
      <ModalContent>
        <ColumnTitles modal>
          <Column width={25} textAlign={'left'}>
            {t('action')}
          </Column>
          <Column width={55} textAlign={'left'}>
            {t('amount')}
          </Column>
          <Column width={20} textAlign={'right'}>
            {t('date')}
          </Column>
        </ColumnTitles>
        <ModalRows>
          {transfers.map((action, i) => (
            <ModalRow key={`transfer-action-${i}`}>
              <Column width={25} textAlign={'left'}>
                <ExternalLink
                  href={getEtherscanLink(chainId, action.transaction.transactionHash, 'transaction')}
                  style={{ fontWeight: 400 }}
                >
                  {t('collateral')}
                  <ArrowWrapper up={i === 0}>{i === 0 ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</ArrowWrapper>
                </ExternalLink>
              </Column>
              <Column width={55} textAlign={'left'}>
                <TokenLogo>
                  <LogoHelper>
                    <img
                      src={getLogoOrDefault(cleanCurrencySymbol(action.amount.currency) ?? '')}
                      alt={`${cleanCurrencySymbol(action.amount.currency)} logo`}
                    />
                  </LogoHelper>
                </TokenLogo>
                <AmountWithFullTooltip amount={action.amount} />{' '}
                <DollarValue>({formatAmount(action.amountUSD, 2, true, '$0.00', true)})</DollarValue>
              </Column>
              <StyledTooltip
                title={`${toDate(action.transaction.timestamp, true)}`}
                placement={'right'}
                style={{ cursor: 'pointer' }}
              >
                <Column width={20} textAlign={'right'}>
                  {toDate(action.transaction.timestamp).replace('a few seconds ago', '< 1 min ago')}
                </Column>
              </StyledTooltip>
            </ModalRow>
          ))}
        </ModalRows>
        <SubmitButtonWrapper onClick={() => history.push(strategy)}>
          <SubmitButton card>Manage</SubmitButton>
        </SubmitButtonWrapper>
      </ModalContent>
    </Modal>
  )
},
StrategyModalComparator)

interface TokensProps {
  tokens: TokenGql[]
  chainId: ChainId
}

const ClosedTokens = ({ tokens, chainId }: TokensProps) => {
  return (
    <>
      {tokens.map((token: TokenGql) => {
        const sdkToken = new Token(chainId, token.id, parseInt(token.decimals), token.symbol, token.name)
        return (
          <div key={token.id}>
            <TokenLogo>
              <LogoHelper>
                <img
                  src={getLogoOrDefault(cleanCurrencySymbol(sdkToken ?? '') ?? '')}
                  alt={`${cleanCurrencySymbol(sdkToken)} logo`}
                />
              </LogoHelper>
            </TokenLogo>
            <ClosedTicker>{cleanCurrencySymbol(sdkToken)}</ClosedTicker>
          </div>
        )
      })}
    </>
  )
}

interface StrategyRowProps {
  position: BorrowPosition
  strategy: StrategyWithAmounts
  strategyNum: number
  transfers: Transfer[]
  isMobile: boolean
  isTablet: boolean
}

function StrategyRowRowComparator(prevProps: StrategyRowProps, nextProps: StrategyRowProps) {
  return (
    prevProps.isMobile === nextProps.isMobile &&
    prevProps.isTablet === nextProps.isTablet &&
    prevProps.strategyNum === nextProps.strategyNum &&
    prevProps.position.status === nextProps.position.status &&
    prevProps.position.totalBorrowUSD === nextProps.position.totalBorrowUSD &&
    prevProps.position.totalSupplyUSD === nextProps.position.totalSupplyUSD
  )
}

const StrategyRow = React.memo(function StrategyRowInner({
  position,
  strategy,
  strategyNum,
  transfers,
  isMobile,
  isTablet,
}: StrategyRowProps) {
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const [modalOpen, setModalOpen] = useState(false)
  const closeModal = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const isOpen = position.status === BorrowPositionStatus.Open
  const [positionEquity, positionEquityUsd] = useMemo(() => {
    const positionEquity = position.supplyAmounts[0]?.amountTokenWei.asFraction.subtract(
      position.totalBorrowUSD.divide(
        position.totalSupplyUSD.divide(position.supplyAmounts[0].amountTokenWei.asFraction),
      ),
    )
    const positionEquityUsd = position.totalSupplyUSD.subtract(position.totalBorrowUSD)
    return [positionEquity, positionEquityUsd]
  }, [position.supplyAmounts, position.totalBorrowUSD, position.totalSupplyUSD])
  const collateralAmount = position.supplyAmounts[0]?.amountTokenWei
  const collateralAmountUSD = position.supplyAmounts[0]?.amountUSD
  const interestRate = useNetInterestRate(position)
  const projectedProfit =
    collateralAmount && interestRate
      ? collateralAmount?.asFraction.add(
          collateralAmount?.asFraction.multiply(interestRate?.asFraction ?? ZERO_FRACTION),
        )
      : ZERO_FRACTION
  const projectedProfitUSD =
    collateralAmountUSD && interestRate
      ? collateralAmountUSD?.asFraction.add(
          collateralAmountUSD?.asFraction.multiply(interestRate?.asFraction ?? ZERO_FRACTION),
        )
      : ZERO_FRACTION

  const equityText = `${formatAmount(positionEquity)} ${cleanCurrencySymbol(
    position.supplyAmounts[0]?.token,
  )} (${formatAmount(positionEquityUsd, 2, true, '', true)})`
  const profitText = `${formatAmount(projectedProfit)} ${cleanCurrencySymbol(
    position.supplyAmounts[0]?.token,
  )} (${formatAmount(projectedProfitUSD, 2, true, '', true)})`
  const strategyLink = `/strategies/${strategyNum}/${position.id}`
  return (
    <div onClick={() => setModalOpen(true)}>
      <DataRow
        columns={[
          {
            width: 20,
            mobileWidth: 30,
            textAlign: 'left',
            type: 'text',
            data: `${strategy.title} ${strategy.leverage}x`,
          },
          {
            width: 30,
            mobileWidth: 35,
            textAlign: 'left',
            type: 'text',
            data: equityText,
          },
          {
            width: 30,
            mobileWidth: 35,
            textAlign: isMobile && !isTablet ? 'right' : 'left',
            type: 'text',
            data: profitText,
          },
          {
            width: 20,
            textAlign: 'right',
            type: 'text',
            data: `${position.status.substring(0, 1) + position.status.substring(1).toLowerCase()} ${toDate(
              position.openTimestamp,
            )}, ${position.openTimestamp.toLocaleString([], {
              hour: 'numeric',
              minute: '2-digit',
            })}`,
            hideOnMobile: true,
          },
        ]}
        contentHeight={36}
        history
      />
      <StrategyModal
        strategy={strategyLink}
        transfers={[transfers[0]]}
        open={modalOpen}
        close={closeModal}
        chainId={chainId}
        isMobile={isMobile}
        isTablet={isTablet}
      />
    </div>
  )
},
StrategyRowRowComparator)

interface ActivePosition {
  position: BorrowPosition
  strategy: number
  transfers: Transfer[]
}

export default function Strategies() {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const { data: positions, loading: isBorrowLoading } = useAllBorrowPositions(account ?? ZERO_ADDRESS)
  const [isLoading, setIsLoading] = useState(true)
  const { data: allTransfers } = useTransfersByWalletAddress(account)

  const strategies = useMemo(() => {
    return positions.filter(position => {
      const positionId = parseFloat(position?.id.split('-')[1])
      return positionId >= 100000 && positionId <= 1000000
    })
  }, [positions])
  const strats = StrategyData()
  const positionIds = useMemo(() => new Set(strategies.map(position => position.id)), [strategies])
  const filteredTransfers = useMemo(() => {
    return allTransfers?.filter(transfer => {
      return positionIds.has(transfer.toMarginAccount.toString())
    })
  }, [allTransfers, positionIds])

  const activePositions = useMemo(() => {
    return strategies?.reduce((filteredPositions: ActivePosition[], position: BorrowPosition) => {
      const positionTransfers = filteredTransfers?.filter(
        transfer => transfer.toMarginAccount.toString() === position.id,
      )
      if (positionTransfers.length < 2) {
        return filteredPositions
      }
      const initialDeposit = positionTransfers[0]
      const loop = positionTransfers[1]
      // Divides the initial deposit + looped amount by initial deposit to get a leverage ratio, then rounds to the nearest whole number to determine which strategy it matches
      const calculatedLeverage = Math.round(
        parseFloat(
          initialDeposit.amount.asFraction
            .add(loop.amount.asFraction)
            .divide(initialDeposit.amount.asFraction)
            .toFixed(2),
        ),
      )
      // if (position.status === 'CLOSED') console.log(positionTransfers)

      const matchedStrategy = strats.find(
        strategy =>
          strategy.leverage === calculatedLeverage &&
          strategy.chain === chainId &&
          strategy.collateralAssets[0].address === initialDeposit?.token.address &&
          strategy.debtAssets[0].address === position.borrowAmounts[0]?.token.address,
      )
      if (matchedStrategy) {
        filteredPositions.push({
          position: position,
          strategy: parseInt(matchedStrategy.id) - 1,
          transfers: positionTransfers,
        })
      }
      return filteredPositions
    }, [])
  }, [chainId, strategies, filteredTransfers, strats])

  useEffect(() => {
    isLoading && !isBorrowLoading && setIsLoading(false)
  }, [isBorrowLoading, isLoading])

  const widths = {
    widths: [10, 15, 15, 15],
    starts: [0, 20, 50, 85],
    tabletWidths: [10, 15, 15, 15],
    tabletStarts: [0, 20, 50, 85],
    mobileWidths: [15, 20, 20],
    mobileStarts: [0, 30, 80],
  }

  return (
    <PageSizeContext.Consumer>
      {({ x }) => {
        const isMobile = x < 960
        const isTablet = 660 < x && x < 960
        return (
          <BorrowWrapper>
            <ColumnTitles>
              <Column width={20} mobileWidth={30} textAlign={'left'}>
                {t('strategy')}
              </Column>
              <Column width={30} mobileWidth={35} textAlign={'left'}>
                {t('equity')}
              </Column>
              <Column width={30} mobileWidth={35} textAlign={isMobile && !isTablet ? 'right' : 'left'}>
                {t('profit')}
              </Column>
              <Column width={20} mobileWidth={20} hideOnMobile={true} textAlign={'right'}>
                {t('openTime')}
              </Column>
            </ColumnTitles>
            <DataRows>
              {account ? (
                isLoading ? (
                  <LoaderWrapper>
                    <TableLoader
                      rows={isMobile ? 5 : isTablet ? 4 : 3}
                      height={30}
                      spacing={55}
                      marginTop={20}
                      isMobile={isMobile}
                      isTablet={isTablet}
                      widths={widths}
                    />
                  </LoaderWrapper>
                ) : positions.length === 0 ? (
                  <NoTrades>{t('noBorrows')}</NoTrades>
                ) : (
                  activePositions.map(position => (
                    <StrategyRow
                      key={position.position.id}
                      position={position.position}
                      strategy={strats[position.strategy]}
                      strategyNum={position.strategy}
                      transfers={position.transfers}
                      isMobile={isMobile}
                      isTablet={isTablet}
                    />
                  ))
                )
              ) : (
                <ConnectWalletButton>
                  <ConnectBtn>
                    <Web3Status />
                  </ConnectBtn>
                </ConnectWalletButton>
              )}
            </DataRows>
          </BorrowWrapper>
        )
      }}
    </PageSizeContext.Consumer>
  )
}
