import { Percent, Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ONE_FRACTION, USDC, W_USDM, WETH, ZERO_PERCENT } from '../../constants'
import { METH } from '../../constants/tokens/METH'
import { GRAI } from '../../constants/tokens/GRAI'
import { RS_ETH } from '../../constants/tokens/RS_ETH'
import { PT_METH_DEC_2024_SPECIAL_ASSET } from '../../constants/isolation/assets/PT_METH_DEC_2024_SPECIAL_ASSET'
import { BorrowPosition } from '../../types/borrowPositionData'
import { useFiatPricesWithLoadingIndicator } from '../../hooks/useFiatValue'
import { tryParseAmount } from '../../state/trade/hooks'
import { CurrencyAmount } from '@dolomite-exchange/v2-sdk'
import useNetInterestRate, { useStrategyInterestRate } from '../../hooks/useNetInterestRate'
import { createFraction } from '../../types/gqlTypeHelpers'
import { useMemo } from 'react'
import { formatAmount } from '../../utils/formatAmount'

const PT_METH = PT_METH_DEC_2024_SPECIAL_ASSET().isolationModeInfo?.getWrappedToken(ChainId.MANTLE)

export enum Risk {
  low = 'low',
  medium = 'medium',
  high = 'high',
}

export const TagsColor = {
  yieldMax: '#7fa225', //'#565A69',
  rewardsMax: '#7fa225', //'#565A69',
  deltaNeutral: '#5d869e',
  expirable: '#D33E43', //'#f44336',
  ethExposure1x: '#e59006',
  minerals: '#2b6dc2',
  powder: '#2b6dc2',
  eigenLayer: '#2b6dc2',
  ethStaking: '#007352',
  usdm: '#2b6dc2',
  pendle: '#2b6dc2',
  ascend: '#2b6dc2',
  kelp: '#2b6dc2',
  arb: '#2b6dc2',
  oArb: '#2b6dc2',
  goArb: '#2b6dc2',
  lrt: '#05a47b',
  leverage2x: '#8fc942',
  leverage3x: '#8fc942',
  leverage4x: '#8fc942',
  leverage5x: '#8fc942',
}
//#B07BAC
//#C3A29E
//#E3D26F
//#06D6A0
//#51BBFE

export const TagsTooltip = {
  yieldMax: 'Yield Maximizing',
  rewardsMax: 'Rewards Maximizing',
  deltaNeutral: 'Δ Neutral',
  //ethStaking: 'ETH Staking',
  //expirable: 'Expirable',
  //ethExposure1x: '1x Eth Exposure',
  //lrt: 'LRT',
  //leverage5x: '5x',
  leverage4x: '4x',
  //leverage3x: '3x',
  leverage2x: '2x',
  //minerals: 'Minerals',
  //powder: 'Powder',
  //eigenLayer: 'EigenLayer Points',
  usdm: 'USDM APR',
  //pendle: 'Pendle Fixed APR',
  ascend: 'Ascend',
  //kelp: 'Kelp Miles',
  arb: 'ARB Rewards',
  oArb: 'oARB Rewards',
  goArb: 'goARB Rewards',
}

export const Tags = {
  yieldMax: 'Yield Maximizing',
  //ethStaking: 'ETH Staking',
  deltaNeutral: 'Δ Neutral',
  rewardsMax: 'Rewards Maximizing',
  //lrt: 'LRT',
  //ethExposure1x: '1x Eth Exposure',
  //expirable: 'Expirable',
  //leverage5x: '5x',
  leverage4x: '4x',
  //leverage3x: '3x',
  leverage2x: '2x',
  //minerals: 'Minerals',
  //powder: 'Powder',
  //kelp: 'Kelp Miles',
  //eigenLayer: 'EigenLayer Points',
  // arb: 'ARB Rewards',
  usdm: 'USDM APR',
  oArb: 'oARB Rewards',
  goArb: 'goARB Rewards',
  //pendle: 'Pendle Fixed APR',
  ascend: 'Ascend',
}

export interface StrategyInfo {
  id: string
  title: string
  tags: string[]
  collateralAssets: Token[]
  debtAssets: Token[]
  risk: Risk
  riskAssessment: string
  leverage: number
  chain: ChainId
  includeRates?: string[]
  specialTag?: string
}

export interface StrategyWithAmounts {
  id: string
  title: string
  tags: string[]
  collateralAssets: Token[]
  debtAssets: Token[]
  risk: Risk
  leverage: number
  chain: ChainId
  supplyAmounts: CurrencyAmount<Token>[]
  borrowAmounts: CurrencyAmount<Token>[]
  includeRates?: string[]
  specialTag?: string
}

const STRATEGIES_DATA: StrategyInfo[] = [
  {
    id: '1',
    title: 'Looped GRAI',
    tags: ['rewardsMax', 'leverage2x', 'yieldMax', 'deltaNeutral', 'ascend', 'goArb'],
    collateralAssets: [GRAI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [USDC[ChainId.ARBITRUM_ONE]],
    risk: Risk.low,
    riskAssessment: `This is a lower risk strategy due to the correlated price of the debt and collateral. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 2,
    chain: ChainId.ARBITRUM_ONE,
  },
  {
    id: '2',
    title: 'Looped GRAI',
    tags: ['rewardsMax', 'leverage4x', 'yieldMax', 'deltaNeutral', 'ascend', 'oArb', 'goArb'],
    collateralAssets: [GRAI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [USDC[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. The correlated price of the strategy's debt and collateral helps to reduce risk, however this strategy uses higher leverage. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 4,
    chain: ChainId.ARBITRUM_ONE,
  },
  {
    id: '3',
    title: 'Looped wUSDM',
    tags: ['leverage2x', 'yieldMax', 'deltaNeutral', 'usdm', 'oArb'],
    collateralAssets: [W_USDM[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [USDC[ChainId.ARBITRUM_ONE]],
    risk: Risk.low,
    riskAssessment: `This is a lower risk strategy due to the correlated price of the debt and collateral. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 2,
    chain: ChainId.ARBITRUM_ONE,
    includeRates: ['USDM APR'],
  },
  {
    id: '4',
    title: 'Looped wUSDM',
    tags: ['leverage4x', 'yieldMax', 'deltaNeutral', 'oArb'],
    collateralAssets: [W_USDM[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [USDC[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. The correlated price of the strategy's debt and collateral helps to reduce risk, however this strategy uses higher leverage. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 4,
    chain: ChainId.ARBITRUM_ONE,
    includeRates: [''],
  },
]

export function StrategyAmounts(strategy: StrategyInfo): [CurrencyAmount<Token>[], CurrencyAmount<Token>[]] {
  const leverage = strategy.leverage ?? 1
  const tokens = useMemo(() => [...strategy.collateralAssets, ...strategy.debtAssets], [strategy])
  const [fiatPrices] = useFiatPricesWithLoadingIndicator(tokens, strategy.chain)
  const supplyAmounts = strategy.collateralAssets.map(token => {
    const price = fiatPrices[token.address ?? '']
    const parsedAmountWithLeverage = tryParseAmount(leverage.toString(), token)
    return (
      parsedAmountWithLeverage?.divide(price?.greaterThan(0) ? price : ONE_FRACTION) ??
      CurrencyAmount.fromRawAmount(token, '0')
    )
  })
  const borrowAmounts = strategy.debtAssets.map(token => {
    const price = fiatPrices[token.address ?? '']
    const parsedAmountWithLeverage = tryParseAmount((leverage - 1).toString(), token)
    return (
      parsedAmountWithLeverage?.divide(price?.greaterThan(0) ? price : ONE_FRACTION) ??
      CurrencyAmount.fromRawAmount(token, '0')
    )
  })
  return [supplyAmounts, borrowAmounts]
}

export function StrategyData(): StrategyWithAmounts[] {
  return STRATEGIES_DATA.map(strategy => {
    const [supplyAmounts, borrowAmounts] = StrategyAmounts(strategy)
    return {
      ...strategy,
      supplyAmounts: supplyAmounts,
      borrowAmounts: borrowAmounts,
    }
  })
}

function InterestRate(
  supplyAmounts: CurrencyAmount<Token>[],
  borrowAmounts: CurrencyAmount<Token>[],
  chain: ChainId | undefined,
  includeRates: string[] | undefined,
) {
  const totalRate = useStrategyInterestRate(supplyAmounts, borrowAmounts, chain, true)
  const interestRate = useStrategyInterestRate(supplyAmounts, borrowAmounts, chain, false, false, includeRates)
  return [totalRate, interestRate]
}

export function useStrategyInterestRateMap(): Record<string, (Percent | undefined)[]> {
  return STRATEGIES_DATA.reduce((memo, t) => {
    const [supplyAmounts, borrowAmounts] = StrategyAmounts(t)
    memo[t.id] = InterestRate(supplyAmounts, borrowAmounts, t.chain, t.includeRates)
    return memo
  }, {} as Record<string, (Percent | undefined)[]>)
}

export function positionStrategy(position: BorrowPosition, strategies: StrategyWithAmounts[]) {
  const positionId = parseFloat(position?.id.split('-')[1])
  if (positionId >= 100000 && positionId <= 1000000) {
    const foundStrategy = strategies?.reduce((memo, strategy, strategyIndex) => {
      memo[strategy.id] = false
      const matchingStrategy = strategy.supplyAmounts.map((supplyAmount, index) => {
        const positionSupply = position.supplyAmounts[index]
        const positionBorrow = position.borrowAmounts[index]
        if (!positionSupply) return false
        const sameToken =
          supplyAmount.currency.address.toUpperCase() === positionSupply.token.address.toUpperCase() &&
          strategy.borrowAmounts[index].currency.address.toUpperCase() === positionBorrow.token.address.toUpperCase()
        if (!sameToken) return false
        const positionRatio = positionSupply.amountTokenWei.divide(positionBorrow.amountTokenWei)
        const strategyRatio = supplyAmount.divide(strategy.borrowAmounts[index])
        return formatAmount(positionRatio) === formatAmount(strategyRatio)
      })
      if (matchingStrategy.includes(true)) memo[strategy.id] = true
      return memo
    }, {} as Record<string, boolean>)
    const index = Object.values(foundStrategy).indexOf(true)
    return index > -1 ? index : false
  }
  return false
}

export default STRATEGIES_DATA
