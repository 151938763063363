import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useActiveWeb3React } from '../../hooks'
import { AppDispatch, AppState } from '../index'
import {
  addGraphqlListeners,
  GraphqlCall,
  GraphqlClientType,
  ListenerOptions,
  parseCallKey,
  removeGraphqlListeners,
  toGraphqlCallKey,
  Web3CallType,
} from './actions'
import { GraphqlStateResult } from './reducer'
import { Contract } from '@ethersproject/contracts'
import { isValidMethodArgs, OptionalMethodInputs, Result } from 'state/multicall/hooks'
import { ChainId } from '../../constants'
import { FunctionFragment } from '@ethersproject/abi'
import { RefreshFrequency } from '../chain/hooks'

interface GraphqlCallResult {
  readonly loading: boolean
  readonly error: string | undefined
  readonly data: string | undefined
  readonly timestamp: number | undefined
}

const INVALID_RESULT: GraphqlCallResult = {
  loading: false,
  error: 'Invalid result',
  data: undefined,
  timestamp: undefined,
}

// the lowest level call for subscribing to GraphQL data
function useGraphqlCallListData<TData>(callList: GraphqlCall[], options: ListenerOptions): GraphQueryState<TData>[] {
  const { chainId } = useActiveWeb3React()
  const callResults = useSelector<AppState, AppState['graphql']['callResults']>(state => state.graphql.callResults)
  const dispatch = useDispatch<AppDispatch>()

  const serializedCallKey = useMemo(() => toGraphqlCallKey(callList), [callList])

  // update listeners when there is an actual change that persists for at least 100ms
  useEffect(() => {
    const calls = parseCallKey(serializedCallKey)
    if (calls.length === 0) {
      return
    }

    dispatch(
      addGraphqlListeners({
        chainId,
        calls,
        options,
      }),
    )

    return () => {
      dispatch(
        removeGraphqlListeners({
          chainId,
          calls,
          options,
        }),
      )
    }
  }, [chainId, dispatch, options, serializedCallKey])

  const callResultSerialized = useMemo(() => {
    const calls = parseCallKey(serializedCallKey)
    if (calls.length === 0) {
      return 'EMPTY'
    }

    const callResult = callResults[chainId][serializedCallKey]
    return callResult ? JSON.stringify(callResult) : undefined
  }, [chainId, serializedCallKey, callResults])

  return useMemo(() => {
    if (callResultSerialized === 'EMPTY') {
      return []
    }

    const callResult: GraphqlStateResult | undefined = callResultSerialized
      ? JSON.parse(callResultSerialized)
      : undefined
    if (!callResult) {
      return [toDolomiteGraphQueryState(INVALID_RESULT)]
    }

    return callResult.resultOrErrors.map(({ error, data }) =>
      toDolomiteGraphQueryState<TData>({
        loading: (callResult.timestamp ?? 0) < (callResult.fetchingTimestamp ?? 1),
        error: error,
        data: data,
        timestamp: callResult.timestamp,
      }),
    )
  }, [callResultSerialized])
}

export interface GraphQueryState<TData> {
  readonly valid: boolean
  // the result, or undefined if loading or errored/no data
  readonly result: TData | undefined
  // true if the result has never been fetched
  readonly loading: boolean
  // true if the call was made and is synced, but the return data is invalid
  readonly error: boolean
  // The time at which this data was fetched
  readonly timestamp: number | undefined
}

const INVALID_QUERY_STATE: GraphQueryState<any> = {
  valid: false,
  result: undefined,
  loading: false,
  error: false,
  timestamp: undefined,
}

const LOADING_QUERY_STATE: GraphQueryState<any> = {
  valid: true,
  result: undefined,
  loading: true,
  error: false,
  timestamp: undefined,
}

function toJsonOrUndefined(x: string): any | undefined {
  try {
    return JSON.parse(x)
  } catch (e) {
    return undefined
  }
}

function toDolomiteGraphQueryState<TData>(callResult: GraphqlCallResult | undefined): GraphQueryState<TData> {
  if (!callResult) {
    return INVALID_QUERY_STATE
  }

  const { loading, data, timestamp } = callResult
  if (!data && loading) {
    return LOADING_QUERY_STATE
  }

  const result = toJsonOrUndefined(data ?? '')
  const success = !!result
  return {
    valid: true,
    loading: loading,
    result: result,
    error: !success,
    timestamp,
  }
}

export const NO_VARIABLES = {}

export function useGraphqlResult<TData>(
  clientType: GraphqlClientType,
  query: string | undefined,
  memorizedVariables: Omit<Record<string, any>, 'blockNumber'> | undefined,
  refreshFrequency: RefreshFrequency,
): GraphQueryState<TData> {
  const { chainId } = useActiveWeb3React()

  const callList = useMemo<GraphqlCall[]>(() => {
    return [
      {
        chainId,
        clientType,
        query: query ? query : 'null',
        variables: memorizedVariables ? JSON.stringify(memorizedVariables) : 'null',
      },
    ]
  }, [chainId, clientType, query, memorizedVariables])

  return useGraphqlResultList<TData>(callList, refreshFrequency)[0]
}

export function useGraphqlResultList<TData>(
  callList: GraphqlCall[],
  refreshFrequency: RefreshFrequency,
): GraphQueryState<TData>[] {
  const options = useMemo<ListenerOptions>(
    () => ({
      refreshFrequency,
    }),
    [refreshFrequency],
  )
  return useGraphqlCallListData<TData>(callList, options)
}

export function useSingleCallResultWithExternalLibrary(
  chainId: ChainId,
  contract: Contract | null | undefined,
  methodName: string,
  inputs: OptionalMethodInputs | undefined,
  refreshFrequency: RefreshFrequency,
): GraphQueryState<Result> {
  const fragment = useMemo(() => contract?.interface.getFunction(methodName), [contract, methodName])

  const variables = useMemo(() => {
    return contract && fragment && isValidMethodArgs(inputs)
      ? {
          chainId,
          to: contract.address,
          data: contract.interface.encodeFunctionData(fragment, inputs),
        }
      : undefined
  }, [chainId, contract, fragment, inputs])

  const state = useGraphqlResult<string[]>(GraphqlClientType.Web3, Web3CallType.Call, variables, refreshFrequency)

  return useMemo(() => {
    return mapRpcResultToResult(contract, fragment, state)
  }, [state, contract, fragment])
}

export function useSingleContractMultipleDataWithExternalLibrary(
  chainId: ChainId,
  contract: Contract | null | undefined,
  methodName: string,
  callInputs: OptionalMethodInputs[],
  refreshFrequency: RefreshFrequency,
): GraphQueryState<Result>[] {
  const fragment = useMemo(() => contract?.interface.getFunction(methodName), [contract, methodName])

  const calls = useMemo(
    () =>
      contract && fragment && callInputs && callInputs.length > 0
        ? callInputs.map<GraphqlCall>(inputs => {
            return {
              chainId,
              clientType: GraphqlClientType.Web3,
              query: Web3CallType.Call,
              variables: JSON.stringify({
                to: contract.address,
                data: contract.interface.encodeFunctionData(fragment, inputs),
              }),
            }
          })
        : [],
    [contract, fragment, callInputs, chainId],
  )

  const states = useGraphqlResultList<string[]>(calls, refreshFrequency)

  return useMemo(() => {
    return states.map(state => mapRpcResultToResult(contract, fragment, state))
  }, [fragment, contract, states])
}

export function useMultipleContractMultipleDataWithExternalLibrary(
  chainId: ChainId,
  contracts: (Contract | null)[],
  methodName: string,
  callInputs: OptionalMethodInputs[],
  refreshFrequency: RefreshFrequency,
): GraphQueryState<Result>[] {
  const fragments = useMemo(() => contracts.map(contract => contract?.interface.getFunction(methodName)), [
    contracts,
    methodName,
  ])

  const calls = useMemo(
    () =>
      fragments && callInputs && callInputs.length > 0 && contracts.length === callInputs.length
        ? (callInputs
            .map<GraphqlCall | undefined>((inputs, i) => {
              const contract = contracts[i]
              const fragment = fragments[i]
              if (!contract || !fragment) {
                return undefined
              }
              return {
                chainId,
                clientType: GraphqlClientType.Web3,
                query: Web3CallType.Call,
                variables: JSON.stringify({
                  chainId,
                  to: contract.address,
                  data: contract.interface.encodeFunctionData(fragment, inputs),
                }),
              }
            })
            .filter(call => !!call) as GraphqlCall[])
        : [],
    [contracts, fragments, callInputs, chainId],
  )

  const states = useGraphqlResultList<string[]>(calls, refreshFrequency)

  return useMemo(() => {
    return states.map((state, i) => mapRpcResultToResult(contracts[i], fragments[i], state))
  }, [contracts, fragments, states])
}

function mapRpcResultToResult(
  contract: Contract | null | undefined,
  fragment: FunctionFragment | undefined,
  state: GraphQueryState<string[]>,
): GraphQueryState<Result> {
  if (contract && fragment && state.result?.[0]) {
    try {
      const decodedResult = contract.interface.decodeFunctionResult(fragment, state.result[0])
      return {
        valid: state.valid,
        result: decodedResult,
        loading: state.loading,
        error: state.error,
        timestamp: state.timestamp,
      }
    } catch (e) {
      console.error('Could not decode result due to error:', e)
    }
  }

  return {
    valid: state.valid,
    result: undefined,
    loading: state.loading,
    error: state.error,
    timestamp: state.timestamp,
  }
}
